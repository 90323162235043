import { useAppSelector } from '@hooks';
import { ITabRoute } from '@interfaces';
import { TabNavigation } from '@molecules';
import { Outlet } from 'react-router-dom';

const TabbedLayout = ({ tabRoutes }: { tabRoutes: ITabRoute[] }) => {
	const userRoles = useAppSelector(state => state.userProfile.permissions);
	const roleFilteredTabs = tabRoutes.filter(tabRoute => {
		if (!tabRoute.handle.requiredPermission) return true;
		// If the user has permission to see the tab, it gets filtered
		return userRoles.some(permission => {
			const [, module] = permission.split(':');
			return module === tabRoute.handle.requiredPermission;
		});
	});

	return (
		<>
			<TabNavigation
				tabs={roleFilteredTabs.map(tabRoute => ({
					name: tabRoute.handle?.tabName ?? '',
					path: tabRoute.path ?? '',
				}))}
				className='mt-8'
			/>
			<Outlet />
		</>
	);
};

export default TabbedLayout;
