import { IViewState } from '@interfaces';
import { MapBase } from '../MapBase';
import { Layer, Source } from 'react-map-gl';
import { v4 } from 'uuid';
import { CSSProperties } from 'react';

interface IMapAreasProps {
	viewState?: IViewState;
	style?: CSSProperties;
	areas?: {
		courtyards: [number, number][][];
		farms: [number, number][][];
	};
}

export const MapAreas = ({ style, viewState, areas = { courtyards: [], farms: [] } }: IMapAreasProps) => {
	return (
		<MapBase
			viewState={viewState}
			style={{
				...style,
				width: '100%',
			}}
		>
			{areas.courtyards.map((courtyardArea, index) => (
				<Source
					key={index}
					type='geojson'
					data={{
						type: 'Feature',
						geometry: {
							type: 'Polygon',
							coordinates: [courtyardArea],
						},
						properties: {},
					}}
				>
					<Layer
						id={v4()}
						type='fill'
						layout={{}}
						paint={{
							'fill-color': '#2973A8',
							'fill-opacity': 0.5,
						}}
					/>
					<Layer
						id={v4()}
						type='line'
						layout={{}}
						paint={{
							'line-color': '#28598F',
							'line-width': 2,
							'line-dasharray': [2, 1],
						}}
					/>
				</Source>
			))}
			{areas.farms.map((farmArea, index) => (
				<Source
					key={index}
					type='geojson'
					data={{
						type: 'Feature',
						geometry: {
							type: 'Polygon',
							coordinates: [farmArea],
						},
						properties: {},
					}}
				>
					<Layer
						id={v4()}
						type='fill'
						layout={{}}
						paint={{
							'fill-color': '#17B26A',
							'fill-opacity': 0.5,
						}}
					/>
					<Layer
						id={v4()}
						type='line'
						layout={{}}
						paint={{
							'line-color': '#085D3A',
							'line-width': 2,
							'line-dasharray': [2, 1],
						}}
					/>
				</Source>
			))}
		</MapBase>
	);
};
