import { deleteLocation, deactivateLocation, QUERY_KEYS, findAllLocationsByCompany } from '@api';
import { Badge, Button, IcDeny, IcList, IcMap, IcPlus, IcTrash } from '@atoms';
import { useAppSelector, useConfirmation, useLocations, useTableQuery } from '@hooks';
import { ILocation } from '@interfaces';
import { BaseTable, MapAreas, ModalConfirmation, ModalNewLocation } from '@organisms';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const columnHelper = createColumnHelper<ILocation>();
const locationColumns: ColumnDef<ILocation, string>[] = [
	columnHelper.accessor('name', {
		header: 'Nombre de Localidad',
	}),
	columnHelper.display({
		header: 'País',
		cell: 'Ecuador',
		meta: { alignment: 'center' },
	}),
	columnHelper.display({
		header: 'Ciudad',
		cell: '-',
		meta: { alignment: 'center' },
	}),
	columnHelper.accessor('locationType.name', {
		header: 'Tipo',
		meta: { alignment: 'center' },
	}),
	columnHelper.display({
		header: 'Estado',
		cell: ({ row }) => (
			<Badge
				variant={row.original.isActive ? 'success' : 'danger'}
				label={row.original.isActive ? 'Activo' : 'Inactivo'}
			/>
		),
		meta: { alignment: 'center' },
	}),
];

const LocationsPage = () => {
	const { id: companyIdFromParams } = useParams();
	const { companies } = useAppSelector(state => state.userProfile);
	const companyId = companyIdFromParams ?? companies[0].id;

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isShowingTables, setIsShowingTables] = useState(true);

	const queryClient = useQueryClient();
	const { confirm: confirmDeletion, confirmationModalProps: deletionConfirmationModalProps } = useConfirmation({
		mutationFn: deleteLocation,
		onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.locations] }),
	});

	const { confirm: confirmDeactivation, confirmationModalProps: deactivationConfirmationModalProps } =
		useConfirmation({
			mutationFn: deactivateLocation,
			onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.locations] }),
		});

	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.locations, 'by-company'],
		fetcherFn: params => findAllLocationsByCompany({ companyId, ...params }),
		tableColumns: [
			...locationColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { id, isActive } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcDeny
								onClick={() => {
									if (!isActive) return;
									confirmDeactivation({
										locationId: id,
									});
								}}
								className={`w-5 ${isActive ? 'cursor-pointer fill-bummock-disabled_grey_2 hover:fill-bummock-midnight_blue' : 'cursor-not-allowed fill-red-500'} transition-all ease-in-out duration-200`}
							/>
							<IcTrash
								onClick={() =>
									confirmDeletion({
										companyId: companies[0].id,
										locationId: id,
									})
								}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	const { data: locations } = useLocations({ by: 'company', companyId });

	const areas: { courtyards: [number, number][][]; farms: [number, number][][] } = {
		courtyards: locations.courtyards.map(courtyard =>
			courtyard.area.map(point => [+point.longitude, +point.latitude])
		),
		farms: locations.farms.map(farm => farm.area.map(point => [+point.longitude, +point.latitude])),
	};

	const handleCloseCreationModal = () => {
		setIsModalVisible(false);
		queryClient.invalidateQueries({
			queryKey: ['locations'],
		});
	};

	return (
		<>
			<ModalConfirmation {...deletionConfirmationModalProps} />
			<ModalConfirmation {...deactivationConfirmationModalProps} />

			<ModalNewLocation isVisible={isModalVisible} onCloseModal={handleCloseCreationModal} />

			<section className='flex justify-between'>
				<Button onClick={() => setIsModalVisible(true)}>
					<IcPlus className='stroke-2 stroke-bummock-off_white w-[22px]' />
					Crear Nueva Localidad
				</Button>
				<Button onClick={() => setIsShowingTables(prev => !prev)}>
					{isShowingTables ? (
						<>
							<IcMap className='stroke-2 stroke-bummock-off_white w-[1.375rem]' />
							Ver Localidades
						</>
					) : (
						<>
							<IcList className='stroke-2 stroke-bummock-off_white w-[1.25rem]' />
							Ver Lista
						</>
					)}
				</Button>
			</section>

			{isShowingTables ? (
				<section>
					<h2 className='text-xl font-bold mb-4 text-bummock-ocean_blue'>Localidades</h2>
					<BaseTable tableQueryResult={tableQueryResult} />
				</section>
			) : (
				<MapAreas areas={areas} />
			)}
		</>
	);
};

export default LocationsPage;
