import { apiPrivate } from '../config/apiPrivate';

interface IChangePasswordParams {
	id: string;
	newPassword: string;
}

interface IChangePasswordResp {}

export const changePassword = async ({ id, ...restParams }: IChangePasswordParams): Promise<IChangePasswordResp> => {
	const { data } = await apiPrivate.patch<IChangePasswordResp>(`/api/users/${id}/change-password`, restParams);
	return data;
};
