import type { FC } from 'react';

import { IcArrowLeft } from '@atoms';

import type { IButtonGoBackProps } from './ButtonGoBack.interfaces';

export const ButtonGoBack: FC<IButtonGoBackProps> = ({ onClick }) => {
	return (
		<button onClick={onClick} className='self-start inline-flex items-center gap-2 transition-transform group'>
			<IcArrowLeft className='w-4 stroke-2 stroke-bummock-midnight_blue group-hover:-translate-x-1 transition-transform' />
			<span className='font-semibold'>Back</span>
		</button>
	);
};
