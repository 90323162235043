import styles from './ProgressBar.module.sass';

/**
 * Defines the neumorphic hole in which the fraction is placed.
 * This is a hacky way of achieving neumorphism on arbitrary shapes
 * as CSS box-shadow apply only to boxes.
 */
const FractionHole = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='86' height='38' viewBox='0 0 86 38' fill='none'>
			<filter id='inset-shadow'>
				<feOffset dx='4' dy='4' />
				<feGaussianBlur stdDeviation='4' result='offset-blur' />
				<feComposite operator='out' in='SourceGraphic' in2='offset-blur' result='inverse' />
				<feFlood floodColor='#E3E3E3' floodOpacity='.95' result='color' />
				<feComposite operator='in' in='color' in2='inverse' result='shadow' />
				<feComposite operator='over' in='shadow' in2='SourceGraphic' />
			</filter>

			<path
				xmlns='http://www.w3.org/2000/svg'
				d='M3.5 11L1 11V27L3.5 27L4.01575 30.2528C4.63191 34.1389 7.9824 37 11.917 37L66.594 37C70.6868 37 74.1195 33.911 74.5497 29.8409L74.85 27L84.85 27V11H74.85V9C74.85 4.58172 71.2683 1 66.85 1L11.756 1C7.66324 1 4.23052 4.08899 3.8003 8.15905L3.5 11Z'
				fill='white'
				filter='url(#inset-shadow)'
			/>
		</svg>
	);
};

interface IProgressBarProps {
	className?: string;
	nth: number;
	outOf: number;
}

export const ProgressBar = ({ className, nth, outOf }: IProgressBarProps) => {
	const clampedNth = nth > outOf ? outOf : nth < 0 ? 0 : nth;
	const percentage = ((clampedNth - 1) / (outOf - 1)) * 100;

	// The fraction hole occupies 70px so we subtract them as the percentage increases
	const extraWidth = (70 * percentage) / 100;
	return (
		<div className={`${styles['progress-bar']} ${className}`}>
			<div
				style={{
					width: `calc(${percentage}% - ${extraWidth}px)`,
				}}
				className={styles['progress-bar__fill-container']}
			>
				<div className={styles['progress-bar__left-container']}></div>
				<div className={styles['progress-bar__fill']}></div>

				<div className={styles['progress-bar__fraction-container']}>
					<FractionHole />
					<span className={styles['progress-bar__fraction']}>
						{clampedNth}/{outOf}
					</span>
				</div>
			</div>
		</div>
	);
};
