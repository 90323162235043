import { getPaginatedResource, IPaginatedResourceParams } from '@api';
import { IDispatchDetails } from '@interfaces';
import moment from 'moment';

interface IDispatchContentResponseEntity {
	id: string;
	createdAt: string;
	code: number;
	quantity: number;
	kitType: {
		name: string;
		kitUsage: {
			name: string;
		};
	};
	user: {
		firstName: string;
		lastName: string;
		image: string;
	};
}

export const findKitDispatchContent = async ({
	dispatchId,
	...params
}: IPaginatedResourceParams & { dispatchId: string }) => {
	const { data } = await getPaginatedResource<IDispatchContentResponseEntity>(
		`/api/dispatches/${dispatchId}`,
		params
	);

	const dispatchContent: IDispatchDetails[] =
		data?.data.map(({ id, code, createdAt, kitType, quantity, user }) => ({
			id,
			code: String(code),
			boxId: '',
			createdBy: `${user.firstName} ${user.lastName}`,
			kit: kitType.name,
			kitUsage: kitType.kitUsage.name,
			creationDate: moment.utc(createdAt).format('DD/MM/YYYY'),
			quantity: quantity,
		})) || [];

	return {
		...data,
		data: dispatchContent,
	};
};
