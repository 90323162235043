import { apiPrivate } from '../config/apiPrivate';

interface ICreateEntityParams {
	name: string;
	identificationNumber: string;
	email: string;
	yardCount: number;
	farmCount: number;
	shippingLineCount: number;
	companyCount: number;
	numberOfCreatedUsers: number;
	adminDni: string;
	adminFirstName: string;
	adminLastName: string;
	adminAge: number;
	adminPhoneNumber: string;
	adminEmail: string;
	adminCity: string;
}

interface ICreateEntityResp {
	id: string;
	name: string;
	identificationNumber: string;
	email: string;
	yardCount: number;
	farmCount: number;
	shippingLineCount: number;
	companyCount: number;
	numberOfCreatedUsers: number;
	createdAt: string;
	updatedAt: string;
}

export const createEntity = async (params: ICreateEntityParams): Promise<ICreateEntityResp> => {
	const { data } = await apiPrivate.post<ICreateEntityResp>('/api/entities', params);
	return data;
};
