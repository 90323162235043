import { IIconsProps } from './Icons.interfaces';

export const IcKit = ({ className, onClick }: IIconsProps) => {
	return (
		<svg className={className} onClick={onClick} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 21' fill='none'>
			<path
				d='M14.9996 6.168C14.9996 7.22886 14.5782 8.24628 13.828 8.99643C13.0779 9.74657 12.0605 10.168 10.9996 10.168C9.93872 10.168 8.92131 9.74657 8.17116 8.99643C7.42102 8.24628 6.99959 7.22886 6.99959 6.168M2.63281 5.56938L1.93281 13.9694C1.78243 15.7739 1.70724 16.6762 2.01227 17.3722C2.28027 17.9837 2.74462 18.4884 3.33177 18.8062C4.00006 19.168 4.90545 19.168 6.71623 19.168H15.283C17.0937 19.168 17.9991 19.168 18.6674 18.8062C19.2546 18.4884 19.7189 17.9837 19.9869 17.3722C20.2919 16.6762 20.2167 15.7739 20.0664 13.9694L19.3664 5.56938C19.237 4.01675 19.1723 3.24043 18.8285 2.65286C18.5257 2.13544 18.0748 1.7206 17.5341 1.46185C16.92 1.168 16.141 1.168 14.583 1.168L7.41623 1.168C5.85821 1.168 5.07921 1.168 4.4651 1.46184C3.92433 1.7206 3.47349 2.13544 3.17071 2.65285C2.82689 3.24043 2.76219 4.01675 2.63281 5.56938Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
