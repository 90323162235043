import { Button, IcPlus, IcTrash, IcUserDisabled, IcUserEdit, Badge } from '@atoms';
import { BaseTable, TablePagination, TableSearchbar } from '@organisms';
import { findAllEntities, QUERY_KEYS } from '@api';
import { useTableQuery } from '@hooks';
import { IEntity } from '@interfaces';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

const columnHelper = createColumnHelper<IEntity>();
const entitiesColumns: ColumnDef<IEntity, string>[] = [
	{
		header: 'Razón Social',
		accessorKey: 'name',
	},
	{
		header: 'RUC',
		accessorKey: 'nationalBusinessNumber',
		enableSorting: false,
	},
	{
		header: 'Correo Electrónico del Administrador',
		accessorKey: 'adminEmail',
		enableSorting: false,
	},
	{
		header: 'Relación',
		accessorKey: 'relationship',
	},
	columnHelper.accessor('status', {
		header: 'Estado',
		cell: columnDefBase => (
			<Badge
				label={columnDefBase.getValue().toUpperCase() === 'APPROVED' ? 'Aprobado' : 'Pendiente'}
				variant={columnDefBase.getValue().toUpperCase() === 'APPROVED' ? 'success' : 'warning'}
			/>
		),
		meta: { alignment: 'left' },
		enableSorting: false,
	}),
];

const ClientsPage = () => {
	const navigator = useNavigate();

	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.entities],
		fetcherFn: findAllEntities,
		tableColumns: [
			...entitiesColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { status, id } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcTrash
								onClick={() => console.log('Clicked item with id', id)}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
							<IcUserDisabled
								onClick={() => console.log('Disabling item with ID', id)}
								className={`${status.toUpperCase() === 'DESACTIVADO' ? ' fill-red-500' : 'hover:fill-bummock-midnight_blue'} w-5 cursor-pointer transition-all ease-in-out duration-200 fill-bummock-disabled_grey_2`}
							/>
							<IcUserEdit
								onClick={() => navigator(`/entities/${id}/edit`)}
								className='w-5 cursor-pointer transition-all ease-in-out duration-200 fill-bummock-disabled_grey_2 hover:fill-bummock-midnight_blue'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	return (
		<>
			<div className='flex justify-between'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<div className='flex gap-4'>
					<Button onClick={() => navigator('/entities/new')}>
						<IcPlus className='stroke-2 stroke-white w-4' /> Agregar Entidad
					</Button>
				</div>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default ClientsPage;
