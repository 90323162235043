import { useForm } from 'react-hook-form';

import type { SubmitHandler } from 'react-hook-form';

import { Button, IcCheckCircle } from '@atoms';
import { FormInputPassword } from '@molecules';
import { PATTERN_PASSWORD, REGEX_PASSWORD } from '@utils';
import { resetPassword } from '@api';
import { useAppDispatch, useAppSelector } from '@hooks';
import { setNewPasswordSuccess } from '@RTK/Slices';

interface IFormValues {
	newPassword: string;
	newPasswordConfirmation: string;
}

export const FormNewPassword = () => {
	const { userId } = useAppSelector(state => state.auth);
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		getValues,
		watch,
		formState: { isDirty, isValid, errors },
	} = useForm<IFormValues>();

	const hdlSubmit: SubmitHandler<IFormValues> = async ({ newPassword }) => {
		await resetPassword({ newPassword, user: userId });
		dispatch(setNewPasswordSuccess(true));
	};

	return (
		<form onSubmit={handleSubmit(hdlSubmit)} className='grid gap-y-4'>
			<FormInputPassword
				label='Contraseña'
				placeholder='Ej: Bummock123@'
				onError={!!errors.newPassword}
				errorMessage={errors.newPassword?.message}
				register={{
					...register('newPassword', {
						required: { value: true, message: 'All fields are required' },
						pattern: { value: REGEX_PASSWORD, message: '' },
						minLength: { value: 8, message: '' },
					}),
				}}
			/>
			<FormInputPassword
				label='Confirmar contraseña'
				placeholder='Ej: Bummock123@'
				onError={!!errors.newPasswordConfirmation}
				errorMessage={errors.newPasswordConfirmation?.message}
				register={{
					...register('newPasswordConfirmation', {
						required: { value: true, message: 'All fields are required' },
						pattern: { value: REGEX_PASSWORD, message: '' },
						minLength: { value: 8, message: '' },
						validate: value => value === getValues('newPassword') || 'The passwords do not match',
					}),
				}}
			/>
			<ul className='flex flex-col gap-y-3'>
				<li className='flex gap-x-2 font-normal text-sm text-bummock-midnight_blue'>
					<IcCheckCircle
						className={`shrink-0 w-5 ${isDirty && watch('newPassword').length >= 8 ? 'fill-success-500' : 'fill-bummock-disabled_grey_2'}`}
					/>
					<span>Debe tener al menos 8 caracteres.</span>
				</li>
				<li className='flex gap-x-2 font-normal text-sm text-bummock-midnight_blue'>
					<IcCheckCircle
						className={`shrink-0 w-5 ${isDirty && watch('newPassword').match(PATTERN_PASSWORD) ? 'fill-success-500' : 'fill-bummock-disabled_grey_2'}`}
					/>
					<span>Debe incluir mayúscula, minúscula, número y caracter especial.</span>
				</li>
			</ul>
			<Button className='w-full' disabled={!isDirty || !isValid}>
				Restablecer
			</Button>
		</form>
	);
};
