import type { FC } from 'react';

import type { IBadgeIconProps } from './BadgeIcon.interfaces';

import styles from './BadgeIcon.module.sass';

export const BadgeIcon: FC<IBadgeIconProps> = ({ label, icon }) => (
	<span className={styles['badge-icon']}>
		{icon}
		{label}
	</span>
);
