import { apiPrivate } from '../config/apiPrivate';

interface ICreateLocationParams {
	locationType: string;
	name: string;
	area: {
		latitude: string;
		longitude: string;
	}[];
	city: string;
}

interface ICreateLocationResp {
	id: string;
	name: string;
	area: {
		latitude: '1';
		longitude: '2';
	}[];
	city: {
		id: string;
	};
}

export const createLocation = async (params: ICreateLocationParams): Promise<ICreateLocationResp> => {
	const { data } = await apiPrivate.post<ICreateLocationResp>('/api/locations', params);
	return data;
};
