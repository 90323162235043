import type { FC } from 'react';

import type { IAlertProps } from './Alert.interfaces';

export const Alert: FC<IAlertProps> = ({ className = '', message }) => {
	return (
		<div
			className={`inline-block py-3 px-4 bg-warning-100 text-warning-500 border border-warning-200 rounded-md ${className}`}
		>
			{message}
		</div>
	);
};
