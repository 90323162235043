import type { FC } from 'react';

import type { IFormLabelProps } from './FormLabel.interfaces';

export const FormLabel: FC<IFormLabelProps> = ({ id, label, onError, required, disabled, onClick }) => (
	<label
		htmlFor={id}
		className={`font-semibold text-2xs leading-none ${onError ? 'text-error-500' : ''} ${disabled ? 'text-bummock-disabled_grey_2' : 'text-bummock-midnight_blue'}`}
		onClick={onClick}
	>
		{label}
		{required ? (
			<span className='text-error-500' aria-label='required' aria-disabled={disabled}>
				*
			</span>
		) : null}
	</label>
);
