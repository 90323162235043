import { IFormEditUserValues } from '@interfaces';
import { apiPrivate } from '../config/apiPrivate';

interface IUpdateUserParams extends Partial<IFormEditUserValues> {
	id: string;
}

interface IUpdateUserResp {}

export const updateUser = async ({ id, ...restParams }: IUpdateUserParams): Promise<IUpdateUserResp> => {
	const { data } = await apiPrivate.patch<IUpdateUserResp>(`/api/users/${id}`, restParams);
	return data;
};
