export const Logotype = ({ className = '' }: { className?: string }) => {
	return (
		<svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 159 22' fill='#282828'>
			<path d='M14.4374 10.7975L13.8614 10.5319L14.4247 10.2409C15.159 9.86767 15.735 9.31102 16.1465 8.5899C16.5579 7.86246 16.7668 7.00218 16.7668 6.02804C16.7668 4.37074 16.2034 3.0993 15.0451 2.14414C13.8867 1.18265 12.2599 0.701904 10.2217 0.701904H0.764771V21.298H10.2217C12.4751 21.298 14.2538 20.792 15.5198 19.7862C16.7795 18.7867 17.3935 17.4394 17.3935 15.6619C17.3935 14.5739 17.1213 13.5808 16.5896 12.7142C16.0578 11.8539 15.3362 11.2024 14.4374 10.7849V10.7975ZM4.69565 4.17465H10.3103C11.1015 4.17465 11.7345 4.38972 12.1966 4.81986C12.665 5.25 12.9055 5.85093 12.9055 6.61C12.9055 7.36907 12.6713 7.95735 12.2156 8.38116C11.7662 8.79865 11.1268 9.00739 10.3166 9.00739H4.70197V4.17465H4.69565ZM12.6777 17.0156C12.1586 17.49 11.437 17.7367 10.5382 17.7367H4.69565V12.3283H10.5382C11.4433 12.3283 12.1586 12.5624 12.6777 13.0305C13.2031 13.5049 13.4689 14.1754 13.4689 15.0357C13.4689 15.896 13.2031 16.5348 12.6777 17.0219V17.0156Z' />
			<path d='M34.94 12.4548C34.94 14.1374 34.5286 15.4911 33.712 16.4716C32.8828 17.471 31.7371 17.9771 30.3129 17.9771C28.8886 17.9771 27.6923 17.471 26.8694 16.4716C26.0655 15.4911 25.6541 14.1374 25.6541 12.4548V0.701904H21.6472V12.4548C21.6472 15.3899 22.4131 17.6987 23.9197 19.3181C25.4198 20.9311 27.572 21.7534 30.3129 21.7534C33.0537 21.7534 35.1932 20.9374 36.6871 19.3244C38.1873 17.7051 38.9469 15.3962 38.9469 12.4548V0.701904H34.9337V12.4548H34.94Z' />
			<path d='M54.8602 9.94358L47.9606 0.701904H44.2766V21.298H48.2581V7.38804L54.588 15.6619H55.1894L61.4623 7.41967V21.298H65.4438V0.701904H61.7598L54.8602 9.94358Z' />
			<path d='M81.6421 9.94358L74.7425 0.701904H71.0585V21.298H75.04V7.38804L81.3699 15.6619H81.9649L88.2379 7.41967V21.298H92.2257V0.701904H88.5354L81.6421 9.94358Z' />
			<path d='M112.481 1.52425C110.924 0.63867 109.095 0.183228 107.031 0.183228C104.968 0.183228 103.138 0.632344 101.581 1.52425C100.03 2.40983 98.8215 3.68127 97.9796 5.2943C97.1314 6.91997 96.7009 8.83662 96.7009 11C96.7009 13.1633 97.1314 15.08 97.9796 16.7056C98.8215 18.3187 100.037 19.5901 101.581 20.4757C103.132 21.3613 104.968 21.8104 107.031 21.8104C109.095 21.8104 110.924 21.3613 112.481 20.4757C114.032 19.5901 115.241 18.3187 116.083 16.7056C116.931 15.08 117.362 13.1633 117.362 11C117.362 8.83662 116.931 6.91997 116.083 5.2943C115.241 3.68127 114.026 2.40983 112.481 1.52425ZM112.57 14.6498C112.051 15.7062 111.298 16.5412 110.336 17.1231C109.38 17.7051 108.266 18.0024 107.025 18.0024C105.784 18.0024 104.677 17.7051 103.733 17.1231C102.784 16.5412 102.037 15.7062 101.518 14.6498C100.999 13.6061 100.739 12.3726 100.739 11C100.739 9.62732 100.999 8.39383 101.518 7.35011C102.037 6.29374 102.784 5.45876 103.733 4.87681C104.683 4.29486 105.791 3.99755 107.025 3.99755C108.259 3.99755 109.373 4.29486 110.336 4.87681C111.298 5.45876 112.045 6.29374 112.57 7.35011C113.089 8.40016 113.349 9.62732 113.349 11C113.349 12.3726 113.089 13.6061 112.57 14.6498Z' />
			<path d='M133.908 17.4078C133.009 17.7873 132.054 17.9771 131.079 17.9771C129.838 17.9771 128.73 17.6798 127.775 17.0979C126.812 16.5159 126.065 15.6809 125.54 14.6182C125.021 13.5745 124.761 12.3537 124.761 10.9937C124.761 9.63367 125.021 8.41916 125.54 7.36912C126.059 6.31274 126.812 5.47777 127.775 4.89581C128.73 4.31386 129.844 4.01656 131.079 4.01656C132.819 4.01656 134.345 4.62381 135.611 5.81935L138.042 3.20688C136.06 1.21432 133.68 0.208557 130.965 0.208557C128.977 0.208557 127.186 0.657674 125.641 1.54958C124.103 2.43516 122.882 3.7066 122.021 5.3386C121.153 6.97693 120.717 8.8746 120.717 11C120.717 13.1254 121.147 15.0231 121.995 16.6487C122.837 18.2681 124.053 19.5395 125.603 20.4314C127.154 21.3297 128.99 21.7851 131.047 21.7851C133.864 21.7851 136.314 20.773 138.333 18.7741L136.022 16.0099C135.408 16.6045 134.699 17.0789 133.902 17.4078H133.908Z' />
			<path d='M150.176 10.7912L158.259 0.701904H153.354L146.403 9.59567V0.701904H142.422V21.298H146.403V12.322L153.797 21.298H159L150.176 10.7912Z' />
		</svg>
	);
};
