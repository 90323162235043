import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import { getStorageLocale } from '@utils';

const getLocale = () => {
	const locale = getStorageLocale() as TLocales;

	if (locale) return { locale };

	return { locale: 'es' as TLocales };
};

interface ILocaleState {
	locale?: TLocales;
}

type TLocales = 'es' | 'en';

const initialState: ILocaleState = {
	...getLocale(),
};

export const localeSlice = createSlice({
	name: 'locale',
	initialState,
	reducers: {
		setLocale: (_state, { payload }: PayloadAction<TLocales>) => {
			_state.locale = payload;
		},
	},
});

export const { setLocale } = localeSlice.actions;
