import { IIconsProps } from './Icons.interfaces';

export const IcList = ({ className }: IIconsProps) => {
	return (
		<svg
			className={className}
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='17'
			viewBox='0 0 20 17'
			fill='none'
		>
			<path
				d='M19 8.5L7 8.5M19 2.5L7 2.5M19 14.5L7 14.5M3 8.5C3 9.05228 2.55228 9.5 2 9.5C1.44772 9.5 1 9.05228 1 8.5C1 7.94772 1.44772 7.5 2 7.5C2.55228 7.5 3 7.94772 3 8.5ZM3 2.5C3 3.05228 2.55228 3.5 2 3.5C1.44772 3.5 1 3.05228 1 2.5C1 1.94772 1.44772 1.5 2 1.5C2.55228 1.5 3 1.94772 3 2.5ZM3 14.5C3 15.0523 2.55228 15.5 2 15.5C1.44772 15.5 1 15.0523 1 14.5C1 13.9477 1.44772 13.5 2 13.5C2.55228 13.5 3 13.9477 3 14.5Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
