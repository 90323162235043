import { apiPrivate } from '../config/apiPrivate';

interface ICreateCategoryParams {
	name: string;
	description: string;
	creatorUserId: string;
	companyId: string;
}

interface ICreateCategoryResp {}

export const createCategory = async (params: ICreateCategoryParams): Promise<ICreateCategoryResp> => {
	const { data } = await apiPrivate.post<ICreateCategoryResp>('/api/categories', params);
	return data;
};
