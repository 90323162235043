import { apiPrivate } from '../config/apiPrivate';

interface IDeactivateLocationParams {
	locationId: string;
}

interface IDeactivateLocationResp {}

export const deactivateLocation = async (params: IDeactivateLocationParams): Promise<IDeactivateLocationResp> => {
	const { data } = await apiPrivate.patch<IDeactivateLocationResp>(`/api/locations/${params.locationId}`, {
		isActive: false,
	});
	return data;
};
