import { ClipboardEvent, Fragment, useEffect, useRef, useState } from 'react';

import { Controller, type UseFormSetValue, useForm, FieldError } from 'react-hook-form';

import { Button } from '@atoms';
import { getUserProfile, verifyOtp } from '@api';
import { useAppDispatch, useAppSelector } from '@hooks';
import { setOtpSuccess, setUserProfile } from '@RTK/Slices';
import { AxiosError } from 'axios';

type TFormOtpInput = {
	digit: string;
};
interface OtpInputGroupProps {
	onChange: (value: IFormOTPValues['code']) => void;
	values: TFormOtpInput[];
	setValue: UseFormSetValue<IFormOTPValues>;
	fieldError: FieldError | undefined;
}
// This won't probably be used anywhere else but at least its logic
// no longer clutters the FormOtp component
const OtpInputGroup = ({ onChange, values, setValue, fieldError }: OtpInputGroupProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [currentFieldIndex, setCurrentFieldIndex] = useState<number>(0);

	// Focuses the first available input
	useEffect(() => containerRef.current!.querySelector('input')?.focus(), []);

	const onKeyDown = (pressedKey: string, index: number) => {
		const isDigit = !isNaN(parseInt(pressedKey));
		if (isDigit) handleValueChange(pressedKey, index);

		let currentField: HTMLInputElement | null = null;

		if (pressedKey === 'Backspace' && index !== 0) {
			const currentValue = containerRef.current!.querySelectorAll('input')[currentFieldIndex].value;
			if (!currentValue) {
				// If the current input doesn't have a value, the prev one gets deleted and the carret
				// moves to the prev
				handleValueChange('', index - 1);
				currentField = containerRef.current!.querySelectorAll('input')[currentFieldIndex - 1];
			} else {
				// If the current input does have a value, that value gets deleted and the carret stays still
				handleValueChange('', index);
				currentField = containerRef.current!.querySelectorAll('input')[currentFieldIndex];
			}
		}

		if (pressedKey === 'ArrowLeft') {
			currentField = containerRef.current!.querySelectorAll('input')[currentFieldIndex - 1];
		} else if (pressedKey === 'ArrowRight') {
			currentField = containerRef.current!.querySelectorAll('input')[currentFieldIndex + 1];
		}

		setTimeout(() => {
			currentField?.focus();
			currentField?.select();
		});
	};

	function handleValueChange(inputValue: string, index: number) {
		onChange(changeGroupValue(index, inputValue));
		// When the value gets deleted
		if (!inputValue) return;

		const nextInput = containerRef.current!.querySelectorAll('input')[currentFieldIndex + 1];
		if (!nextInput) return;
		nextInput.focus();
	}

	function changeGroupValue(index: number, value: string) {
		const newValues = [...values];
		newValues[index].digit = value;
		return newValues;
	}

	function handlePaste(e: ClipboardEvent<HTMLInputElement>) {
		e.stopPropagation();

		// Prevents the pasted text from ending up in the input
		e.preventDefault();

		const clipboardData = e.clipboardData;
		const pastedData = clipboardData.getData('Text').trim();

		if (!pastedData) return;

		// Validate if the code is conformed of numbers only and it contains the required number
		// of digits
		const hasNumbersOnly = /^\d+$/.test(pastedData);
		const hasRequiredLength = pastedData.length === values.length;

		if (hasNumbersOnly && hasRequiredLength) {
			[...pastedData].forEach((char, index) => setValue(`code.${index}.digit`, char));
			[...containerRef.current!.querySelectorAll('input')].at(-1)?.focus();
		} else {
			// TODO: Set error or show toast
			console.log('invalid OTP');
		}
	}

	return (
		<div ref={containerRef} className='flex gap-x-2 items-center'>
			{values.map((value, idx) => (
				<Fragment key={idx}>
					<input
						data-has-error={!!fieldError?.message}
						onPaste={handlePaste}
						type='text'
						placeholder='0'
						className='w-11 h-11 p-0'
						autoComplete='off'
						onChange={() => {}}
						onKeyDown={e => onKeyDown(e.key, idx)}
						onFocus={() => setCurrentFieldIndex(idx)}
						value={value.digit}
					/>
					{idx === 2 && <span className='font-bold text-bummock-midnight_blue'>-</span>}
				</Fragment>
			))}
		</div>
	);
};

interface IFormOTPValues {
	code: TFormOtpInput[];
}
const INITIAL_DATA: IFormOTPValues['code'] = [
	{ digit: '' },
	{ digit: '' },
	{ digit: '' },
	{ digit: '' },
	{ digit: '' },
	{ digit: '' },
];
export const FormOtp = () => {
	const { handleSubmit, control, watch, setValue, setError } = useForm<IFormOTPValues>({
		defaultValues: {
			code: INITIAL_DATA,
		},
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { userEmail } = useAppSelector(state => state.auth);
	const dispatch = useAppDispatch();

	const hdlSubmit = async (formValues: IFormOTPValues) => {
		setIsLoading(true);
		try {
			const otp = formValues.code.map(input => input.digit).join('');
			const response = await verifyOtp({ user: userEmail, otp });
			console.log(response);
			const profileInfo = await getUserProfile();
			dispatch(setUserProfile(profileInfo));
			dispatch(setOtpSuccess(true));
		} catch (error: unknown) {
			setIsLoading(false);

			if (!(error instanceof AxiosError)) throw error;

			// The submitted OTP is incorrect
			if (error.response?.status === 401) {
				console.log('TODO: Handle unauthorized state');
			}

			setError('code', { message: 'Invalid OTP' });
		}
	};

	return (
		<form className='w-full grid justify-items-center gap-y-8' onSubmit={handleSubmit(hdlSubmit)}>
			<Controller
				control={control}
				name='code'
				render={({ field: { onChange }, fieldState: { error } }) => (
					// Refactor this plz
					<OtpInputGroup onChange={onChange} values={watch('code')} setValue={setValue} fieldError={error} />
				)}
			/>

			<Button
				isLoading={isLoading}
				disabled={
					watch('code')
						.map(input => input.digit)
						.join('').length !== INITIAL_DATA.length
				}
				className='w-full justify-center'
			>
				Verificar
			</Button>
		</form>
	);
};
