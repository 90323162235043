import { Button } from '@atoms';
import { Modal } from '../Modal';

interface IModalConfirmationProps {
	confirmationMessage?: string;
	isVisible: boolean;
	onConfirm: () => void;
	onReject: () => void;
}

export const ModalConfirmation = ({ confirmationMessage, isVisible, onConfirm, onReject }: IModalConfirmationProps) => {
	return (
		<Modal className='p-12' isOpen={isVisible} onClose={onReject}>
			<p className='text-xl text-bummock-ocean_blue text-center font-bold mb-8'>
				{confirmationMessage ?? 'Has realizado cambios. ¿Deseas confirmarlos?'}
			</p>
			<div className='flex gap-4 justify-center'>
				<Button onClick={onReject} className='w-[150px]' variant='secondary'>
					Cancelar
				</Button>
				<Button onClick={onConfirm} className='w-[150px]'>
					Guardar
				</Button>
			</div>
		</Modal>
	);
};
