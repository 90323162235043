import { apiPrivate } from '../config/apiPrivate';

interface ICreateKitDispatchParams {
	location: string;
	boxes: string[];
}

interface ICreateKitDispatchResp {}

export const createKitDispatch = async (params: ICreateKitDispatchParams): Promise<ICreateKitDispatchResp> => {
	const { data } = await apiPrivate.post<ICreateKitDispatchResp>('/api/dispatches', params);
	return data;
};
