import { apiPublic } from '../config/apiPublic';

interface IVerifyTokenParams {
	resetPasswordToken: string;
	user: string;
}

interface IVerifyTokenResp {}

export const verifyResetPasswordToken = async (params: IVerifyTokenParams): Promise<IVerifyTokenResp> => {
	return apiPublic.post('/api/auth/verify-token-reset-password', params);
};
