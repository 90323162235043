'use client';

import { useEffect, useRef } from 'react';

import type { RefObject } from 'react';

import gsap from 'gsap';

import { Isotype, Logotype } from '@atoms';

import styles from './LogoLoader.module.sass';
import { useAppDispatch } from '@hooks';
import { finishLoadingAnimation } from '@RTK/Slices';

interface LoadingLogotypeProps {
	isLoading?: boolean;
}

export const LogoLoader = ({ isLoading = false }: LoadingLogotypeProps) => {
	const animationRef = useRef<gsap.core.Timeline | null>(null);
	const loaderContainerRef = useRef<HTMLDivElement>(null);
	const isotypeContainerRef = useRef<HTMLDivElement>(null);
	const isotypeRef = useRef<SVGSVGElement>(null);
	const logoContainerRef = useRef<HTMLDivElement>(null);

	const dispatch = useAppDispatch();

	function createAnimation(logoRef: RefObject<HTMLDivElement>) {
		const timeline = gsap
			.timeline({ paused: true })
			// Leaves only the isotype visible
			.to(logoContainerRef.current, {
				width: '0px',
				ease: 'power3.inOut', // this looks good
				duration: 0.6,
			})
			// Leaves only the isotype visible
			.to(
				logoRef.current,
				{
					ease: 'power2.in',
					gap: '0',
					duration: 0.6,
				},
				'<'
			)
			// Starts rotating the isotype
			.to(isotypeContainerRef.current, {
				rotateZ: '900deg',
				ease: 'power1.in', // It's the default anyway
				duration: 0.5,
			})
			// At the same time it starts changing its color
			.to(
				isotypeRef.current,
				{
					borderRadius: '50%',
					backgroundColor: '#282828',
					scale: '0.6',
					ease: 'none',
					duration: 0.5,
				},
				'<'
			);

		// This callback gets called when the isotype stops spinning
		timeline.add(() => {
			dispatch(finishLoadingAnimation());
		});

		const scaleTimeline = gsap
			.timeline({ repeat: -1 })
			.to(isotypeRef.current, {
				scale: '1',
				duration: 0.7,
			})
			.to(isotypeRef.current, {
				scale: '0.6',
				duration: 0.7,
			});
		timeline.add(scaleTimeline);
		return timeline;
	}

	useEffect(() => {
		// Animation initialization
		if (!animationRef.current) {
			animationRef.current = createAnimation(loaderContainerRef) ?? null;
		}

		const timeline = animationRef.current;
		if (!timeline) return;

		if (timeline.isActive() && !isLoading) {
			timeline.pause();
			gsap.to(loaderContainerRef.current, {
				opacity: 0,
			});
		} else if (isLoading) {
			gsap.to(loaderContainerRef.current, {
				opacity: 1,
			});
			timeline.play();
		}
	}, [isLoading]);

	return (
		<div ref={loaderContainerRef} className={`${styles['logo-loader']}`}>
			<div ref={isotypeContainerRef} className={`${styles['logo-loader__isotype-container']}`}>
				<Isotype mRef={isotypeRef} className='w-12 h-12' />
			</div>
			<div ref={logoContainerRef} className={`${styles['logo-loader__logo-container']}`}>
				<Logotype className='h-[33px]' />
			</div>
		</div>
	);
};
