import { IIconsProps } from './Icons.interfaces';

export const IcDeny = ({ className = '', onClick }: IIconsProps) => (
	<svg
		onClick={onClick}
		className={className}
		xmlns='http://www.w3.org/2000/svg'
		// height='24'
		viewBox='0 0 23 24'
		fill='none'
	>
		<path d='M19.7902 4.19001C17.7102 2.11001 14.9302 0.960007 11.9602 0.960007C5.83016 0.960007 0.970156 5.81001 0.910156 12C0.970156 18.18 5.82016 23.03 11.9502 23.04H11.9702C18.0402 23.04 22.9802 18.1 23.0002 12.02C23.0002 9.06001 21.8702 6.28001 19.7902 4.19001ZM18.5102 16.75L7.20016 5.44001C7.09016 5.33001 7.03016 5.23001 7.04016 5.16001C7.04016 5.09001 7.12016 5.00001 7.25016 4.92001C8.46016 4.12001 9.79016 3.65001 11.1802 3.54001L11.3602 3.52001C11.4302 3.52001 11.5102 3.50001 11.5902 3.50001C11.6302 3.50001 11.6702 3.50001 11.7202 3.50001C11.7602 3.50001 11.8002 3.50001 11.8402 3.50001H11.9702C16.1302 3.50001 19.6502 6.46001 20.3502 10.54C20.7202 12.73 20.2902 14.81 19.0602 16.7C18.9802 16.83 18.8802 16.91 18.8102 16.92C18.7402 16.92 18.6302 16.87 18.5202 16.76L18.5102 16.75ZM16.8802 18.83C16.8802 18.88 16.8102 18.98 16.6502 19.09C15.4702 19.87 14.1602 20.33 12.7302 20.46C8.34016 20.87 4.33016 17.8 3.59016 13.45C3.50016 12.89 3.45016 12.43 3.46016 11.98C3.48016 10.27 3.95016 8.70001 4.87016 7.31001C4.98016 7.15001 5.07016 7.08001 5.13016 7.08001C5.19016 7.08001 5.29016 7.13001 5.43016 7.27001L16.7102 18.55C16.8402 18.68 16.8902 18.79 16.8802 18.84V18.83Z' />
	</svg>
);
