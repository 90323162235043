import { ITabRoute } from '@interfaces';
import { TabbedLayout } from '@layout';
import { CategoriesPage, InventoryPage, ProductsTypesPage, SuppliersPage } from '@pages';
import { RouteObject } from 'react-router-dom';
import { createProtectedTabRoute } from '../../router/helpers';

const inventorySubRoutes: ITabRoute[] = [
	createProtectedTabRoute({
		path: 'products',
		tabName: 'Productos',
		requiredPermission: 'INVENTORY_PRODUCTS',
		element: <InventoryPage />,
	}),
	createProtectedTabRoute({
		path: 'products-types',
		tabName: 'Tipos de Productos',
		requiredPermission: 'INVENTORY_PRODUCT_TYPES',
		element: <ProductsTypesPage />,
	}),
	createProtectedTabRoute({
		path: 'categories',
		tabName: 'Categorías',
		requiredPermission: 'INVENTORY_CATEGORIES',
		element: <CategoriesPage />,
	}),
	createProtectedTabRoute({
		path: 'supppliers',
		tabName: 'Proveedores',
		requiredPermission: 'INVENTORY_SUPPLIERS',
		element: <SuppliersPage />,
	}),
];

export const inventoryRoutes: RouteObject[] = [
	{
		path: 'inventory',
		element: <TabbedLayout tabRoutes={inventorySubRoutes} />,
		children: inventorySubRoutes,
	},
];
