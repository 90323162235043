import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

const getTheme = () => {
	const currentTheme = localStorage.getItem('currentTheme');

	document.body.className = currentTheme ? currentTheme : 'light';

	if (currentTheme) {
		return { isDarkMode: currentTheme === 'dark' };
	}

	return { isDarkMode: false };
};

interface IUIState {
	isDarkMode: boolean;
	resetRequestWasSuccessfully: boolean;
	resetRequestFailed: boolean;
	resetRequestEmail: string;
}

const initialState: IUIState = {
	...getTheme(),
	resetRequestEmail: '',
	resetRequestFailed: false,
	resetRequestWasSuccessfully: false,
};

export const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		toggleDarkMode: _state => {
			_state.isDarkMode = !_state.isDarkMode;
		},
		setResetRequestEmail: (_state, { payload }: PayloadAction<string>) => {
			_state.resetRequestEmail = payload;
		},
		setResetRequestFailed: (_state, { payload }: PayloadAction<boolean>) => {
			_state.resetRequestFailed = payload;
		},
		setResetRequestWasSuccessfully: (_state, { payload }: PayloadAction<boolean>) => {
			_state.resetRequestWasSuccessfully = payload;
		},
	},
});

export const { toggleDarkMode, setResetRequestFailed, setResetRequestWasSuccessfully, setResetRequestEmail } =
	uiSlice.actions;
