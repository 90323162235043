import type { FC } from 'react';

import type { IDashedContainerProps } from './DashedContainer.interfaces';

import styles from './DashedContainer.module.sass';

export const DashedContainer: FC<IDashedContainerProps> = ({ children, className = '', onClick = () => {} }) => {
	return (
		<div className={`${styles['dashed-container']} ${className}`} onClick={onClick}>
			{children}
		</div>
	);
};
