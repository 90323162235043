import { ITabRoute } from '@interfaces';
import { TabbedLayout } from '@layout';
import { EditUserInformationPage, EditUserSecurityPage } from '@pages';
import { RouteObject } from 'react-router-dom';

const profileSubRoutes: ITabRoute[] = [
	{ path: 'information', handle: { tabName: 'Información' }, element: <EditUserInformationPage /> },
	{ path: 'security', handle: { tabName: 'Seguridad' }, element: <EditUserSecurityPage /> },
];

export const profileRoutes: RouteObject[] = [
	{
		path: 'profile',
		element: <TabbedLayout tabRoutes={profileSubRoutes} />,
		children: profileSubRoutes,
	},
];
