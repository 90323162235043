import { IIconsProps } from './Icons.interfaces';

export const IcLockWithoutKey = ({ className }: IIconsProps) => (
	<svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 21' fill='none'>
		<path
			d='M14 9.168V6.168C14 3.40658 11.7614 1.168 9 1.168C6.23858 1.168 4 3.40658 4 6.168V9.168M5.8 19.168H12.2C13.8802 19.168 14.7202 19.168 15.362 18.841C15.9265 18.5534 16.3854 18.0945 16.673 17.53C17 16.8882 17 16.0482 17 14.368V13.968C17 12.2878 17 11.4478 16.673 10.806C16.3854 10.2415 15.9265 9.7826 15.362 9.49498C14.7202 9.168 13.8802 9.168 12.2 9.168H5.8C4.11984 9.168 3.27976 9.168 2.63803 9.49498C2.07354 9.7826 1.6146 10.2415 1.32698 10.806C1 11.4478 1 12.2878 1 13.968V14.368C1 16.0482 1 16.8882 1.32698 17.53C1.6146 18.0945 2.07354 18.5534 2.63803 18.841C3.27976 19.168 4.11984 19.168 5.8 19.168Z'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
