export enum MODULES {
	USERS,
	USERS_INFORMATION,
	USERS_SECURITY,
	USERS_ROLES,
	USERS_LOCATIONS,

	COMPANIES,

	MY_COMPANY,
	MY_COMPANY_INFORMATION,
	MY_COMPANY_LOCATIONS,
	MY_COMPANY_WORKFLOWS,
	MY_COMPANY_FORMS,
	MY_COMPANY_PROCESSES,

	ENTITIES,

	INVENTORY,
	INVENTORY_PRODUCTS,
	INVENTORY_PRODUCT_TYPES,
	INVENTORY_CATEGORIES,
	INVENTORY_SUPPLIERS,

	KITS,
	KITS_BOXES,
	KITS_DISPATCH,
	KITS_TYPES,
	KITS_USAGES,

	SEALS,

	DISPATCH,
	DISPATCH_CREATE,
	DISPATCH_LIST,

	INSPECTIONS,
	INSPECTIONS_LIST,
}

export type TPermissions = 'VIEW' | 'CREATE' | 'EDIT' | 'DELETE' | 'READ_WRITE';

export const PERMISSIONS_BY_LEVELS: Record<TPermissions, number> = {
	VIEW: 0,
	CREATE: 1,
	EDIT: 2,
	DELETE: 2,
	READ_WRITE: 3,
} as const;

export const ROLES = [
	{
		id: '68e34a3c-3937-4eb6-9b72-299038a43ce7',
		name: 'Administrativo',
		defaultRoute: '/users',
	},

	{
		id: '79285aed-6edb-45a9-a7cf-930a0d30f839',
		name: 'Supervisor de Inventario',
		defaultRoute: '/inventory/products',
	},

	{
		id: '6c49908f-0a1f-46ab-882c-6f5719f817b7',
		name: 'Personal de Inventario',
		defaultRoute: '/inventory/products',
	},

	{
		id: 'e9581413-162b-43c7-8c1f-61c9e913d8e9',
		name: 'Supervisor de inspeccion',
		defaultRoute: '/inspections/all',
	},
];
