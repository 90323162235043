import { v4 } from 'uuid';

export const ROLES_AND_PERMISSIONS = [
	{
		id: v4(),
		title: 'Administrativo',
		description:
			'El administrador tiene control total sobre el sistema, con la capacidad de configurar ajustes, gestionar cuentas de usuarios y acceder a todas las áreas y datos sin restricciones.',
		options: [
			{
				id: v4(),
				title: 'Administrador',
				description:
					'El rol de administrador ofrece control total sobre la configuración y gestión del sistema, incluyendo la personalización de flujos de trabajo, manejo de acceso por roles, y la configuración de seguridad como la caducidad de contraseñas y encriptación. Este perfil permite la creación y asignación de workflows específicos, gestión avanzada de inventarios, y la administración de usuarios. También abarca la integración tecnológica para escaneo móvil de documentos, lectura NFC, y verificación de fotos, además de la capacidad para personalizar la interfaz con elementos como logotipos en documentos y localidades en mapas. El administrador tiene autoridad completa para supervisar la operativa, seguridad, y eficiencia del sistema, asegurando su adaptabilidad y cumplimiento con las necesidades de la organización.',
				isSelected: false,
			},
		],
	},
	{
		id: v4(),
		title: 'Visualizacion',
		description:
			'Permite a los usuarios acceder únicamente a la visualización de datos e informes, sin la capacidad de editar o modificar cualquier configuración o contenido. Este perfil está diseñado para usuarios que necesitan consultar información pero no gestionarla.',
		options: [
			{
				id: v4(),
				title: 'Visualizador Administrativo',
				description:
					'El Visualizador Administrativo juega un papel crucial en la observación y análisis de datos operativos, permitiendo el acceso y revisión del registro de eventos, la vinculación de entidades a flujos de trabajo, y la comparación de fotos para control de calidad. Este rol también administra la agrupación de información, seguimiento de inventarios, listado de dispositivos, y acceso a kits, proporcionando una visión integral de la cadena de suministro y facilitando la supervisión efectiva de los recursos y procesos internos.',
				isSelected: false,
			},
			{
				id: v4(),
				title: 'Visualizador Entidad',
				description:
					'El Visualizador Entidad está enfocado en el monitoreo y análisis de la información relacionada con entidades específicas. Este rol tiene acceso al registro de eventos para seguir las actividades, utiliza la comparación de fotos para verificar detalles importantes, y maneja la agrupación de información para una organización eficiente. Adicionalmente, mantiene una visión clara sobre el detalle de la cadena de suministro y recibe notificaciones de estado, asegurando estar siempre informado sobre los cambios y actualizaciones críticos en el sistema.',
				isSelected: false,
			},
		],
	},
	{
		id: v4(),
		title: 'Analítica',
		description:
			'Otorga acceso a herramientas avanzadas de análisis de datos, permitiendo al usuario generar informes detallados y descubrir insights valiosos. No incluye permisos de modificación o gestión de la configuración del sistema.',
		options: [
			{
				id: v4(),
				title: 'Analista',
				description:
					'El Analista se encarga de examinar y entender profundamente los datos y eventos del sistema. Este rol accede al registro de eventos para analizar actividades, utiliza la comparación de fotos para validar y asegurar la coherencia de la información, y emplea la agrupación de información para mejorar la organización y accesibilidad de los datos. Con un enfoque particular en el detalle de la cadena de suministro, el Analista juega un papel clave en la optimización de procesos y la identificación de áreas de mejora, garantizando la eficiencia y la seguridad operativa.',
				isSelected: false,
			},
		],
	},
	{
		id: v4(),
		title: 'Campo',
		description:
			'Está enfocado en usuarios que realizan inspecciones, permitiéndoles acceder y completar flujos de trabajo específicos. No poseen permisos para modificar flujos de trabajo, solo para ejecutarlos según lo establecido.',
		options: [
			{
				id: v4(),
				title: 'Supervisor de Campo',
				description:
					'Supervisa y coordina operaciones clave, accediendo al registro de eventos para controlar actividades. Maneja workflows, incluso offline, y facilita la movilidad mediante el escaneo de documentos y lectura NFC. Este rol puede reordenar flujos de trabajo, asignar kits a unidades, y garantizar la precisión mediante la comparación y verificación de fotos. Además, edita documentos en la web, organiza información relevante, realiza seguimiento de inventarios, y mantiene un control detallado sobre la cadena de suministro, asegurando eficiencia y coherencia en los procesos.',
				isSelected: false,
			},
			{
				id: v4(),
				title: 'Inspector',
				description:
					'El Inspector realiza evaluaciones detalladas en campo, utilizando herramientas tecnológicas avanzadas para garantizar la conformidad y calidad. Este rol accede al registro de eventos para documentar inspecciones, opera con workflows offline para asegurar continuidad en cualquier entorno, y utiliza el escaneo móvil de documentos para agilizar la recopilación de datos. Capaz de asignar kits a unidades específicas y emplear lectura NFC para la identificación rápida, el Inspector también agrupa información eficientemente y verifica la autenticidad mediante la comparación de fotos, jugando un papel crucial en el mantenimiento de estándares y protocolos.',
				isSelected: false,
			},
		],
	},
	{
		id: v4(),
		title: 'Sellos de Seguridad',
		description:
			'Permite al usuario gestionar y registrar los sellos de seguridad. Este perfil tiene acceso a la creación, asignación y verificación de sellos, pero no modifica políticas de seguridad generales.',
		options: [
			{
				id: v4(),
				title: 'Supervisor de sellos',
				description:
					'Responsable de la integridad y seguridad en el manejo de sellos de seguridad. Utiliza la tecnología de lectura NFC para identificar y verificar sellos rápidamente, y lleva a cabo la comparación de fotos para asegurar la autenticidad. Este rol también implica la organización y gestión eficiente de la información relacionada con los sellos, manteniendo una lista detallada y gestionando el inventario de sellos. Además, es encargado de la verificación de sellos para confirmar su estado y legitimidad, así como de la destrucción segura de sellos cuando sea necesario, garantizando así la protección y seguridad en los procesos de sellado.',
				isSelected: false,
			},
			{
				id: v4(),
				title: 'Inventario',
				description:
					'El encargado de Inventario se especializa en la administración eficiente del inventario de sellos, utilizando tecnología de lectura NFC para una identificación rápida y precisa de los artículos. Este rol prioriza la organización de la información para mantener un sistema de inventario claro y actualizado, facilitando la gestión integral del stock de sellos. Su responsabilidad clave incluye asegurar que los sellos estén correctamente catalogados, disponibles cuando se necesiten y adecuadamente controlados, contribuyendo a la eficacia y seguridad de las operaciones.',
				isSelected: false,
			},
			{
				id: v4(),
				title: 'Encriptador',
				description:
					'El Encriptador se centra en la seguridad de los datos mediante el uso avanzado de tecnología NFC para la encriptación y protección de la información. Este rol aprovecha la lectura NFC no solo para identificar dispositivos y elementos de manera eficiente, sino también para implementar soluciones de encriptación robustas con NFC, garantizando la integridad y confidencialidad de los datos. La organización de la información es fundamental para este perfil, asegurando que todos los datos sensibles estén adecuadamente protegidos y accesibles solo para aquellos con los permisos necesarios, jugando un papel crucial en la salvaguarda de la seguridad de la información en la organización.',
				isSelected: false,
			},
			{
				id: v4(),
				title: 'Inspector/Verificador',
				description:
					'El Inspector/Verificador desempeña un papel crucial en la validación de la autenticidad y conformidad de elementos críticos. Utiliza la lectura NFC para agilizar la verificación de objetos y documentos, y aplica técnicas de agrupación de información para mantener organizados los datos recabados durante las inspecciones. Este rol es responsable de la verificación de fotos, asegurando que las imágenes correspondan fielmente a los objetos o situaciones inspeccionados, y de la verificación de sellos, confirmado su integridad y autenticidad. Además, posee la autoridad para llevar a cabo la destrucción de sellos que no cumplan con los estándares requeridos, garantizando así la seguridad y el cumplimiento de las regulaciones.',
				isSelected: false,
			},
		],
	},
	{
		id: v4(),
		title: 'Contabilidad',
		description:
			'Permite al usuario gestionar cuentas, realizar seguimientos de pagos y preparar informes financieros. Este rol está diseñado para usuarios encargados de la administración financiera y no incluye permisos para gestionar otros aspectos del sistema.',
		options: [
			{
				id: v4(),
				title: 'Facturación',
				description:
					'El encargado de Facturación se especializa en la gestión y organización de los procesos de facturación, utilizando la agrupación de información para mantener un seguimiento detallado y ordenado de las transacciones. Este rol es responsable de generar reportes automáticos que facilitan la revisión y análisis de las operaciones de facturación, asegurando precisión y eficiencia. Además, lleva a cabo consultas de facturación para resolver dudas, realizar ajustes y mantener una comunicación clara con clientes o proveedores, garantizando la transparencia y satisfacción en el proceso de facturación.',
				isSelected: false,
			},
		],
	},
	{
		id: v4(),
		title: 'Inventario',
		description:
			'Permite al usuario administrar y supervisar el stock de productos o materiales, incluyendo la entrada y salida de inventario. Este perfil está orientado a garantizar la actualización y precisión de los registros de inventario, sin acceso a funciones fuera de su ámbito.',
		options: [
			{
				id: v4(),
				title: 'Supervisor de Inventario',
				description:
					'El Supervisor es clave en la gestión de recursos y seguridad, destacando en la organización y seguimiento del inventario. Su capacidad para mantener una lista detallada de sellos y gestionar su retorno asegura la integridad de los procesos de seguridad. Además, supervisa la gestión de seguridad, incluyendo el control de dispositivos y el manejo eficiente de kits, abarcando desde el acceso hasta su devolución y despacho, tanto individual como en granel. Este rol garantiza la precisión y seguridad en la distribución y manejo de recursos, manteniendo los estándares de calidad y cumplimiento.',
				isSelected: false,
			},
			{
				id: v4(),
				title: 'Personal Inventario',
				description:
					'El Personal de Inventario juega un papel esencial en la organización y administración de los recursos materiales de la empresa. Este rol se centra en la agrupación eficiente de información y el seguimiento meticuloso de los inventarios para asegurar una gestión precisa de los activos. Además, contribuye a la gestión de seguridad, manteniendo un control riguroso sobre el listado de dispositivos y la administración de kits, desde su acceso hasta su despacho, ya sea de manera individual o al granel. Su labor asegura una distribución eficaz y segura de los recursos, esencial para el buen funcionamiento y la seguridad operacional de la organización.',
				isSelected: false,
			},
		],
	},
	{
		id: v4(),
		title: 'Agentes Gubernamentales',
		description:
			'Ofrece acceso a información y operaciones reguladas por normativas gubernamentales. Este rol permite la supervisión y cumplimiento de estándares legales, sin capacidad de alterar datos críticos del sistema.',
		options: [
			{
				id: v4(),
				title: 'P.A.N Reporte',
				description:
					'Recopilación y organización estratégica de información relevante para la lucha contra el narcotráfico. Este puesto es crucial para agrupar datos de manera efectiva, facilitando el análisis y la interpretación de patrones que puedan indicar actividades ilícitas. Su labor asegura que la información esté estructurada de manera que apoye las operaciones y estrategias de la Policía Anti Narcóticos en su esfuerzo por combatir y prevenir el tráfico de drogas.',
				isSelected: false,
			},
			{
				id: v4(),
				title: 'P.A.N Inspecciones',
				description:
					'Ejecución de revisiones y controles en campo, utilizando tecnología de lectura NFC para la identificación rápida y precisa de objetos o personas. Este personal es fundamental en la agrupación y manejo de la información recabada durante las inspecciones, organizando los datos de forma que sean fácilmente accesibles y analizables para la detección de actividades relacionadas con narcóticos. Su trabajo es esencial para la eficacia de las operaciones anti narcóticos, permitiendo una respuesta rápida y basada en evidencia frente a las amenazas de tráfico de drogas.',
				isSelected: false,
			},
		],
	},
];
