import { createSlice } from '@reduxjs/toolkit';

interface IHeaderState {
	isSearching: boolean;
}

const initialState: IHeaderState = {
	isSearching: false,
};

export const headerSlice = createSlice({
	name: 'header',
	initialState,
	reducers: {
		startSearching: _state => {
			_state.isSearching = true;
		},
		stopSearching: _state => {
			_state.isSearching = false;
		},
	},
});

export const { startSearching, stopSearching } = headerSlice.actions;
