import { apiPrivate } from '../config/apiPrivate';

interface IUpdateCategoryParams {
	id: string;
	name?: string;
	description?: string;
}

interface IUpdateCategoryResp {}

export const updateCategory = async ({ id, ...restParams }: IUpdateCategoryParams): Promise<IUpdateCategoryResp> => {
	const { data } = await apiPrivate.patch<IUpdateCategoryResp>(`/api/categories/${id}`, restParams);
	return data;
};
