import { useEffect, useRef } from 'react';

import gsap from 'gsap';
import { Link, useNavigate } from 'react-router-dom';
import { useGSAP } from '@gsap/react';

import { FormHeader } from '@molecules';
import { FormLogin, FormOtp } from '@organisms';
import { IcArrowLeft } from '@atoms';
import { setHasShrinked, resetAuthState } from '@RTK/Slices';
import { useAppDispatch, useAppSelector } from '@hooks';
import { ROLES } from '@utils';

const LoginPage = () => {
	const {
		loginSuccess,
		userEmail,
		otpSuccess,
		loaderAnimation: { isDone },
	} = useAppSelector(state => state.auth);
	const { role } = useAppSelector(state => state.userProfile);
	const mainRef = useRef<HTMLElement>(null);
	const dispatch = useAppDispatch();
	const navigator = useNavigate();

	function shrinkForm() {
		return new Promise(resolve => {
			gsap.to(mainRef.current, {
				height: '0px',
				duration: 0.6,
				ease: 'power3.inOut',
				onComplete: resolve,
			});
		});
	}

	useGSAP(async () => {
		if (!otpSuccess) return;
		await shrinkForm();
		dispatch(setHasShrinked(true));
	}, [otpSuccess]);

	useEffect(() => {
		const userRoleId = role.id;
		const currentUserRole = ROLES.find(role => role.id === userRoleId);
		const routeBasedOnCurrentRole = currentUserRole?.defaultRoute ?? '/';
		if (isDone) navigator(routeBasedOnCurrentRole);
	}, [isDone]);

	return (
		<main ref={mainRef} className={`flex flex-col items-center gap-y-8 overflow-hidden px-1`}>
			{loginSuccess ? (
				<>
					<FormHeader
						title='Verifique su correo electrónico.'
						description='Ingrese el código que recibió al correo electrónico: '
						descriptionHighlight={userEmail}
					/>
					<FormOtp />
					<Link
						to='/login'
						onClick={() => dispatch(resetAuthState())}
						className='self-center w-fit inline-flex gap-x-2 font-semibold text-bummock-midnight_blue'
					>
						<IcArrowLeft className='w-3.5 stroke-2 stroke-bummock-midnight_blue' />
						Iniciar sesión
					</Link>
				</>
			) : (
				<FormLogin />
			)}
		</main>
	);
};

export default LoginPage;
