import { apiPrivate } from '../config/apiPrivate';

interface IAssignRoleParams {
	id: string;
	role: string;
	company: string;
	dateExpire: string;
}

interface IAssignRoleResp {}

export const assignRole = async ({ id, ...restParams }: IAssignRoleParams): Promise<IAssignRoleResp> => {
	const { data } = await apiPrivate.post<IAssignRoleResp>(`/api/users/${id}/assign-role`, restParams);
	return data;
};
