import { findAllBoxes, QUERY_KEYS } from '@api';
import { useTableQuery } from '@hooks';
import { IBox, IKitDispatchItem } from '@interfaces';
import { createColumnHelper } from '@tanstack/react-table';
import { Dispatch } from 'react';
import { BaseTable, TablePagination, TableSearchbar } from '../BaseTable/BaseTable';
import { Button } from '@atoms';

interface ITableKitsDispatchProps {
	selectedItems: IKitDispatchItem[];
	setSelectedItems: Dispatch<(items: IKitDispatchItem[]) => IKitDispatchItem[]>;
}
const columnHelper = createColumnHelper<IBox>();
const columns = [
	columnHelper.accessor('code', {
		header: 'Box ID',
		cell: columnDefBase => columnDefBase.getValue().padStart(6, '0'),
	}),
	columnHelper.accessor('kitType', {
		header: 'Tipo de Kit',
	}),
	columnHelper.accessor('usage', {
		header: 'Uso del Kit',
	}),
	columnHelper.accessor('creationDate', {
		header: 'Fecha de creación',
	}),
	columnHelper.accessor('createdBy', {
		header: 'Creado por',
	}),
	columnHelper.accessor('quantity', {
		header: 'Cantidad',
	}),
];

// TODO: Refactor this so that the columns stay stable across renders. Try passing state to the table using the meta property.
export const TableKitsDispatch = ({ selectedItems, setSelectedItems }: ITableKitsDispatchProps) => {
	console.log(selectedItems);
	// The details regarding filtering must be part of the hook itself. Refactor this later on.
	const tableQueryResult = useTableQuery({
		queryKey: [`${QUERY_KEYS.boxes}-available`],
		fetcherFn: params =>
			findAllBoxes({
				...params,
				filters: {
					status: '$not:DISPATCHED',
				},
			}),
		tableColumns: [
			...columns,
			{
				header: 'Modificar',
				cell: ({ row }) => {
					const { id, kitType, quantity, usage } = row.original;
					console.log(selectedItems);
					return (
						<Button
							key={id}
							disabled={!!selectedItems.find(item => item.id === id)}
							onClick={() => {
								setSelectedItems(prev => {
									const stateClone = [...prev];

									const foundIndex = prev.findIndex(counter => counter.id === id);
									const wasFound = foundIndex !== -1;

									if (wasFound) return prev;

									stateClone.push({
										id,
										name: kitType,
										category: usage,
										available: quantity,
										description: '-',
										quantity: +quantity,
									});

									return stateClone;
								});
							}}
							className='w-[9.375rem] h-[2.0625rem]'
						>
							Agregar
						</Button>
					);
				},
			},
		],
	});

	return (
		<>
			<div className='grid grid-cols-4 gap-y-4 gap-x-8 items-end'>
				<TableSearchbar className='w-auto' tableQueryResult={tableQueryResult} />
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};
