import { IDispatch } from '@interfaces';
import { getPaginatedResource, IPaginatedResourceParams } from '@api';
import moment from 'moment';

// This gets returned for both bulk dispatches & kits dispatches
interface IDispatchResponseEntity {
	id: string;
	createdAt: string;
	code: number;
	items: number;
	user: {
		firstName: string;
		lastName: string;
		image: string;
	};
	location: {
		name: string;
	};
}

export const findAllDispatches = async ({
	dispatchType,
	...params
}: IPaginatedResourceParams & { dispatchType: 'bulk' | 'kits' }) => {
	const { data } = await getPaginatedResource<IDispatchResponseEntity>(
		`/api/dispatches/${dispatchType === 'bulk' ? 'bulk' : ''}`,
		params
	);

	const dispatches: IDispatch[] =
		data?.data.map(({ id, code, location, createdAt, user, items }) => ({
			id,
			dispatchNumber: String(code),
			dispatchDate: moment.utc(createdAt).format('DD/MM/YYYY'),
			dispatchedBy: `${user.firstName} ${user.lastName}`,
			items,
			location: location.name,
		})) || [];

	return {
		...data,
		data: dispatches,
	};
};
