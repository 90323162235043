import { View, Text } from '@react-pdf/renderer';

export const ObservationsTable = ({
	spanishObservation,
	englishObservation = '',
}: {
	spanishObservation: string;
	englishObservation?: string;
}) => (
	<View>
		{/* Header */}
		<View
			style={{
				backgroundColor: '#eee',
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				borderTopLeftRadius: '4px',
				borderTopRightRadius: '4px',
			}}
		>
			<View
				style={{
					flexGrow: 1,
					padding: '8px',
				}}
			>
				<Text
					style={{
						fontWeight: 600,
					}}
				>
					Observaciones
				</Text>
				<Text
					style={{
						color: '#A1A1A1',
						fontSize: '8px',
						fontWeight: 400,
					}}
				>
					Observations
				</Text>
			</View>
		</View>

		{/* Content */}
		<View
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				// Centralize this (abstract into a cell component)
				borderLeft: '1px solid #eee',
				borderRight: '1px solid #eee',
				borderBottom: '1px solid #eee',
			}}
		>
			<View
				style={{
					flexGrow: 1,
					padding: '8px',
				}}
			>
				<Text>{spanishObservation}</Text>
				<Text
					style={{
						fontSize: '8px',
						color: 'grey',
					}}
				>
					{englishObservation}
				</Text>
			</View>
		</View>
	</View>
);
