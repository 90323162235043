import { useState } from 'react';

import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import type { SubmitHandler } from 'react-hook-form';

import { Button } from '@atoms';
import { FormInput, FormInputPassword } from '@molecules';
import { REGEX_EMAIL, REGEX_PASSWORD } from '@utils';
import { getUserProfile, login } from '@api';
import { setLoginSuccess, setOtpSuccess, setUserEmail, setUserProfile } from '@RTK/Slices';
import { useAppDispatch } from '@hooks';

import type { IFormLoginValues } from '@interfaces';
import toast from 'react-hot-toast';

export const FormLogin = () => {
	const [isLoading, setIsLoading] = useState<boolean>();

	const dispatch = useAppDispatch();

	const {
		formState: { errors },
		setError,
		handleSubmit,
		register,
	} = useForm<IFormLoginValues>();

	const hdlSubmit: SubmitHandler<IFormLoginValues> = async formData => {
		setIsLoading(true);

		try {
			const { twoFactorIsEnabled } = await login(formData);
			dispatch(setUserEmail(formData.email));

			if (!twoFactorIsEnabled) {
				const profileInfo = await getUserProfile();
				dispatch(setUserProfile(profileInfo));
				dispatch(setOtpSuccess(true));
			} else {
				dispatch(setLoginSuccess(true));
			}
		} catch (error) {
			console.error(error);
			// This error shouldn't be displayed like this. Consider a toast or similar.
			setError('email', {});
			setError('password', {});
			toast.error('Invalid credentials.');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<form autoComplete='off' className='w-full flex flex-col' onSubmit={handleSubmit(hdlSubmit)}>
			<FormInput
				errorMessage={errors.email?.message}
				label='Correo Electrónico'
				onError={!!errors.email}
				placeholder='E-mail'
				register={{
					...register('email', {
						required: { value: true, message: 'This field is required' },
						pattern: { value: REGEX_EMAIL, message: 'Invalid email' },
						minLength: { value: 6, message: 'Min length is 6' },
					}),
				}}
				type='email'
			/>
			<FormInputPassword
				className='mt-5'
				errorMessage={errors.password?.message}
				label='Contraseña'
				onError={!!errors.password}
				placeholder='Contraseña'
				register={{
					...register('password', {
						required: { value: true, message: 'This field is required' },
						pattern: { value: REGEX_PASSWORD, message: 'Invalid password' },
						minLength: { value: 6, message: 'Min length is 6' },
						maxLength: { value: 30, message: 'Max length is 30' },
					}),
				}}
			/>
			<Link to='/forget-password' className='self-end mt-3 font-semibold text-sm text-bummock-ocean_blue'>
				¿Olvidó su contraseña?
			</Link>
			<Button className='mt-5 w-full justify-center' isLoading={isLoading}>
				Ingresar
			</Button>
			<span className='mt-5 text-center text-sm'>
				¿Quieres ser cliente?{' '}
				<a href='https://localhost:3000/book-a-demo' className='font-semibold text-bummock-arctic_blue'>
					Haz click aqui.
				</a>
			</span>
		</form>
	);
};
