import { LinkSolid } from '@atoms';
import { ITabNavigationProps } from './TabNavigation.interfaces';
import styles from './TabNavigation.module.sass';

export const TabNavigation = ({ className = '', tabs }: ITabNavigationProps) => {
	return (
		<nav className={`${className} ${styles['tab-navigation']}`}>
			{tabs.map(tab => (
				<LinkSolid className={styles['tab-navigation__item']} key={tab.name} to={tab.path} label={tab.name} />
			))}
		</nav>
	);
};
