import { Button } from '@atoms';
import { FormInput } from '@molecules';
import { useFormContext } from 'react-hook-form';

interface IFormProductTypeProps {
	onCancel: () => void;
	onSubmit: () => void;
	isLoading: boolean;
	canSave?: boolean;
}

interface IFormProductTypeValues {
	name: string;
}

export const FormProductType = ({ onCancel, onSubmit, isLoading, canSave = true }: IFormProductTypeProps) => {
	const { register, formState } = useFormContext<IFormProductTypeValues>();

	return (
		<form className='mt-8 flex flex-col gap-8' onSubmit={onSubmit} autoComplete='off'>
			<FormInput
				disabled={isLoading}
				label='Nombre'
				placeholder='Name'
				required={true}
				onError={!!formState.errors.name}
				errorMessage={formState.errors.name?.message}
				register={register('name', {
					required: { value: true, message: 'This field is required' },
					shouldUnregister: true,
				})}
			/>
			<div className='flex gap-4'>
				<Button disabled={isLoading} type='button' onClick={onCancel} className='px-9' variant='secondary'>
					Cancelar
				</Button>
				<Button disabled={!canSave} isLoading={isLoading} className='px-9'>
					Guardar
				</Button>
			</div>
		</form>
	);
};
