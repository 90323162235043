import { Container } from '../components/Container';
import { View, Text, StyleSheet, Link } from '@react-pdf/renderer';
import { NumberedContentPage } from './NumberedContentPage';

const styles = StyleSheet.create({
	headingSpanishText: {
		fontSize: '14px',
		fontWeight: 'bold',
	},
	headingEnglishText: {
		color: '#A1A1A1',
	},
	tableHeaderContainer: {
		color: 'white',
		backgroundColor: '#092D45',
		display: 'flex',
		flexDirection: 'row',
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
	},
	contentsContainer: {
		flexGrow: 1,
		padding: '8px',
	},
	pageNumberContainer: {
		flexBasis: '75px',
		padding: '8px',
	},
	tableHeaderSpanishText: {
		fontWeight: 600,
	},
	tableHeaderEnglishText: {
		fontSize: '8px',
		fontWeight: 400,
	},
	marginHorizontalCenter: {
		margin: '0 auto',
	},
	rowContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	contentsCellContainer: {
		flexGrow: 1,
		padding: '8px',
		// Centralize this (abstract into a cell component)
		borderLeft: '1px solid #eee',
		borderRight: '1px solid #eee',
		borderBottom: '1px solid #eee',
		width: '488px',
	},
	rowSpanishText: {
		fontWeight: 600,
		// Got overriden by link
		color: '#3F3F3F',
	},
	rowEnglishText: {
		fontSize: '8px',
		fontWeight: 400,
		color: '#A1A1A1',
	},
	pageNumberCellContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',

		flexBasis: '75px',
		padding: '8px',
		textAlign: 'center',

		borderRight: '1px solid #eee',
		borderBottom: '1px solid #eee',
	},
});

// I Think these are static for Risk Protection
const PDF_CONTENTS = [
	{
		spanish: 'Lista de verificación de los procedimientos de seguridad.',
		english: 'Security procedures check list.',
		linkSrc: 'SECURITY_PROCEDURES_PAGE',
	},
	{
		spanish: 'Registro de contenedor inspeccionado.',
		english: 'Record of inspected container.',
		linkSrc: 'RECORD_OF_INSPECTED_CONTAINER',
	},
	{
		spanish: 'Formato de inspección de contenedores.',
		english: 'Container inspection format.',
		linkSrc: 'CONTAINER_INSPECTION_FORMAT',
	},
	{
		spanish: 'Fotografías de flujos.',
		english: 'Workflows images.',
		linkSrc: 'WORKFLOWS_IMAGES',
	},
];

export const TOCPage = () => (
	<NumberedContentPage>
		<Container>
			<Text style={styles.headingSpanishText}>Reporte de inspección de contenedor</Text>
			<Text style={styles.headingEnglishText}>Inspection Report</Text>
		</Container>

		{/* Table */}
		<View>
			{/* Header */}
			<View style={styles.tableHeaderContainer}>
				<View style={styles.contentsContainer}>
					<Text style={styles.tableHeaderSpanishText}>Contenido</Text>
					<Text style={styles.tableHeaderEnglishText}>Contents</Text>
				</View>
				<View style={styles.pageNumberContainer}>
					<View style={styles.marginHorizontalCenter}>
						<Text style={styles.tableHeaderSpanishText}>Página</Text>
						<Text style={styles.tableHeaderEnglishText}>Page</Text>
					</View>
				</View>
			</View>

			{/* Table rows */}
			{/* TODO: Dynamically set page numbers & set links to proper pages */}
			{PDF_CONTENTS.map(({ spanish, english, linkSrc }, i) => (
				<View key={i} style={styles.rowContainer}>
					<View style={styles.contentsCellContainer}>
						<Link style={{ textDecoration: 'none' }} src={`#${linkSrc}`}>
							<Text style={styles.rowSpanishText}>{spanish}</Text>
							<Text style={styles.rowEnglishText}>{english}</Text>
						</Link>
					</View>
					<View style={styles.pageNumberCellContainer}>
						<Link style={{ textDecoration: 'none' }} src={`#${linkSrc}`}>
							<Text style={{ color: '#3F3F3F' }}>{i + 3}</Text>
						</Link>
					</View>
				</View>
			))}
		</View>
	</NumberedContentPage>
);
