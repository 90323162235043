import { apiPrivate } from '../config/apiPrivate';

interface IGetBoxDetailsParams {
	boxId: string;
}

interface IGetBoxDetailsResp {
	id: string;
	createdAt: string;
	code: number;
	quantity: number;
	status: string;
	user: {
		id: string;
		firstName: string;
		lastName: string;
		image: string;
	};
}

export const getBoxDetails = async (params: IGetBoxDetailsParams): Promise<IGetBoxDetailsResp> => {
	const { data } = await apiPrivate.get<IGetBoxDetailsResp>(`/api/kits/box/${params.boxId}`);
	return data;
};
