import { getOneKitType, QUERY_KEYS } from '@api';
import { skipToken, useQuery } from '@tanstack/react-query';

export const useKitTypeDetails = (kitTypeId: string) => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.kitsTypes, kitTypeId],
		queryFn: kitTypeId ? () => getOneKitType({ id: kitTypeId }) : skipToken,
	});

	return queryResult;
};
