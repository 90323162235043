import { v4 } from 'uuid';

import { FormLabel } from '@atoms';

import type { IFormInputProps } from './FormInput.interfaces';

import styles from './FormInput.module.sass';
import { KeyboardEventHandler } from 'react';

export const FormInput = ({
	icon,
	iconPlacement = 'left',
	className = '',
	disabled,
	errorMessage,
	label,
	name,
	onChange,
	onError,
	pattern,
	placeholder,
	register,
	required,
	type,
	value,
	onKeyDown = () => {},
	onBlur = () => {},
}: IFormInputProps & { onKeyDown?: KeyboardEventHandler<HTMLInputElement>; onBlur?: () => void }) => {
	const id = v4();

	return (
		<fieldset className={`${styles['fieldset']} ${className}`}>
			<FormLabel {...{ disabled, id, label, onError, required }} />
			{icon ? (
				<div className='relative'>
					<div
						className={`absolute top-1/2 -translate-y-1/2 ${iconPlacement === 'left' ? 'left-3' : 'right-3'}`}
					>
						{icon}
					</div>
					<input
						className={`${iconPlacement === 'left' ? '!pl-8' : '!pr-8'} ${disabled ? 'text-bummock-disabled_grey_2 cursor-not-allowed' : ''}`}
						aria-disabled={disabled}
						aria-required={required}
						data-has-error={onError}
						{...{ disabled, id, name, onChange, onKeyDown, pattern, placeholder, type, value }}
						{...register}
						onBlur={onBlur}
					/>
				</div>
			) : (
				<input
					className={disabled ? 'text-bummock-disabled_grey_2 cursor-not-allowed' : ''}
					aria-disabled={disabled}
					aria-required={required}
					data-has-error={onError}
					{...{ disabled, id, name, onChange, onKeyDown, pattern, placeholder, type, value, onBlur }}
					{...register}
					onBlur={onBlur}
				/>
			)}
			{onError ? <span className={styles['fieldset--error__message']}>{errorMessage}</span> : null}
		</fieldset>
	);
};
