import { createBrowserRouter } from 'react-router-dom';

import { AuthLayout, DashboardLayout } from '@layout';
import { LoginPage, ForgotPasswordPage, VerifyEmailPage, NewPasswordPage } from '@pages';
import { DashboardRoutes } from './dashboard-routes';

const router = createBrowserRouter([
	{
		path: '/',
		element: <DashboardLayout />,
		children: DashboardRoutes,
	},
	{
		element: <AuthLayout />,
		children: [
			{
				path: '/login',
				element: <LoginPage />,
			},
			{
				path: '/forget-password',
				element: <ForgotPasswordPage />,
			},
			{
				path: '/verify-email',
				element: <VerifyEmailPage />,
			},
			{
				path: '/new-password',
				element: <NewPasswordPage />,
			},
		],
	},
]);

export default router;
