import { apiPrivate } from '../config/apiPrivate';

interface IAssignCourtyardParams {
	companyId: string;
	locationId: string;
}

interface IAssignCourtyardResp {}

export const assignLocation = async ({
	companyId,
	locationId,
}: IAssignCourtyardParams): Promise<IAssignCourtyardResp> => {
	const { data } = await apiPrivate.post<IAssignCourtyardResp>(`/api/companies/${companyId}/assign-location`, {
		locationId: locationId,
	});
	return data;
};
