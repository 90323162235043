import { findAllDispatches, QUERY_KEYS } from '@api';
import { IcEye, SearchableDropdown } from '@atoms';
import { useTableQuery } from '@hooks';
import { IDispatch } from '@interfaces';
import { BaseTable, TablePagination, TableSearchbar } from '@organisms';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

const columnHelper = createColumnHelper<IDispatch>();
const dispatchesColumns = [
	columnHelper.accessor('dispatchNumber', {
		header: 'Número de Despacho',
		cell: columnDefBase => columnDefBase.getValue().padStart(6, '0'),
	}),
	columnHelper.accessor('items', {
		header: 'Items',
	}),
	columnHelper.accessor('location', {
		header: 'Localidad',
	}),
	columnHelper.accessor('dispatchDate', {
		header: 'Fecha de Despacho',
	}),
	columnHelper.accessor('dispatchedBy', {
		header: 'Despachado por',
	}),
];

const DISPATCH_TYPES = [
	{ id: 'bulk' as const, value: 'Inventario' },
	{ id: 'kits' as const, value: 'Kits' },
];

type TDispatchTypes = (typeof DISPATCH_TYPES)[number]['id'];

const DispatchesPage = () => {
	const navigator = useNavigate();

	const [selectedDispatchType, setSelectedDispatchType] = useState<TDispatchTypes>(DISPATCH_TYPES[0].id);

	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.dispatches, selectedDispatchType],
		fetcherFn: params => findAllDispatches({ dispatchType: selectedDispatchType, ...params }),
		tableColumns: [
			...dispatchesColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { id } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcEye
								onClick={() => navigator(`${selectedDispatchType}/${id}`)}
								className='stroke-2 w-[22px] stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	return (
		<>
			<div className='flex gap-4'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<SearchableDropdown
					id={v4()}
					options={DISPATCH_TYPES}
					onChange={value => setSelectedDispatchType(value as TDispatchTypes)}
					selectedOptionId={selectedDispatchType}
				/>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default DispatchesPage;
