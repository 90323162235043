import { apiPrivate } from '../config/apiPrivate';

interface ICountKitsParams {
	kitTypeId: string;
}

type ICountKitsResp = number;

export const countKits = async ({ kitTypeId }: ICountKitsParams): Promise<ICountKitsResp> => {
	const { data } = await apiPrivate.get<ICountKitsResp>(`/api/kits/count/${kitTypeId}`);
	return data;
};
