import { apiPrivate } from '../config/apiPrivate';

interface ICreateKitTypeParams {
	name: string;
	kitUsage: string;
	company: string;
	description: string;
	itemsType: {
		productType: string;
		quantity: number;
		package_container: boolean;
	}[];
}

interface ICreateKitTypeResp {}

export const createKitType = async (params: ICreateKitTypeParams): Promise<ICreateKitTypeResp> => {
	const { data } = await apiPrivate.post<ICreateKitTypeResp>('/api/kits/types', params);
	return data;
};
