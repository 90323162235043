import { Switch } from '@atoms';
import { IFormInputsProps } from '@interfaces';
import { UseFormRegisterReturn } from 'react-hook-form';
import { v4 } from 'uuid';

interface IFormSelectProps
	extends Omit<IFormInputsProps, 'pattern' | 'placeholder' | 'value' | 'onChange' | 'register'> {
	defaultValue?: boolean;
	register: UseFormRegisterReturn<string>;
	variant?: 'primary' | 'secondary';
}

export const FormInputSwitch = ({ variant, className, label, register, defaultValue, disabled }: IFormSelectProps) => {
	const id = v4();
	return (
		<fieldset className={`flex ${label ? 'gap-[0.875rem]' : 'gap-0'} ${className}`}>
			<Switch
				id={id}
				name={register.name}
				mRef={register.ref}
				variant={variant}
				onChange={register.onChange}
				defaultValue={defaultValue}
				disabled={disabled}
			/>
			<label htmlFor={id} className='text-[0.8125rem] cursor-default'>
				{label}
			</label>
		</fieldset>
	);
};
