import { apiPrivate } from '../config/apiPrivate';

import type { IVerifyOtpValues } from '@interfaces';

interface IVerifyOtpParams extends IVerifyOtpValues {}

interface IVerifyOtpResp {
	accessToken: string;
}

export const verifyOtp = async (params: IVerifyOtpParams): Promise<IVerifyOtpResp> => {
	return apiPrivate.post('/api/auth/verify-auth-code', {
		...params,
		type: '2FA',
	});
};
