import type { Feature, Polygon } from 'geojson';

import DrawControl from './DrawControl';
import { IPoint, IViewState } from '@interfaces';
import { MapBase } from '../MapBase';
import { Marker } from 'react-map-gl';
import { IcMarker } from '@atoms';

const DRAW_STYLES = [
	// ACTIVE (being drawn)
	// line stroke
	{
		id: 'gl-draw-line',
		type: 'line',
		filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round',
		},
		paint: {
			'line-color': '#D20C0C',
			'line-dasharray': [0.2, 2],
			'line-width': 2,
		},
	},
	// polygon fill
	{
		id: 'gl-draw-polygon-fill',
		type: 'fill',
		filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
		paint: {
			'fill-color': '#D20C0C',
			'fill-outline-color': '#D20C0C',
			'fill-opacity': 0.1,
		},
	},
	// polygon mid points
	{
		id: 'gl-draw-polygon-midpoint',
		type: 'circle',
		filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
		paint: {
			'circle-radius': 3,
			'circle-color': '#fbb03b',
		},
	},
	// polygon outline stroke
	// This doesn't style the first edge of the polygon, which uses the line stroke styling instead
	{
		id: 'gl-draw-polygon-stroke-active',
		type: 'line',
		filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round',
		},
		paint: {
			'line-color': '#D20C0C',
			'line-dasharray': [0.2, 2],
			'line-width': 2,
		},
	},
	// vertex point halos
	{
		id: 'gl-draw-polygon-and-line-vertex-halo-active',
		type: 'circle',
		filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
		paint: {
			'circle-radius': 5,
			'circle-color': '#FFF',
		},
	},
	// vertex points
	{
		id: 'gl-draw-polygon-and-line-vertex-active',
		type: 'circle',
		filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
		paint: {
			'circle-radius': 3,
			'circle-color': '#D20C0C',
		},
	},

	// INACTIVE (static, already drawn)
	// line stroke
	{
		id: 'gl-draw-line-static',
		type: 'line',
		filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round',
		},
		paint: {
			'line-color': '#000',
			'line-width': 3,
		},
	},
	// polygon fill
	{
		id: 'gl-draw-polygon-fill-static',
		type: 'fill',
		filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
		paint: {
			'fill-color': '#000',
			'fill-outline-color': '#000',
			'fill-opacity': 0.1,
		},
	},
	// polygon outline
	{
		id: 'gl-draw-polygon-stroke-static',
		type: 'line',
		filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round',
		},
		paint: {
			'line-color': '#000',
			'line-width': 3,
		},
	},
];

const generatePoints = (geometry: Polygon): IPoint[] => {
	const [coords] = geometry.coordinates;
	return (
		coords
			.map(coord => ({
				lng: coord[0],
				lat: coord[1],
			}))
			// For some reason the drawing library always returns an extra
			// point that's equals to the first one so we ignore it.
			.filter((_, index, arr) => index !== arr.length - 1)
	);
};

interface IDrawableMapProps {
	isVisible: boolean;
	points?: IPoint[];
	onFinishedDrawing?: (points: IPoint[]) => void;
	onDeleteDrawing?: () => void;
	viewState: IViewState;
	markerCoords?: { lat: number; lng: number };
}

export const DrawableMap = ({
	isVisible,
	viewState,
	markerCoords,
	points = [],
	onFinishedDrawing = () => {},
	onDeleteDrawing = () => {},
}: IDrawableMapProps) => {
	const handleCreate = ({ id, geometry }: Feature<Polygon>) => {
		const points = generatePoints(geometry);
		console.log(id, points);
		const drawingButton = document.querySelector('.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon');
		drawingButton?.classList.add('!hidden');

		onFinishedDrawing(points);
	};

	const handleDelete = ({ id }: Feature<Polygon>) => {
		console.log('Deleting feature', id);

		const drawingButton = document.querySelector('.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon');
		drawingButton?.classList.remove('!hidden');

		onDeleteDrawing();
	};

	const handleUpdate = ({ geometry }: Feature<Polygon>) => {
		const newPoints = generatePoints(geometry);
		onFinishedDrawing(newPoints);
	};

	return (
		<MapBase
			isVisible={isVisible}
			viewState={viewState}
			style={{
				flexGrow: '1',
				flexBasis: '0',
				flexShrink: '1',
				width: '48.125rem',
				height: 'auto',
			}}
		>
			{!!markerCoords && (
				<Marker latitude={markerCoords.lat} longitude={markerCoords.lng} anchor='bottom'>
					<IcMarker className='w-10 stroke-[1.5] fill-blue-500' />
				</Marker>
			)}

			<DrawControl
				points={points}
				styles={DRAW_STYLES}
				position='top-right'
				displayControlsDefault={false}
				controls={{
					polygon: true,
					trash: true,
				}}
				onCreate={e => handleCreate(e.features[0])}
				onUpdate={e => handleUpdate(e.features[0])}
				onDelete={e => handleDelete(e.features[0])}
			/>
		</MapBase>
	);
};
