import { apiPublic } from '../config/apiPublic';

interface IResetPasswordParams {
	user: string;
	newPassword: string;
}

interface IResetPasswordResp {
	id: string;
	firstName: string;
	lastName: string;
}

export const resetPassword = async (params: IResetPasswordParams) => {
	return apiPublic.patch<IResetPasswordResp>('/api/auth/reset-password', params);
};
