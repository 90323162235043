import styles from './Pagination.module.sass';
import { IPaginationProps } from './Pagination.interfaces';

import { Button } from '@atoms';
import { ReactNode } from 'react';

export const Pagination = ({
	currentPageIndex,
	className,
	itemsPerPage,
	numberOfItems = 1,
	onChangePage = () => {},
}: IPaginationProps) => {
	const numberOfPages = Math.ceil(numberOfItems / itemsPerPage);

	const handleClickPage = (pageIndex: number) => {
		if (pageIndex !== currentPageIndex) onChangePage(pageIndex);
	};

	const handleClickChangePage = (amount: number) => {
		const newPage = currentPageIndex + amount;
		if (!(newPage > numberOfPages - 1 || newPage < 0)) {
			onChangePage(newPage);
		}
	};

	if (numberOfItems === 0)
		return (
			<footer className={`${className} ${styles['pagination']}`}>
				<aside className={styles['pagination__shown-items-info']}>No items to show</aside>
				<div className={styles['pagination__controls']}>
					<Button variant='secondary' onClick={() => handleClickChangePage(-1)}>
						Anterior
					</Button>
					<Button variant='secondary' onClick={() => handleClickChangePage(1)}>
						Próximo
					</Button>
				</div>
			</footer>
		);

	// Esto debe pasar cuando hay hasta 7 páginas
	let pageButtons: JSX.Element[] | ReactNode = [];
	if (numberOfPages <= 7) {
		pageButtons = Array.from({ length: numberOfPages }).map((_, index) => (
			<li key={index}>
				<Button
					variant='secondary'
					onClick={() => handleClickPage(index)}
					className={`${styles['pagination__controls__pages__page']} 
                    ${currentPageIndex === index ? styles['pagination__controls__pages__page--active'] : ''} justify-center`}
				>
					{index + 1 + ''}
				</Button>
			</li>
		));
	} else {
		let delta = 0;
		if (currentPageIndex <= 3) {
			delta = 2;
		} else if (currentPageIndex > numberOfPages - 4) {
			delta = numberOfPages - 5;
		} else {
			delta = currentPageIndex - 1;
		}

		const movingControls = Array.from({ length: 3 }, (_, idx) => (
			<Button
				key={idx + delta}
				variant='secondary'
				onClick={() => handleClickPage(idx + delta)}
				className={`${styles['pagination__controls__pages__page']} 
                    ${currentPageIndex === idx + delta ? styles['pagination__controls__pages__page--active'] : ''} justify-center`}
			>
				{idx + 1 + delta}
			</Button>
		));

		const leftBetweenButton =
			currentPageIndex >= 0 && currentPageIndex <= 3 ? (
				<Button
					variant='secondary'
					onClick={() => handleClickPage(1)}
					className={`${styles['pagination__controls__pages__page']} 
                    ${currentPageIndex === 1 ? styles['pagination__controls__pages__page--active'] : ''} justify-center`}
				>
					2
				</Button>
			) : (
				<span className='w-[45px] text-center font-extrabold'>. . .</span>
			);

		const rightBetweenButton =
			currentPageIndex <= numberOfPages && currentPageIndex > numberOfPages - 5 ? (
				<Button
					variant='secondary'
					onClick={() => handleClickPage(numberOfPages - 2)}
					className={`${styles['pagination__controls__pages__page']} 
                    ${currentPageIndex === numberOfPages - 2 ? styles['pagination__controls__pages__page--active'] : ''} justify-center`}
				>
					{numberOfPages - 1}
				</Button>
			) : (
				<span className='w-[45px] text-center font-extrabold'>. . .</span>
			);

		pageButtons = (
			<>
				<Button
					variant='secondary'
					onClick={() => handleClickPage(0)}
					className={`${styles['pagination__controls__pages__page']} 
                    ${currentPageIndex === 0 ? styles['pagination__controls__pages__page--active'] : ''} justify-center`}
				>
					1
				</Button>
				{leftBetweenButton}
				{...movingControls}
				{rightBetweenButton}
				<Button
					variant='secondary'
					onClick={() => handleClickPage(numberOfPages - 1)}
					className={`${styles['pagination__controls__pages__page']} 
                    ${currentPageIndex === numberOfPages - 1 ? styles['pagination__controls__pages__page--active'] : ''} justify-center`}
				>
					{numberOfPages}
				</Button>
			</>
		);
	}

	const fromLabel = currentPageIndex * itemsPerPage + 1;
	const toLabel = (currentPageIndex + 1) * itemsPerPage;

	return (
		<footer className={`${className} ${styles['pagination']}`}>
			<aside className={styles['pagination__shown-items-info']}>
				{numberOfItems === 1
					? 'Mostrando 1 registro'
					: `Mostrando ${fromLabel} a ${toLabel > numberOfItems ? numberOfItems : toLabel} de ${numberOfItems} registros`}
			</aside>
			<div className={styles['pagination__controls']}>
				<Button disabled={currentPageIndex <= 0} variant='secondary' onClick={() => handleClickChangePage(-1)}>
					Anterior
				</Button>
				<ol className={styles['pagination__controls__pages']}>{pageButtons}</ol>
				<Button
					disabled={currentPageIndex >= numberOfPages - 1}
					variant='secondary'
					onClick={() => handleClickChangePage(1)}
				>
					Próximo
				</Button>
			</div>
		</footer>
	);
};
