import { useState, useEffect } from 'react';
import { IcArrowDown } from '../Icons/IcArrowDown';
import { useDetectClickOutside } from '@hooks';

import styles from './MultiCheckSelect.module.sass';
import { InputCheckbox } from '../InputCheckbox/InputCheckbox';
import { IMultiCheckSelectProps } from './MultiCheckSelect.interfaces';

export const MultiCheckSelect = ({
	label = '',
	className = '',
	isOpen: isOpenProp = false,
	options: optionsProp,
	selectedOptions: selectedOptionProp = [],
	disabled,
	onChange = () => {},
	onClose = () => {},
}: IMultiCheckSelectProps) => {
	const [selectedOptions, setSelectedOptions] = useState<string[]>(selectedOptionProp);
	useEffect(() => setSelectedOptions(selectedOptionProp), [selectedOptionProp.length]);

	const [isOpen, setIsOpen] = useState<boolean>(isOpenProp);
	useEffect(() => setIsOpen(isOpenProp), [isOpenProp]);

	const { elementRef: selectRef } = useDetectClickOutside({
		isOpen,
		onClickOutside: () => handleChangeVisibility(false),
	});

	const options = optionsProp.map(option => {
		const isSelected = !!selectedOptions.find(selectedOption => selectedOption === option.id);
		return {
			...option,
			isSelected,
		};
	});

	function handleClickOption(optionId: string) {
		const isAlreadySelected = !!options.filter(option => option.isSelected).find(option => option.id === optionId);
		const newSelectedOptions = isAlreadySelected
			? selectedOptions.filter(selectedOption => selectedOption !== optionId)
			: [...selectedOptions, optionId];
		setSelectedOptions(newSelectedOptions);
		onChange(newSelectedOptions);
	}

	function handleChangeVisibility(isVisible: boolean) {
		if (disabled) return;
		setIsOpen(isVisible);
		if (!isVisible) onClose();
	}

	return (
		<div
			className={`${styles['select']} ${className} ${disabled ? '!cursor-not-allowed' : ''} ${isOpen ? 'z-10' : ''}`}
			onClick={() => handleChangeVisibility(!isOpen)}
			ref={selectRef}
		>
			<div className={`${styles['select__options']}`}>
				<div className={`${styles['select__option']} ${styles['select__option--selected']}`}>
					{label}
					<IcArrowDown
						className={`${isOpen ? 'rotate-180' : ''} stroke-2 w-[14px] ml-auto ${disabled ? 'stroke-bummock-disabled_grey_2' : 'stroke-bummock-midnight_blue'}`}
					/>
				</div>
				{isOpen &&
					options.map(option => (
						<div
							className={styles['select__option']}
							key={option.id}
							onClick={e => {
								e.stopPropagation();
								handleClickOption(option.id);
							}}
						>
							{option.value}
							<InputCheckbox
								checked={option.isSelected}
								className='bg-bummock-off_white hover:cursor-pointer'
							/>
						</div>
					))}
			</div>
		</div>
	);
};
