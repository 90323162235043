import { ITabRoute } from '@interfaces';
import { TabbedLayout } from '@layout';
import { CreateDispatchPage, DispatchContentPage, DispatchesPage } from '@pages';
import { RouteObject } from 'react-router-dom';
import { createProtectedTabRoute } from '../../router/helpers';

const dispatchSubRoutes: ITabRoute[] = [
	createProtectedTabRoute({
		path: 'create',
		tabName: 'Despachar',
		element: <CreateDispatchPage />,
		requiredPermission: 'DISPATCH_CREATE',
	}),
	createProtectedTabRoute({
		path: 'all',
		tabName: 'Despachos',
		children: [
			{
				index: true,
				element: <DispatchesPage />,
			},
			{ path: ':dispatchType/:id', element: <DispatchContentPage /> },
		],
		requiredPermission: 'DISPATCH_LIST',
	}),
];

export const dispatchRoutes: RouteObject[] = [
	{
		path: 'dispatch',
		element: <TabbedLayout tabRoutes={dispatchSubRoutes} />,
		children: dispatchSubRoutes,
	},
];
