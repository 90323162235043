import { deactivateUser, deleteUser, findAllUsers, QUERY_KEYS } from '@api';
import { Badge, Button, IcAddUser, IcTrash, IcUserDisabled, IcUserEdit } from '@atoms';
import { useConfirmation, useTableQuery } from '@hooks';
import { IUser } from '@interfaces';
import { BaseTable, ModalConfirmation, TablePagination, TableSearchbar } from '@organisms';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

const columnHelper = createColumnHelper<IUser>();
const userColumns: ColumnDef<IUser, string>[] = [
	{
		header: 'Nombre',
		accessorKey: 'firstName',
	},
	{
		header: 'Apellido',
		accessorKey: 'lastName',
	},
	{
		header: 'Correo Electrónico',
		accessorKey: 'email',
	},
	{
		header: 'Empresa',
		accessorKey: 'company',
	},
	{
		header: 'Cargo',
		accessorKey: 'accessLevel',
		enableSorting: false,
		meta: { alignment: 'center' },
	},
	columnHelper.accessor('status', {
		header: 'Estado',
		cell: columnDefBase => (
			<Badge
				label={columnDefBase.getValue()}
				variant={
					columnDefBase.getValue().toUpperCase() === 'ACTIVO'
						? 'success'
						: columnDefBase.getValue().toUpperCase() === 'DESACTIVADO'
							? 'danger'
							: 'warning'
				}
			/>
		),
		meta: { alignment: 'center' },
	}),
	{
		header: 'Último acceso',
		accessorKey: 'lastAccess',
		meta: { alignment: 'center' },
	},
];

const UsersPage = () => {
	const navigator = useNavigate();
	const queryClient = useQueryClient();

	const { confirm: confirmDeletion, confirmationModalProps: deletionConfirmationModalProps } = useConfirmation({
		confirmationMessage: '¿Estás seguro de eliminar el usuario?',
		mutationFn: deleteUser,
		onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.users] }),
	});

	const { confirm: confirmDeactivation, confirmationModalProps: deactivationConfirmationModalProps } =
		useConfirmation({
			confirmationMessage: '¿Estás seguro de desactivar el usuario?',
			mutationFn: deactivateUser,
			onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.users] }),
		});

	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.users],
		fetcherFn: findAllUsers,
		tableColumns: [
			...userColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { status, id } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcTrash
								onClick={() => confirmDeletion({ id })}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
							<IcUserDisabled
								onClick={() => confirmDeactivation({ id })}
								className={`${status.toUpperCase() === 'DESACTIVADO' ? ' fill-red-500' : 'hover:fill-bummock-midnight_blue'} w-5 cursor-pointer transition-all ease-in-out duration-200 fill-bummock-disabled_grey_2`}
							/>
							<IcUserEdit
								onClick={() => navigator(`/users/${id}/information`)}
								className='w-5 cursor-pointer transition-all ease-in-out duration-200 fill-bummock-disabled_grey_2 hover:fill-bummock-midnight_blue'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});
	return (
		<>
			<ModalConfirmation {...deactivationConfirmationModalProps} />
			<ModalConfirmation {...deletionConfirmationModalProps} />

			<div className='flex justify-between mt-8'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<div className='flex gap-4'>
					<Button onClick={() => navigator('new')}>
						<IcAddUser className='stroke-2 stroke-bummock-off_white w-[22px]' />
						Nuevo Usuario
					</Button>
				</div>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default UsersPage;
