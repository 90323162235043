import { Button } from '@atoms';
import { FormNewEntity } from '@organisms';
import { useNavigate } from 'react-router-dom';

const NewEntityPage = () => {
	const navigator = useNavigate();

	return (
		<>
			<header className='flex gap-2 mt-8'>
				<Button
					onClick={() => navigator('/entities')}
					className='!shadow-none !bg-bummock-arctic_blue'
					variant='secondary'
				>
					Clientes
				</Button>
			</header>

			<h2 className='text-xl font-bold text-bummock-ocean_blue'>Detalles de Entidad</h2>
			<FormNewEntity />
		</>
	);
};

export default NewEntityPage;
