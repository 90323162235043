import { NavLink, useLocation } from 'react-router-dom';

import styles from './SidebarLink.module.sass';
import { ReactNode } from 'react';
import { MODULES } from '@utils';
import { useAppSelector } from '@hooks';

interface ISidebarLinkProps {
	to: string;
	label: string;
	icon?: ReactNode;
	className?: string;
	onClick?: () => void;
	permission?: keyof typeof MODULES;
}

const PermissionWrapper = ({ children, permission }: { children: ReactNode; permission?: keyof typeof MODULES }) => {
	const { permissions: currentPermissions } = useAppSelector(state => state.userProfile);
	if (!permission) return children;
	const permit = currentPermissions.some(currentPermission => {
		const [, p] = currentPermission.split(':');
		return p === permission;
	});
	if (permit) return children;
	return null;
};

export const SidebarLink = ({ to, label, icon, className = '', onClick = () => {}, permission }: ISidebarLinkProps) => {
	const locations = useLocation();
	const currentLocationBasePath = locations.pathname.split('/').filter(item => item.length)[0];
	const toBasePath = to.split('/').filter(item => item.length)[0];
	const isActive = currentLocationBasePath === toBasePath;

	return (
		<PermissionWrapper permission={permission}>
			<NavLink
				onClick={onClick}
				className={`${className} ${styles['link']} ${isActive ? styles['active'] : ''}`}
				to={to}
				data-type='button'
			>
				{icon}
				{label}
			</NavLink>
		</PermissionWrapper>
	);
};
