import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

interface IAuthState {
	loginSuccess: boolean;
	newPasswordSuccess?: boolean;
	userEmail: string;
	userId: string;
	otpSuccess: boolean;
	loaderAnimation: {
		hasShrinked: boolean;
		isDone: boolean;
	};
}

const initialState: IAuthState = {
	loginSuccess: false,
	newPasswordSuccess: false,
	userEmail: '',
	userId: '',
	otpSuccess: false,
	loaderAnimation: {
		hasShrinked: false,
		isDone: false,
	},
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setLoginSuccess: (_state, { payload }: PayloadAction<boolean>) => {
			_state.loginSuccess = payload;
		},
		setOtpSuccess: (_state, { payload }: PayloadAction<boolean>) => {
			_state.otpSuccess = payload;
		},
		setHasShrinked: (_state, { payload }: PayloadAction<boolean>) => {
			_state.loaderAnimation.hasShrinked = payload;
		},
		finishLoadingAnimation: _state => {
			_state.loaderAnimation.isDone = true;
		},
		setUserEmail: (_state, { payload }: PayloadAction<string>) => {
			_state.userEmail = payload;
		},
		setUserId: (_state, { payload }: PayloadAction<string>) => {
			_state.userId = payload;
		},
		setNewPasswordSuccess: (_state, { payload }: PayloadAction<boolean>) => {
			_state.newPasswordSuccess = payload;
		},
		resetAuthState: () => initialState,
	},
});

export const {
	setLoginSuccess,
	setUserEmail,
	setUserId,
	setOtpSuccess,
	setHasShrinked,
	finishLoadingAnimation,
	setNewPasswordSuccess,
	resetAuthState,
} = authSlice.actions;
