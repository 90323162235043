import { apiPrivate } from '../config/apiPrivate';

interface IGetOneCompanyParams {
	companyId: string;
}

interface IGetOneCompanyResp {
	id: string;
	createdAt: string;
	updatedAt: string;
	deleteAt: null;
	name: string;
	identificationNumber: string;
	email: string;
	path: string;
	yardCount: 0;
	farmCount: 0;
	shippingLineCount: 0;
	companyCount: 0;
	numberOfCreatedUsers: 0;
	isActive: true;
	image: string;
	admins: {
		id: string;
		firstName: string;
		lastName: string;
		phoneNumber: string;
		email: string;
		city: string;
	}[];
}

export const getOneCompany = async (params: IGetOneCompanyParams): Promise<IGetOneCompanyResp> => {
	const { data } = await apiPrivate.get<IGetOneCompanyResp>(`/api/companies/${params.companyId}`);
	return data;
};
