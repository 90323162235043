import { IKitType } from '@interfaces';
import { IPaginatedResourceParams, IPaginatedResourceResp, getPaginatedResource } from '@api';
import moment from 'moment';

interface IKitTypeResponseEntity {
	id: string;
	createdAt: string;
	name: string;
	description: string;
	isActive: boolean;
	kitUsage: string;
	user: string;
}

export const findAllKitsTypes = async (params: IPaginatedResourceParams): Promise<IPaginatedResourceResp<IKitType>> => {
	const { data } = await getPaginatedResource<IKitTypeResponseEntity>('/api/kits/types', params);

	const kitTypes: IKitType[] =
		data?.data.map(({ id, createdAt, isActive, kitUsage, name, user }) => ({
			id,
			name,
			usage: kitUsage,
			createdBy: user,
			status: isActive ? 'Activo' : 'Desactivado',
			creationDate: moment.utc(createdAt).format('DD/MM/YYYY'),
		})) || [];

	return {
		...data,
		data: kitTypes,
	};
};
