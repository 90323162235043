import { ButtonSolid, IcExpand, IcLogOut, Isotype, LinkSolid, Logotype } from '@atoms';
import { Navbar } from '@molecules';

import styles from './Sidebar.module.sass';
import { useState } from 'react';
import { useAppDispatch } from '@hooks';
import { clearUserProfile, resetAuthState } from '@RTK/Slices';

export const Sidebar = ({ className = '' }: { className?: string }) => {
	const [isCollapsed, setIsCollapsed] = useState(false);
	const dispatcher = useAppDispatch();

	return (
		<aside className={`${className} ${styles['sidebar']} ${isCollapsed ? styles['sidebar--collapsed'] : ''}`}>
			<div className={styles['sidebar__imagotype']}>
				<Isotype />
				<Logotype className={styles['sidebar__imagotype__logo']} />
			</div>
			<div className={`${styles['sidebar__panel']}`}>
				<ButtonSolid
					icon={<IcExpand className='stroke-bummock-disabled_grey_2' />}
					className={`h-auto p-1 leading-[0] bg-bummock-off_white text-bummock-midnight_blue font-semibold ${styles['sidebar__panel__toggle-size']}`}
					onClick={() => setIsCollapsed(prev => !prev)}
				/>
				<Navbar className={styles['sidebar__panel__navbar']} />
				<LinkSolid
					onClick={() => {
						dispatcher(clearUserProfile());
						dispatcher(resetAuthState());
					}}
					icon={<IcLogOut className='stroke-bummock-midnight_blue' />}
					to='/login'
					label='Cerrar Sesión'
				/>
			</div>
		</aside>
	);
};
