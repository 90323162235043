import { Outlet } from 'react-router-dom';

import { LogoLoader } from '@atoms';
import { useAppSelector } from '@hooks';
import { useRef } from 'react';

const AuthLayout = () => {
	const {
		loaderAnimation: { hasShrinked },
	} = useAppSelector(state => state.auth);
	const layoutRef = useRef<HTMLElement>(null);

	return (
		<section ref={layoutRef} className='w-screen h-screen grid grid-cols-2' style={{ backgroundColor: '#f9f9f9' }}>
			<figure className='w-full h-[calc(100%_-_32px)] my-auto rounded-tr-2xl rounded-br-2xl overflow-hidden'>
				<img
					src='/trucks-3.jpg'
					className='w-full h-full object-cover bg-blend-darken filter brightness-90'
					alt=''
				/>
			</figure>
			<aside className='w-full h-full flex flex-col gap-y-16 justify-center items-center'>
				<LogoLoader isLoading={hasShrinked} />
				<div className='w-full max-w-[25rem]'>
					<Outlet />
				</div>
			</aside>
		</section>
	);
};

export default AuthLayout;
