import { apiPrivate } from '../config/apiPrivate';

interface ICreateCompanyParams {
	name: string;
	identificationNumber: string;
	email: string;
	yardCount: number;
	farmCount: number;
	shippingLineCount: number;
	companyCount: number;
	numberOfCreatedUsers: number;
	file: File;
	adminDni: string;
	adminFirstName: string;
	adminLastName: string;
	adminAge: number;
	adminPhoneNumber: string;
	adminEmail: string;
	adminCity: string;
}

interface ICreateCompanyResp {
	id: string;
	name: string;
	identificationNumber: string;
	email: string;
	yardCount: number;
	farmCount: number;
	shippingLineCount: number;
	companyCount: number;
	numberOfCreatedUsers: number;
	createdAt: string;
	updatedAt: string;
}

export const createCompany = async (params: ICreateCompanyParams): Promise<ICreateCompanyResp> => {
	const formData = new FormData();
	for (const [key, value] of Object.entries(params)) {
		formData.append(key, value);
	}
	const { data } = await apiPrivate.post<ICreateCompanyResp>('/api/companies', params, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return data;
};
