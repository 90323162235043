import type { FC } from 'react';

import type { IBadgeProps } from './Badge.interfaces';

import styles from './Badge.module.sass';

export const Badge: FC<IBadgeProps> = ({ variant = 'success', label }) => (
	<span className={styles['badge']} data-variant={variant}>
		{label}
	</span>
);
