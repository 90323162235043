import { apiPrivate } from '../config/apiPrivate';

interface ICreateBulkDispatchParams {
	location: string;
	items: {
		id: string;
		quantity: number;
		startCode: string;
	}[];
}

interface ICreateBulkDispatchResp {}

export const createBulkDispatch = async (params: ICreateBulkDispatchParams): Promise<ICreateBulkDispatchResp> => {
	const { data } = await apiPrivate.post<ICreateBulkDispatchResp>('/api/dispatches/bulk', params);
	return data;
};
