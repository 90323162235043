import { ILocation } from '@interfaces';
import { IPaginatedResourceParams, getPaginatedResource } from '@api';

interface IFindAllLocations extends IPaginatedResourceParams {
	companyId: string;
}

export const findAllLocationsByCompany = async ({ companyId, ...restParams }: IFindAllLocations) => {
	const { data } = await getPaginatedResource<ILocation>(`/api/locations/company/${companyId}`, restParams);
	return data;
};
