import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { findAllSeals, QUERY_KEYS } from '@api';
import { Badge } from '@atoms';
import { useTableQuery } from '@hooks';
import { ISeal } from '@interfaces';
import { BaseTable, TablePagination, TableSearchbar } from '@organisms';

const columnHelper = createColumnHelper<ISeal>();
const sealsColumns: ColumnDef<ISeal, string>[] = [
	{
		header: 'Número de Sello',
		accessorKey: 'id',
		meta: { alignment: 'center' },
	},
	{
		header: 'Fecha de Encriptación',
		accessorKey: 'encryptionDate',
		meta: { alignment: 'center' },
	},
	{
		header: 'Hora de Encriptación',
		accessorKey: 'encryptionTime',
		meta: { alignment: 'center' },
	},
	{
		header: 'Encriptado Por',
		accessorKey: 'encryptedBy',
	},
	{
		header: 'Asignado a Cliente',
		accessorKey: 'clientAssignedTo',
	},
	columnHelper.accessor('status', {
		header: 'Estado',
		cell: columnDefBase => (
			<Badge
				label={columnDefBase.getValue()}
				variant={columnDefBase.getValue().toUpperCase() === 'ACTIVO' ? 'success' : 'warning'}
			/>
		),
		meta: { alignment: 'center' },
		enableSorting: false,
	}),
	columnHelper.display({
		header: 'Acciones',
		cell: () => <span className='underline cursor-pointer'>Visualizar</span>,
		meta: { alignment: 'right' },
	}),
];

const SealsPage = () => {
	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.seals],
		fetcherFn: findAllSeals,
		tableColumns: sealsColumns,
	});
	return (
		<>
			<TableSearchbar tableQueryResult={tableQueryResult} />
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default SealsPage;
