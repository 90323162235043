import { ITabRoute } from '@interfaces';
import { TabbedLayout } from '@layout';
import { CompaniesLocationsPage, CompaniesWorkflowsPage, EditCompanyInformationPage } from '@pages';
import { RouteObject } from 'react-router-dom';
import { createProtectedTabRoute } from '../../router/helpers';

const companySubRoutes: ITabRoute[] = [
	createProtectedTabRoute({
		path: 'information',
		tabName: 'Información',
		requiredPermission: 'MY_COMPANY_INFORMATION',
		element: <EditCompanyInformationPage />,
	}),
	createProtectedTabRoute({
		path: 'locations',
		tabName: 'Localidades',
		requiredPermission: 'MY_COMPANY_LOCATIONS',
		element: <CompaniesLocationsPage />,
	}),
	createProtectedTabRoute({
		path: 'workflows',
		tabName: 'Flujos de Trabajo',
		requiredPermission: 'MY_COMPANY_WORKFLOWS',
		element: <CompaniesWorkflowsPage />,
	}),
];

export const companyRoutes: RouteObject[] = [
	{
		path: 'company',
		element: <TabbedLayout tabRoutes={companySubRoutes} />,
		children: companySubRoutes,
	},
];
