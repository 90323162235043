import { deleteCategory, findAllCategories, QUERY_KEYS } from '@api';
import { Badge, Button, IcEdit, IcPlus, IcTrash } from '@atoms';
import { useAppSelector, useConfirmation, useTableQuery } from '@hooks';
import { ICategory } from '@interfaces';
import {
	BaseTable,
	ModalConfirmation,
	ModalEditCategory,
	ModalNewCategory,
	TablePagination,
	TableSearchbar,
} from '@organisms';
import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { canAccess } from '@utils';
import { PermissionWrapper } from '@wrappers';
import { useState } from 'react';

const columnHelper = createColumnHelper<ICategory>();
const categoriesColumns = [
	columnHelper.accessor('name', {
		header: 'Nombre de Categoría',
	}),
	columnHelper.accessor('productTypeCount', {
		header: 'Tipos Asignados',
		meta: { alignment: 'center' as const },
	}),
	columnHelper.accessor('productCount', {
		header: 'Productos Asignados',
		meta: { alignment: 'center' as const },
	}),
	columnHelper.accessor('inventoryCount', {
		header: 'Inventario en Categoría',
		meta: { alignment: 'center' as const },
	}),
	columnHelper.accessor('isActive', {
		header: 'Estado',
		cell: columnDefBase => (
			<Badge
				label={columnDefBase.getValue() ? 'Activo' : 'Desactivado'}
				variant={columnDefBase.getValue() ? 'success' : 'danger'}
			/>
		),
		meta: { alignment: 'left' as const },
		enableSorting: false,
	}),
];

const INITIAL_MODAL_STATE = {
	isModalVisible: false,
	editingItemId: '',
};

const CategoriesPage = () => {
	const [{ isModalVisible, editingItemId }, setModalState] = useState(INITIAL_MODAL_STATE);
	const queryClient = useQueryClient();
	const { confirm, confirmationModalProps: confirmDeletionModalProps } = useConfirmation({
		confirmationMessage: '¿Estás seguro de eliminar la categoría?',
		mutationFn: deleteCategory,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.categories] });
		},
	});

	const permissions = useAppSelector(state => state.userProfile.permissions);
	const shouldShowActions = canAccess({ module: 'INVENTORY_CATEGORIES', minimumPermission: 'EDIT', permissions });

	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.categories],
		fetcherFn: findAllCategories,
		tableColumns: [
			...categoriesColumns,
			...(shouldShowActions
				? [
						columnHelper.display({
							header: 'Acciones',
							cell: ({ row }) => {
								const { id } = row.original;
								return (
									<div className='flex justify-end gap-4'>
										<PermissionWrapper module='INVENTORY_CATEGORIES' minimumPermission='EDIT'>
											<IcEdit
												onClick={() =>
													setModalState(prev => ({
														...prev,
														isModalVisible: true,
														editingItemId: id,
													}))
												}
												className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
											/>
										</PermissionWrapper>
										<PermissionWrapper module='INVENTORY_CATEGORIES' minimumPermission='DELETE'>
											<IcTrash
												onClick={() => confirm({ id })}
												className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
											/>
										</PermissionWrapper>
									</div>
								);
							},
							meta: { alignment: 'right' },
						}),
					]
				: []),
		],
	});

	return (
		<>
			<ModalConfirmation {...confirmDeletionModalProps} />

			<ModalNewCategory
				isVisible={isModalVisible && !editingItemId}
				onCloseModal={() =>
					setModalState(prev => ({
						...prev,
						isModalVisible: false,
					}))
				}
			/>
			<ModalEditCategory
				editingItemId={editingItemId}
				isVisible={isModalVisible && !!editingItemId}
				onCloseModal={() =>
					setModalState(prev => ({
						...prev,
						isModalVisible: false,
						editingItemId: '',
					}))
				}
			/>

			<div className='flex justify-between'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<PermissionWrapper module='INVENTORY_CATEGORIES' minimumPermission='CREATE'>
					<Button
						onClick={() =>
							setModalState({
								...INITIAL_MODAL_STATE,
								isModalVisible: true,
							})
						}
					>
						<IcPlus className='stroke-2 stroke-bummock-off_white w-5' />
						Crear Categoría
					</Button>
				</PermissionWrapper>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default CategoriesPage;
