import { findAllBoxes, QUERY_KEYS } from '@api';
import { Badge, Button, IcEye, IcPaperPlane, IcPlus } from '@atoms';
import { useTableQuery } from '@hooks';
import { IBox } from '@interfaces';
import { BaseTable, TablePagination, TableSearchbar } from '@organisms';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

const columnHelper = createColumnHelper<IBox>();
const boxColumns: ColumnDef<IBox, string>[] = [
	columnHelper.accessor('code', {
		header: 'Box ID',
		cell: columnDefBase => columnDefBase.getValue().padStart(6, '0'),
	}),
	{
		header: 'Tipo de Kit',
		accessorKey: 'kitType',
	},
	{
		header: 'Uso del Kit',
		accessorKey: 'usage',
	},
	{
		header: 'Fecha de creación',
		accessorKey: 'creationDate',
	},
	{
		header: 'Creado por',
		accessorKey: 'createdBy',
	},
	{
		header: 'Cantidad',
		accessorKey: 'quantity',
	},
	columnHelper.accessor('status', {
		header: 'Estado',
		cell: columnDefBase => (
			<Badge
				label={columnDefBase.getValue()}
				variant={columnDefBase.getValue() === 'En progreso' ? 'warning' : 'success'}
			/>
		),
		meta: { alignment: 'left' },
		enableSorting: false,
	}),
];

const KitsPage = () => {
	const navigator = useNavigate();
	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.boxes],
		fetcherFn: findAllBoxes,
		tableColumns: [
			...boxColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { id } = row.original;
					console.log(id);
					return (
						<div className='flex justify-end gap-4'>
							<IcEye
								onClick={() => navigator(`/kits/boxes/${id}`)}
								className='stroke-2 w-[22px] stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});
	return (
		<>
			<div className='flex justify-between'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<div className='flex gap-4'>
					<Button onClick={() => navigator('/dispatch/create')}>
						<IcPaperPlane className='stroke-2 stroke-white w-[22px]' />
						Despachar
					</Button>
					<Button onClick={() => navigator('../new')}>
						<IcPlus className='stroke-2 stroke-bummock-off_white w-5' />
						Crear Kit
					</Button>
				</div>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default KitsPage;
