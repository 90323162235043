import { apiPrivate } from '../config/apiPrivate';

interface IUpdateSupplierParams {
	id: string;
	name?: string;
	identification?: string;
}

interface IUpdateSupplierResp {}

export const updateSupplier = async ({ id, ...restParams }: IUpdateSupplierParams): Promise<IUpdateSupplierResp> => {
	const { data } = await apiPrivate.patch<IUpdateSupplierResp>(`/api/suppliers/${id}`, restParams);
	return data;
};
