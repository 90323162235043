import { IEntity } from '@interfaces';
import { getPaginatedResource, IPaginatedResourceParams } from '@api';

interface IEntityResponseEntity {
	id: string;
	name: string;
	identificationNumber: string;
	status: string;
	relation: string;
	email: string;
}

export const findAllEntities = async (params: IPaginatedResourceParams) => {
	const { data } = await getPaginatedResource<IEntityResponseEntity>('/api/entities', params);

	const entities: IEntity[] = data.data.map(({ id, identificationNumber, name, relation, status, email }) => ({
		id,
		name,
		relationship: relation,
		adminEmail: email,
		nationalBusinessNumber: identificationNumber,
		status,
	}));

	return {
		...data,
		data: entities,
	};
};
