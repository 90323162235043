import { apiPrivate } from '@api';

interface ILocationsTypeResponseEntity {
	id: string;
	name: string;
	isActive: boolean;
}

export const findAllLocationsTypes = async () => {
	const { data } = await apiPrivate.get<ILocationsTypeResponseEntity[]>('/api/locations/types');
	return data;
};
