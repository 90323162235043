import { apiPrivate } from '../config/apiPrivate';

interface IGetKitDetailsParams {
	kitId: string;
}

interface IGetKitDetailsResp {
	id: string;
	createdAt: string;
	kit: string;
	status: string;
	user: {
		firstName: string;
		lastName: string;
		image: string;
	};
	kitType: {
		name: string;
	};
	kitDetail: {
		securityItemType: string;
		items: {
			id: string;
			value: string;
		}[];
	}[];
}

export const getKitDetails = async (params: IGetKitDetailsParams): Promise<IGetKitDetailsResp> => {
	const { data } = await apiPrivate.get<IGetKitDetailsResp>(`/api/kits/${params.kitId}`);
	return data;
};
