import { ReactNode } from 'react';
import { useAppSelector } from '@hooks';
import { MODULES, PERMISSIONS_BY_LEVELS, TPermissions } from '@utils';

export const PermissionWrapper = ({
	module,
	minimumPermission,
	children,
}: {
	module: keyof typeof MODULES;
	minimumPermission: TPermissions;
	children: ReactNode;
}) => {
	const minimumLevel = PERMISSIONS_BY_LEVELS[minimumPermission];

	const permissions = useAppSelector(state => state.userProfile.permissions);
	const permissionIndex = permissions.findIndex(permission => {
		const [, currentModule] = permission.split(':');
		return module === currentModule;
	});

	// Si no se encuentra ningún permiso de ese módulo, se presenta un fallback
	if (permissionIndex === -1) return null;

	const [, , currentPermission] = permissions[permissionIndex].split(':');
	const currentLevel = PERMISSIONS_BY_LEVELS[currentPermission as TPermissions];

	if (currentLevel < minimumLevel) return null;

	return children;
};
