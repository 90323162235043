// @ts-nocheck
import { useEffect } from 'react';

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useControl, useMap } from 'react-map-gl';

import type { DrawCreateEvent, DrawDeleteEvent, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw';
import type { Feature, Polygon } from 'geojson';
import type { ControlPosition } from 'react-map-gl';

import type { IPoint } from '@interfaces';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
	position?: ControlPosition;
	points: IPoint[];

	onCreate?: (e: DrawCreateEvent & { features: Feature<Polygon>[] }) => void;
	onUpdate?: (e: DrawUpdateEvent & { features: Feature<Polygon>[] }) => void;
	onDelete?: (e: DrawDeleteEvent & { features: Feature<Polygon>[] }) => void;
};

export default function DrawControl(props: DrawControlProps) {
	const map = useMap();

	const drawControl = useControl<MapboxDraw>(
		() => {
			// Add custom buttons
			return new MapboxDraw(props);
		},
		({ map }) => {
			map.on('draw.create', props.onCreate!);
			map.on('draw.update', props.onUpdate!);
			map.on('draw.delete', props.onDelete!);
		},
		({ map }) => {
			map.off('draw.create', props.onCreate!);
			map.off('draw.update', props.onUpdate!);
			map.off('draw.delete', props.onDelete!);
		},
		{
			position: props.position,
		}
	);

	// Synchronizes with map to delete features
	useEffect(() => {
		if (props.points.length !== 0) return;

		// We'll only have one feature
		const [feature] = drawControl.getAll().features;
		if (!feature) return;

		const featureId = feature.id;
		if (!featureId) return;

		const toDeleteFeature = drawControl.get(String(featureId));
		const actualMap = map.current?.getMap();

		// Fire the deleting event so that the drawing button can be enabled back
		actualMap?.fire('draw.delete', { features: [toDeleteFeature] });

		// Removes all (there will only be one) the drawn features
		drawControl.deleteAll();
	}, [props.points.length]);

	// Synchronizes with map to make the drawn shape fully controllable
	useEffect(() => {
		const coordinates = props.points.map(point => [point.lng, point.lat]);
		if (!coordinates.length) return;

		drawControl.deleteAll();
		drawControl.add({
			type: 'Polygon',
			// Mapbox API requires to specify that the shape ends where it begins
			coordinates: [[...coordinates, coordinates[0]]],
		});
	}, [props.points.map(point => `${point.lat}${point.lng}`).join('')]);

	return null;
}

DrawControl.defaultProps = {
	onCreate: () => {},
	onUpdate: () => {},
	onDelete: () => {},
};
