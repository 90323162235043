import { apiPrivate } from '@api';
import { SortingState } from '@tanstack/react-table';
import { ITEMS_PER_PAGE } from '@utils';

// Maybe make this generic so that it accepts filters
interface IPaginatedResourceParams {
	page: number;
	sorting: SortingState;
	searchTerm: string;
	limit?: number;
	filters?: Record<string, string>;
}

interface IPaginatedResourceResp<T> {
	meta: {
		itemsPerPage: number;
		totalItems: number;
		currentPage: number;
		totalPages: number;
	};
	data: T[];
}

export const getPaginatedResource = <T>(endpoint: string, params: IPaginatedResourceParams) => {
	// Transforms the provided filter into a NestJS pagination format
	const processedFilters = Object.fromEntries(
		Object.entries<string>(params.filters ?? {}).map(([key, value]) => {
			return [`filter.${key}`, value] as [string, string];
		})
	);
	return apiPrivate.get<IPaginatedResourceResp<T>>(endpoint, {
		params: {
			limit: params.limit ?? ITEMS_PER_PAGE,
			page: params.page + 1,
			search: params.searchTerm,
			sortBy: params.sorting.map(column => `${column.id}:${column.desc ? 'DESC' : 'ASC'}`).join(''),
			...processedFilters,
		},
	});
};

export type { IPaginatedResourceParams, IPaginatedResourceResp };
