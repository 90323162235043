import { IUser } from '@interfaces';
import { getPaginatedResource, IPaginatedResourceParams, IPaginatedResourceResp } from '@api';

interface IUserResponseEntity {
	id: string;
	createdAt: string;
	updatedAt: string;
	deleteAt: string;
	dni: string;
	firstName: string;
	lastName: string;
	age: number;
	email: string;
	password: string;
	resetPasswordToken: string;
	isActive: boolean;
	hasChangePassword: boolean;
	lastSession: string;
	companies: {
		id: string;
		name: string;
	}[];
	accessLevel: {
		id: string;
		name: string;
	}[];
}

export const findAllUsers = async (params: IPaginatedResourceParams): Promise<IPaginatedResourceResp<IUser>> => {
	const { data } = await getPaginatedResource<IUserResponseEntity>('/api/users', params);

	const users: IUser[] =
		data?.data.map(({ id, accessLevel, companies, email, firstName, lastName, isActive }) => ({
			id,
			accessLevel: accessLevel[0]?.name ?? '-',
			company: companies[0]?.name ?? 'Bummock S.A',
			email,
			firstName,
			lastName,
			lastAccess: '-',
			status: isActive ? 'Activo' : 'Desactivado',
		})) || [];

	return {
		...data,
		data: users,
	};
};
