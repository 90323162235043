import { getOneUser, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

export const useUser = (userId: string) => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.users, userId],
		queryFn: () => getOneUser({ userId }),
	});

	return queryResult;
};
