import { MODULES, PERMISSIONS_BY_LEVELS, TPermissions } from '@utils';

/**
 * Capitalizes strings. If the provided string has more than one word (contains spaces)
 * all the words get capitalized.
 * @param string
 * @returns
 */
export function capitalize(string: string) {
	if (!string.length) return '';
	const spaceSeparator = ' ';
	return string
		.split(spaceSeparator)
		.map(word => {
			const capitalizedFirstChar = word.at(0)?.toUpperCase();
			const restCharacters = word.slice(1).toLowerCase();
			return `${capitalizedFirstChar}${restCharacters}`;
		})
		.join(spaceSeparator);
}

export const debounce = (func: () => void, ms: number) => {
	let timeout: NodeJS.Timeout;
	return function () {
		clearTimeout(timeout);
		timeout = setTimeout(() => func(), ms);
	};
};

export const getDirtyFormData = <T>(dirtyFields: Partial<Record<keyof T, boolean>>, formData: T) => {
	const dirties = Object.keys(dirtyFields).reduce((prev, curr) => {
		return {
			...prev,
			[curr]: formData[curr as keyof T],
		};
	}, {} as Partial<T>);

	return dirties;
};

export const generateLastCode = (serialBegin: string, quantity: number) => {
	const letters = serialBegin.match(/[a-zA-Z]+/g) || [];
	const startLetter = letters[0] || '';
	const endLetter = letters[1] || '';

	const matchedNumbers = serialBegin.match(/\d+/g) || ['1'];

	if (!quantity) return '';

	const numDigit = matchedNumbers[0].length;
	const initialNumber = +matchedNumbers[0];
	const lastNumbers = String(initialNumber + quantity - 1).padStart(numDigit, '0');

	return `${startLetter}${lastNumbers}${endLetter}`;
};

export const canAccess = ({
	module,
	minimumPermission,
	permissions,
}: {
	module: keyof typeof MODULES;
	minimumPermission: TPermissions;
	permissions: string[];
}) => {
	const minimumLevel = PERMISSIONS_BY_LEVELS[minimumPermission];

	const permissionIndex = permissions.findIndex(permission => {
		const [, currentModule] = permission.split(':');
		return module === currentModule;
	});

	// Si no se encuentra ningún permiso de ese módulo, se presenta un fallback
	if (permissionIndex === -1) return false;

	const [, , currentPermission] = permissions[permissionIndex].split(':');
	// console.log(currentPermission);
	const currentLevel = PERMISSIONS_BY_LEVELS[currentPermission as TPermissions];
	// console.log(currentLevel);

	if (currentLevel < minimumLevel) return false;

	return true;
};

export function getWeekOfYear(date: Date): number {
	const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
	const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
	return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}
