import { apiPrivate } from '../config/apiPrivate';

interface IDeleteCompanyParams {
	id: string;
}

interface IDeleteCompanyResp {}

export const deleteCompany = async (params: IDeleteCompanyParams): Promise<IDeleteCompanyResp> => {
	const { data } = await apiPrivate.delete<IDeleteCompanyResp>(`/api/companies/${params.id}`);
	return data;
};
