import { NavLink } from 'react-router-dom';
import type { ILinkIconProps } from './LinkIcon.interfaces';

import styles from './LinkIcon.module.sass';

export const LinkIcon = ({ className = '', to, icon }: ILinkIconProps) => {
	return (
		<NavLink
			className={({ isActive }) => `${styles['link']} ${className} ${isActive ? styles['active'] : ''}`}
			to={to}
		>
			{icon}
		</NavLink>
	);
};
