import { FormLabel, Select } from '@atoms';
import { IFormInputsProps } from '@interfaces';
import { useState } from 'react';

type TOption = {
	id: string;
	value: string;
};

interface IFormSelectProps extends Omit<IFormInputsProps, 'pattern' | 'placeholder' | 'value' | 'onChange'> {
	options: TOption[];
	selectedOptionId: TOption['id'];
	includeEmptyOption?: boolean;
	onChange?: (selectedOptionId: string) => void;
	onBlur?: () => void;
}

export const FormSelect = ({
	className,
	required,
	options,
	label,
	selectedOptionId,
	onError,
	errorMessage,
	onChange,
	onBlur,
	disabled,
	includeEmptyOption,
}: IFormSelectProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	function handleCloseSelect() {
		setIsOpen(false);
		onBlur && onBlur();
	}

	return (
		<fieldset className={`flex flex-col gap-3 ${className}`}>
			<FormLabel
				onError={onError}
				required={required}
				disabled={disabled}
				onClick={() => !disabled && setIsOpen(true)}
				label={label}
			/>
			<Select
				className={`${onError ? 'ring-[3px] ring-error-300' : ''}`}
				disabled={disabled}
				isOpen={isOpen}
				options={options}
				selectedOptionId={selectedOptionId}
				onChange={onChange}
				onClose={handleCloseSelect}
				includeEmptyOption={includeEmptyOption}
			/>
			{onError ? <span className='font-normal text-xs text-error-500'>{errorMessage}</span> : null}
		</fieldset>
	);
};
