import { apiPrivate } from '../config/apiPrivate';

interface ICreateUserParams {
	dni: string;
	firstName: string;
	lastName: string;
	age: number;
	phoneNumber: string;
	email: string;
	twoFactorEnabled: boolean;
	role?: string; // id
	company: string; // id
	city: string;
	file: File;
}

interface ICreateUserResp {
	id: string;
	dni: string;
	firstName: string;
	lastName: string;
	age: number;
	phoneNumber: string;
	email: string;
	twoFactorEnabled: boolean;
	city: {
		id: string;
		// createdAt: string;
		// updatedAt: string;
		// deleteAt: string;
		// cityName: string;
		// cityCode: string;
		// coordinates: {
		// 	latitude: string;
		// 	longitude: string;
		// };
	};
	// resetPasswordToken: null;
	// lastSession: null;
	// createdAt: '2024-05-13T22:32:01.277Z';
	// updatedAt: '2024-05-13T22:32:01.277Z';
	// deleteAt: string;
	// isActive: boolean;
	// hasChangePassword: boolean;
}

export const createUser = async (params: ICreateUserParams): Promise<ICreateUserResp> => {
	const formData = new FormData();
	for (const [key, value] of Object.entries(params)) {
		formData.append(key, value);
	}
	const { data } = await apiPrivate.post<ICreateUserResp>('/api/users', params, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return data;
};
