import { type ForwardedRef, forwardRef } from 'react';
import { IcCheck } from '../Icons/IcCheck';
import { IInputCheckboxProps } from './InputCheckbox.interfaces';
import styles from './InputCheckbox.module.sass';

const InputCheckbox = (
	{
		className = '',
		checked = false,
		onChange = () => {},
		onError: hasError,
		errorMessage,
		...rest
	}: IInputCheckboxProps,
	ref: ForwardedRef<HTMLInputElement>
) => {
	return (
		<div className={styles['container']}>
			<input
				onChange={onChange}
				checked={checked}
				className={`${styles['container__checkbox']} ${className}`}
				type='checkbox'
				ref={ref}
				{...rest}
			/>
			{checked && <IcCheck className={styles['container__check-icon']} />}
		</div>
	);
};

const ForwardedCheckbox = forwardRef<HTMLInputElement, IInputCheckboxProps>(InputCheckbox);

export { ForwardedCheckbox as InputCheckbox };
