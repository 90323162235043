import { useNavigate, useParams } from 'react-router-dom';
import { ButtonGoBack, Card, IcEye } from '@atoms';
import { createColumnHelper } from '@tanstack/react-table';
import { IDispatchDetails } from '@interfaces';
import { findBulkDispatchContent, findKitDispatchContent, QUERY_KEYS } from '@api';
import { useDispatchDetails, useTableQuery } from '@hooks';
import { BaseTable } from '@organisms';

const columnHelper = createColumnHelper<IDispatchDetails>();
const dispatchDetailsColumns = [
	columnHelper.accessor('code', {
		header: 'Box ID',
		cell: columnDefBase => columnDefBase.getValue().padStart(6, '0'),
	}),
	columnHelper.accessor('kit', {
		header: 'Kit',
	}),
	columnHelper.accessor('kitUsage', {
		header: 'Uso del Kit',
	}),
	columnHelper.accessor('creationDate', {
		header: 'Fecha de creación',
	}),
	columnHelper.accessor('createdBy', {
		header: 'Creado por',
	}),
	columnHelper.accessor('quantity', {
		header: 'Cantidad',
	}),
];

const KitDispatchContentTable = ({ dispatchId }: { dispatchId: string }) => {
	const navigator = useNavigate();

	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.dispatches, 'kits', dispatchId],
		fetcherFn: params => findKitDispatchContent({ ...params, dispatchId }),
		tableColumns: [
			...dispatchDetailsColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { id } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcEye
								onClick={() => navigator(`/kits/boxes/${id}`)}
								className='stroke-2 w-[22px] stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	return <BaseTable tableQueryResult={tableQueryResult} />;
};

const BulkDispatchContentTable = ({ dispatchId }: { dispatchId: string }) => {
	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.dispatches, 'bulk', dispatchId],
		fetcherFn: params => findBulkDispatchContent({ ...params, dispatchId }),
		tableColumns: [
			{
				accessorKey: 'id',
				header: 'Código',
				cell: ({ row }) => row.original.id.slice(-6),
			},
			{
				accessorKey: 'productType',
				header: 'Tipo de Producto',
			},
			{
				accessorKey: 'valueCode',
				header: 'Lote',
			},
			{
				accessorKey: 'distinctive',
				header: 'Distintivo',
			},
			{
				accessorKey: 'color',
				header: 'Color',
			},
		],
	});

	return <BaseTable tableQueryResult={tableQueryResult} />;
};

const DispatchContentPage = () => {
	const navigator = useNavigate();
	const { id: dispatchId = '', dispatchType = '' } = useParams();
	console.log(dispatchType);
	const { data, isLoading, isError } = useDispatchDetails(dispatchId, dispatchType as 'bulk' | 'kits');

	return (
		<>
			<ButtonGoBack onClick={() => navigator(-1)} />

			<div>
				<h2 className='text-xl font-bold text-bummock-ocean_blue mb-4'>Despacho Creado</h2>
				<p>Información detallada del despacho previamente creado.</p>
			</div>

			{isLoading && 'Loading...'}
			{!isLoading && !isError && (
				<div className='grid grid-cols-5 gap-8'>
					<Card title='Despacho ID' value={data.code} />
					<Card title='Tipo de Despacho' value={dispatchType === 'bulk' ? 'Granel' : 'Kits'} />
					<Card title='Fecha de despacho' value={data.createdAt} />
					<Card title='Despachado por' value={data.createdBy} />
				</div>
			)}

			<hr />

			<h2 className='text-xl font-bold text-bummock-ocean_blue'>Resumen de Despacho</h2>
			{dispatchType === 'kits' ? (
				<KitDispatchContentTable dispatchId={dispatchId} />
			) : (
				<BulkDispatchContentTable dispatchId={dispatchId} />
			)}
		</>
	);
};

export default DispatchContentPage;
