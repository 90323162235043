import { deleteCompany } from '@api';
import { FormConfirmedDeletion } from '@molecules';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

export const FormDeleteCompany = () => {
	const navigator = useNavigate();
	const { id: companyId } = useParams();

	const mutation = useMutation({
		mutationFn: deleteCompany,
		onSuccess: () => navigator('/companies'),
	});

	function handleDelete() {
		mutation.mutate({ id: companyId! });
	}

	return (
		<FormConfirmedDeletion
			label='Confirmar eliminación de empresa'
			alertMessage='Una vez que elimines la empresa, no hay vuelta atrás. Por favor, asegúrate.'
			buttonLabel='Borrar Empresa'
			onDelete={handleDelete}
		/>
	);
};
