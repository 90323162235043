import { useEffect, useRef } from 'react';

interface useDetectClickOutsideArgs {
	isOpen: boolean;
	onClickOutside: () => void;
}

export const useDetectClickOutside = <T extends HTMLElement = HTMLDivElement>({
	isOpen,
	onClickOutside = () => {},
}: useDetectClickOutsideArgs) => {
	const elementRef = useRef<T>(null);

	useEffect(() => {
		function handleClickBody(e: MouseEvent) {
			if (!isOpen || !elementRef.current) return;
			const clickedElement = e.target as HTMLElement;
			const clickedInside = elementRef.current.contains(clickedElement);
			if (clickedInside) return;
			onClickOutside();
		}
		document.body.addEventListener('click', handleClickBody);
		return () => document.body.removeEventListener('click', handleClickBody);
	}, [isOpen]);

	return { elementRef };
};
