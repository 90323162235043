import { apiPrivate } from '../config/apiPrivate';

interface IGetOneKitTypeParams {
	id: string;
}

export interface IKitTypeSecurityItem {
	id: string;
	name: string;
	description: string;
	kitUsage: {
		id: string;
		name: string;
	};
	kitTypeDetail: {
		id: string;
		quantity: number;
		productType: {
			id: string;
			name: string;
		};
		package_container: boolean;
	}[];
}

export const getOneKitType = async (params: IGetOneKitTypeParams): Promise<IKitTypeSecurityItem> => {
	const { data } = await apiPrivate.get<IKitTypeSecurityItem>(`/api/kits/types/${params.id}`);
	return data;
};
