import { ICompany } from '@interfaces';
import { getPaginatedResource, IPaginatedResourceResp, IPaginatedResourceParams } from '@api';

interface ICompanyEntityResponse {
	id: string;
	name: string;
	identificationNumber: string;
	email: string;
	yardCount: number;
	farmCount: number;
	shippingLineCount: number;
	companyCount: number;
	numberOfCreatedUsers: number;
	createdAt: string;
	updatedAt: string;
	deleteAt: string;
	isActive: boolean;
}

export const findAllCompanies = async (params: IPaginatedResourceParams): Promise<IPaginatedResourceResp<ICompany>> => {
	const { data } = await getPaginatedResource<ICompanyEntityResponse>('/api/companies', params);

	const companies: ICompany[] =
		data?.data.map(({ id, email, name, identificationNumber, isActive }) => ({
			id,
			name,
			ruc: identificationNumber,
			adminEmail: email,
			assignedLocations: 1,
			assignedWorkflows: 1,
			users: 1,
			lastAccess: '-',
			status: isActive ? 'Activo' : 'Desactivado',
		})) || [];

	return {
		...data,
		data: companies,
	};
};
