import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import Map, { NavigationControl, type MapRef } from 'react-map-gl';

import { mapboxAccessToken } from '@utils';
import { IViewState } from '@interfaces';

interface IMapBase {
	viewState?: IViewState;
	children?: ReactNode;
	style?: CSSProperties;
	isVisible?: boolean;
}

const MAPS_VARIANTS = {
	streets: 'streets-v12',
	satellite: 'satellite-streets-v12',
} as const;

export const MapBase = ({ isVisible = false, viewState, style, children }: IMapBase) => {
	// This is typed with the literal types assigned to the MAPS_VARIANTS constant
	const [variant, setVariant] = useState<(typeof MAPS_VARIANTS)['satellite' | 'streets']>('streets-v12');

	const mapRef = useRef<MapRef>(null);

	useEffect(() => {
		if (!mapRef.current) return;
		if (!viewState) return;

		mapRef.current.easeTo({
			center: [viewState.lng, viewState.lat],
			animate: true,
			duration: 1000, // one second
			...(viewState.zoom
				? {
						zoom: viewState.zoom,
					}
				: {}),
		});

		mapRef.current.resize();
	}, [viewState]);

	useEffect(() => {
		if (!mapRef.current || !viewState || !isVisible) return;
		mapRef.current.resize();
	}, [isVisible]);

	return (
		<Map
			maxBounds={[
				[-83.0, -6.0],
				[-67.0, 3.0],
			]}
			minZoom={0}
			ref={mapRef}
			fadeDuration={10}
			initialViewState={{
				longitude: -78.1834,
				latitude: -1.8312,
				zoom: 6,
			}}
			dragRotate={false}
			logoPosition='top-left'
			style={{ borderRadius: '0.5rem', ...style }}
			mapStyle={`mapbox://styles/mapbox/${variant}`}
			mapboxAccessToken={mapboxAccessToken}
			onRender={event => event.target.resize()}
		>
			<NavigationControl showCompass={false} position='top-right' />
			<div className='absolute z-10 bottom-3 left-3 flex bg-white rounded-[4px] shadow-[0_0_0_2px_rgba(0,0,0,0.1)]'>
				<button
					onClick={() => setVariant(MAPS_VARIANTS.streets)}
					className={`py-1 px-2 border-r hover:bg-black/5 ${variant === 'streets-v12' ? '[text-shadow:_0.0625rem_0_0_rgb(0_0_0_/_60%)] text-black bg-black/[.05]' : 'text-gray-500'}`}
				>
					Map
				</button>
				<button
					onClick={() => setVariant(MAPS_VARIANTS.satellite)}
					className={`py-1 px-2 text-bummock-dark_grey hover:bg-black/5 ${variant === 'satellite-streets-v12' ? '[text-shadow:_0.0625rem_0_0_rgb(0_0_0_/_60%)] text-black bg-black/5' : 'text-gray-500'}`}
				>
					Satellite
				</button>
			</div>
			{children}
		</Map>
	);
};
