import { IIconsProps } from './Icons.interfaces';

export const IcEdit = ({ className = '', onClick }: IIconsProps) => {
	return (
		<svg onClick={onClick} className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 23' fill='none'>
			<path
				d='M10 4H5.8C4.11984 4 3.27976 4 2.63803 4.32698C2.07354 4.6146 1.6146 5.07354 1.32698 5.63803C1 6.27976 1 7.11984 1 8.8V17.2C1 18.8802 1 19.7202 1.32698 20.362C1.6146 20.9265 2.07354 21.3854 2.63803 21.673C3.27976 22 4.11984 22 5.8 22H14.2C15.8802 22 16.7202 22 17.362 21.673C17.9265 21.3854 18.3854 20.9265 18.673 20.362C19 19.7202 19 18.8802 19 17.2V13M6.99997 16H8.67452C9.1637 16 9.40829 16 9.63846 15.9447C9.84254 15.8957 10.0376 15.8149 10.2166 15.7053C10.4184 15.5816 10.5914 15.4086 10.9373 15.0627L20.5 5.5C21.3284 4.67157 21.3284 3.32843 20.5 2.5C19.6716 1.67157 18.3284 1.67157 17.5 2.5L7.93723 12.0627C7.59133 12.4086 7.41838 12.5816 7.29469 12.7834C7.18504 12.9624 7.10423 13.1575 7.05523 13.3615C6.99997 13.5917 6.99997 13.8363 6.99997 14.3255V16Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
