import { useAppSelector, useCompany } from '@hooks';
import { FormDeleteCompany, FormEditCompanyInformation } from '@organisms';
import { useParams } from 'react-router-dom';

const EditInformationPage = () => {
	const { id: companyId } = useParams();
	const { companies } = useAppSelector(state => state.userProfile);
	const { isLoading, isError, data: companyData } = useCompany(companyId || companies[0].id);

	if (isLoading) return <Skeleton />;

	if (isError) return <p className='text-red-500'>Some error ocurred while fetching user data</p>;

	return (
		<>
			<h2 className='text-xl font-bold text-bummock-ocean_blue'>Detalles de Empresa</h2>
			<FormEditCompanyInformation
				editingCompany={{
					name: companyData!.name,
					adminFirstName: companyData!.admins[0]!.firstName!,
					adminLastName: companyData!.admins[0].lastName!,
					adminEmail: companyData!.admins[0].email!,
					adminCity: companyData!.admins[0].city!,
					contactPhoneNumber: companyData!.admins[0].phoneNumber!,
					identificationNumber: companyData!.identificationNumber!,
					file: companyData!.image!,
					email: companyData!.email,
				}}
			/>
			<hr />
			<h2 className='text-xl font-bold text-bummock-ocean_blue'>Borrar Empresa</h2>
			<FormDeleteCompany />
		</>
	);
};

function Skeleton() {
	return (
		<>
			<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
			<div className='rounded-md max-w-md w-full'>
				<div className='animate-pulse flex space-x-4 items-end'>
					<div className='rounded-lg bg-bummock-arctic_blue opacity-50 h-[100px] w-[100px]'></div>
					<div className='flex-1 space-y-6 py-1'>
						<div className='h-2 bg-bummock-arctic_blue opacity-50 rounded'></div>
						<div className='space-y-3'>
							<div className='grid grid-cols-3 gap-4'>
								<div className='h-2 bg-bummock-arctic_blue opacity-50 rounded col-span-2'></div>
								<div className='h-2 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
							</div>
							<div className='h-2 bg-bummock-arctic_blue opacity-50 rounded'></div>
						</div>
					</div>
				</div>
			</div>

			<hr />

			<div className='space-y-8 animate-pulse'>
				<div className='grid grid-cols-4 gap-4'>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				</div>
				<div className='grid grid-cols-4 gap-4'>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				</div>
				<div className='grid grid-cols-4 gap-4'>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				</div>
			</div>
			<div className='space-y-4 animate-pulse'>
				<div className='w-1/5  h-6 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			</div>

			<hr />

			<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
			<div className='space-y-4 animate-pulse'>
				<div className='w-1/3 h-6 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				<div className='w-1/5  h-6 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				<div className='w-1/3 h-6 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			</div>
			<div className='space-y-4 animate-pulse'>
				<div className='w-1/5  h-6 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			</div>
		</>
	);
}

export default EditInformationPage;
