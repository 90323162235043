import { getKitDetails, getOneProcess, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

interface IPicture {
	url: string;
	coords: {
		lat: string;
		lng: string;
	};
	tookBy: string;
}

interface IWorkflow {
	name: string;
	steps: {
		name: string;
		pictures: IPicture[];
	}[];
}

interface IKitContent {
	securityItems: {
		name: string;
		labels: string[];
	}[];
}

interface IProcessDetails {
	id: string;
	container: string;
	client: string;
	creationDate: string;
	doneBy: string;
	status: string;
	kitContent: IKitContent;
	workFlows: IWorkflow[];
}

interface IUseProcessDetailsParams {
	id: string;
}

const getOneProcessDetails = async ({ id }: { id: string }): Promise<IProcessDetails> => {
	const processAsIs = await getOneProcess({ id });

	const process = processAsIs[0];
	console.log(process);

	const workFlows: IWorkflow[] = process.workflows.map(workflow => ({
		name: workflow.titleWorkflow,
		steps: workflow.steps
			.filter(step => step.formType === 'CAMERA')
			.map(cameraStep => {
				// Camera component is composed of one compnent only
				const cameraComponent = cameraStep.component[0];
				const pictures =
					cameraComponent?.response.map(photoResponse => ({
						url: photoResponse.response_answer ?? 'https://placehold.co/300x400',
						coords: {
							lat: photoResponse.response_coordinates.lat + '',
							lng: photoResponse.response_coordinates.long + '',
						},
						tookBy: photoResponse.fullName,
					})) ?? [];

				return {
					name: cameraStep.title,
					pictures: pictures,
				};
			}),
	}));

	const kitId =
		process.workflows
			.find(workflow => workflow.titleWorkflow === 'INSPECCION INTERNA REEFER')
			?.steps.find(step => step.title === 'INGRESO INFORMACIÒN')
			?.component.find(component => component.label === 'KIT DE SEGURIDAD')?.response[0]?.response_answer ?? '';

	const { kitDetail } = await getKitDetails({ kitId });

	return {
		id: process.id,
		client: process.company,
		container: process.reference,
		creationDate: moment.utc(process.date).format('DD/MM/YYYY'),
		// Esto tal vez no es conveniente mostrarlo, ya que el proceso se hace por muchas personas
		doneBy: 'David Gavilanez',
		kitContent: {
			securityItems: kitDetail.map(detail => ({
				name: detail.securityItemType,
				labels: detail.items.map(item => item.value),
			})),
		},
		status: 'Pendiente',
		workFlows,
	};
};

export const useProcessDetails = ({ id }: IUseProcessDetailsParams) => {
	const {
		data: processDetails,
		isLoading,
		isError,
	} = useQuery({
		queryKey: [QUERY_KEYS.processes, 'webview', id],
		queryFn: () => getOneProcessDetails({ id }),
	});

	return {
		processDetails,
		isLoading,
		isError,
	};
};
