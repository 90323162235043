import { Badge, ButtonGoBack, Card, IcEye } from '@atoms';
import { IKit } from '@interfaces';
import { BaseTable, TablePagination, TableSearchbar } from '@organisms';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { findAllKits, QUERY_KEYS } from '@api';
import { useNavigate, useParams } from 'react-router-dom';
import { useBoxDetails, useTableQuery } from '@hooks';
import moment from 'moment';

const columnHelper = createColumnHelper<IKit>();
const kitsColumns: ColumnDef<IKit, string>[] = [
	{
		header: 'Código',
		accessorKey: 'code',
	},
	{
		header: 'Tipo de Kit',
		accessorKey: 'kitType',
	},
	{
		header: 'Uso del Kit',
		accessorKey: 'usage',
	},
	{
		header: 'Fecha de creación',
		accessorKey: 'creationDate',
	},
	{
		header: 'Creado por',
		accessorKey: 'createdBy',
	},
	columnHelper.accessor('status', {
		header: 'Estado',
		cell: columnDefBase => (
			<Badge
				label={columnDefBase.getValue()}
				variant={columnDefBase.getValue() === 'Activo' ? 'success' : 'warning'}
			/>
		),
		meta: { alignment: 'center' },
		enableSorting: false,
	}),
];

const CardsSkeleton = () => {
	return (
		<div className='space-y-8 animate-pulse'>
			<div className='grid grid-cols-5 gap-4'>
				<div className='h-[7rem] bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				<div className='h-[7rem] bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				<div className='h-[7rem] bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				<div className='h-[7rem] bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				<div className='h-[7rem] bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			</div>
		</div>
	);
};

const BoxPage = () => {
	const { id: boxId = '' } = useParams();
	const navigator = useNavigate();

	const { isLoading: isLoadingBoxDetails, isError: isErrorBoxDetails, data: boxDetails } = useBoxDetails(boxId);

	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.kits],
		fetcherFn: params => findAllKits({ ...params, kitId: boxId! }),
		tableColumns: [
			...kitsColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { id } = row.original;
					return (
						<div className='flex justify-end'>
							<IcEye
								onClick={() => navigator(`kit/${id}`)}
								className='stroke-2 w-[22px] stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	return (
		<>
			<ButtonGoBack onClick={() => navigator('../')} />

			<div>
				<h2 className='text-xl font-bold text-bummock-ocean_blue mb-4'>Box Generado</h2>
				<p>Lista de Kits agrupados según la cantidad previamente creada.</p>
			</div>

			{isLoadingBoxDetails && <CardsSkeleton />}

			{!isLoadingBoxDetails && !isErrorBoxDetails && !!boxDetails && (
				<div className='grid grid-cols-4 gap-8'>
					<Card title='Box ID' value={boxDetails.code.toString().padStart(6, '0')} />
					<Card title='Fecha de creación' value={moment.utc(boxDetails.createdAt).format('DD/MM/YYYY')} />
					<Card title='Creado por' value={`${boxDetails.user.firstName} ${boxDetails.user.lastName}`} />
					<Card title='Kits' value={boxDetails.quantity} />
				</div>
			)}

			<TableSearchbar tableQueryResult={tableQueryResult} />
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default BoxPage;
