import type { FC } from 'react';

import type { IButtonSolidProps } from './ButtonSolid.interfaces';

import styles from './ButtonSolid.module.sass';

export const ButtonSolid: FC<IButtonSolidProps> = ({
	className = '',
	label,
	icon,
	disabled = false,
	isLoading = false,
	onClick,
	mRef,
	type,
	onError,
	title,
	iconPlacement = 'left',
}) => {
	if (disabled) {
		return (
			<button
				ref={mRef}
				className={`${styles['button']} ${disabled ? styles['button--disabled'] : ''} ${className}`}
				{...{ type, title, disabled }}
			>
				{iconPlacement === 'left' ? icon : null}
				{label}
				{iconPlacement === 'right' ? icon : null}
			</button>
		);
	}

	if (onError)
		return (
			<button className={`${styles['button']} ${styles['button--error']} ${className}`} {...{ type, title }}>
				{label}
			</button>
		);

	if (isLoading)
		return (
			<button className={`${styles['button']} ${styles['button--loading']}`} {...{ type, title }}>
				IcSpinner
			</button>
		);

	return (
		<button ref={mRef} className={`${styles['button']} ${className}`} {...{ onClick, type, title }}>
			{iconPlacement === 'left' ? icon : null}
			{label}
			{iconPlacement === 'right' ? icon : null}
		</button>
	);
};
