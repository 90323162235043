import { FormLabel, MultiCheckSelect } from '@atoms';
import { IFormInputsProps } from '@interfaces';
import { useState } from 'react';

type TOption = {
	id: string;
	value: string;
};

interface IFormMultiCheckSelectProps extends Omit<IFormInputsProps, 'pattern' | 'placeholder' | 'value' | 'onChange'> {
	options: TOption[];
	selectedOptions: string[];
	onChange?: (selectedOptionIds: string[]) => void;
	onBlur?: () => void;
}

export const FormMultiCheckSelect = ({
	className,
	required,
	options,
	label,
	selectedOptions,
	onError,
	errorMessage,
	onChange,
	onBlur,
	disabled,
}: IFormMultiCheckSelectProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	function handleCloseSelect() {
		setIsOpen(false);
		onBlur && onBlur();
	}

	return (
		<fieldset className={`flex flex-col gap-3 ${className}`}>
			<FormLabel
				onError={onError}
				required={required}
				disabled={disabled}
				onClick={() => !disabled && setIsOpen(true)}
				label={label}
			/>
			<MultiCheckSelect
				label={label}
				className={`${onError ? 'ring-[3px] ring-error-300' : ''}`}
				disabled={disabled}
				isOpen={isOpen}
				options={options}
				selectedOptions={selectedOptions}
				onChange={onChange}
				onClose={handleCloseSelect}
			/>
			{onError ? <span className='font-normal text-xs text-error-500'>{errorMessage}</span> : null}
		</fieldset>
	);
};
