import { apiPrivate } from '../config/apiPrivate';

interface IUnassignRoleParams {
	id: string;
	role: string;
	company: string;
}

interface IUnassignRoleResp {}

export const unassignRole = async ({ id, ...restParams }: IUnassignRoleParams): Promise<IUnassignRoleResp> => {
	const { data } = await apiPrivate.patch<IUnassignRoleResp>(`/api/users/unassign-role-user/${id}`, restParams);
	return data;
};
