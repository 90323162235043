import { IBox } from '@interfaces';
import { IPaginatedResourceParams, getPaginatedResource } from '@api';
import moment from 'moment';

interface IBoxResponseEntity {
	id: string;
	createdAt: string;
	updatedAt: string;
	deleteAt: null;
	code: number;
	quantity: number;
	endAt: string;
	status: 'FULL' | 'IN USE' | 'DISPATCHED';
	user: {
		firstName: string;
		lastName: string;
		image: string;
	};
	kitType: {
		name: string;
		kitUsage: {
			name: string;
		};
	};
}

export const findAllBoxes = async (params: IPaginatedResourceParams) => {
	// This might cause problems as the backend doesn't return an item with this IBox shape
	const { data } = await getPaginatedResource<IBoxResponseEntity>('/api/kits', params);
	const mappedData: IBox[] = data.data.map(({ id, code, createdAt, kitType, quantity, status, user }) => ({
		id: id,
		code: code + '',
		kitType: kitType.name,
		usage: kitType.kitUsage.name,
		creationDate: moment.utc(createdAt).format('DD/MM/YYYY'),
		createdBy: `${user.firstName} ${user.lastName}`,
		quantity: quantity + '',
		status: status === 'FULL' ? 'Terminado' : status === 'IN USE' ? 'En progreso' : 'Despachado',
	}));
	return {
		...data,
		data: mappedData,
	};
};
