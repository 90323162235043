import { apiPrivate } from '../config/apiPrivate';

interface IDeleteSupplierParams {
	id: string;
}

interface IDeleteSupplierResp {}

export const deleteSupplier = async (params: IDeleteSupplierParams): Promise<IDeleteSupplierResp> => {
	const { data } = await apiPrivate.delete<IDeleteSupplierResp>(`/api/suppliers/${params.id}`);
	return data;
};
