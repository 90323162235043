import { findAllInspections, QUERY_KEYS } from '@api';
import { Badge, IcEye, IcReport } from '@atoms';
import { useTableQuery } from '@hooks';
import { IInspection } from '@interfaces';
import { BaseTable, TablePagination, TableSearchbar } from '@organisms';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

const columnHelper = createColumnHelper<IInspection>();
const inspectionsColumns: ColumnDef<IInspection, string>[] = [
	columnHelper.accessor('id', {
		header: 'ID',
		cell: columnDefBase => columnDefBase.getValue().slice(0, 7).toUpperCase(),
	}),
	columnHelper.accessor('container', {
		header: 'Contenedor',
	}),
	// columnHelper.accessor('client', {
	// 	header: 'Cliente',
	// }),
	columnHelper.accessor('inspectionDate', {
		header: 'Fecha de Inspección',
	}),
	// columnHelper.accessor('inspector', {
	// 	header: 'Realizado por',
	// }),
	columnHelper.accessor('status', {
		header: 'Estado',
		cell: columnDefBase => (
			<Badge
				label={columnDefBase.getValue()}
				variant={
					columnDefBase.getValue().toUpperCase() === 'INSPECCIONADO'
						? 'success'
						: columnDefBase.getValue().toUpperCase() === 'EN PROCESO'
							? 'warning'
							: 'danger'
				}
			/>
		),
		meta: { alignment: 'center' },
	}),
];

const InspectionsPage = () => {
	const navigator = useNavigate();
	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.inspections],
		fetcherFn: findAllInspections,
		tableColumns: [
			...inspectionsColumns,
			columnHelper.display({
				header: 'Visualizar',
				cell: ({ row }) => {
					const { id } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcEye
								onClick={() => navigator(`/inspections/${id}/details`)}
								className='stroke-2 w-[22px] stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
							<IcReport
								onClick={() => navigator(`/inspections/${id}/pdf`)}
								className='stroke-2 w-[18px] stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});
	return (
		<>
			<TableSearchbar tableQueryResult={tableQueryResult} />
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default InspectionsPage;
