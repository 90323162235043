import { setUserId } from '@RTK/Slices';
import { verifyResetPasswordToken } from '@api';
import { useAppDispatch } from '@hooks';
import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

const VerifyEmailPage = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [onError, setOnError] = useState<boolean>(false);

	const resetToken = searchParams.get('token') ?? '';
	const userId = searchParams.get('data') ?? '';

	console.log(resetToken, userId);

	useEffect(() => {
		verifyResetPasswordToken({ resetPasswordToken: resetToken, user: userId })
			.then(() => {
				dispatch(setUserId(userId));
				navigate('/new-password');
			})
			.catch(() => setOnError(true));
	}, []);

	if (onError) {
		return <h1>Error. Token incorrecto.</h1>;
	}

	return <h1>Success</h1>;
};

export default VerifyEmailPage;
