import { ITabRoute } from '@interfaces';
import { TabbedLayout } from '@layout';
import {
	EditUserInformationPage,
	EditUserSecurityPage,
	NewCustomRolePage,
	NewUserPage,
	UserLocationsPage,
	UserRolesPage,
	UsersPage,
} from '@pages';
import { RouteObject } from 'react-router-dom';
import { createProtectedTabRoute } from '../../router/helpers';

const usersSubRoutes: ITabRoute[] = [
	createProtectedTabRoute({
		path: 'information',
		tabName: 'Información',
		requiredPermission: 'USERS_INFORMATION',
		element: <EditUserInformationPage />,
	}),
	createProtectedTabRoute({
		path: 'security',
		tabName: 'Seguridad',
		requiredPermission: 'USERS_SECURITY',
		element: <EditUserSecurityPage />,
	}),
	createProtectedTabRoute({
		path: 'roles',
		tabName: 'Roles y Permisos',
		requiredPermission: 'USERS_ROLES',
		children: [
			{
				index: true,
				element: <UserRolesPage />,
			},
			{
				path: 'new',
				element: <NewCustomRolePage />,
			},
		],
	}),
	createProtectedTabRoute({
		path: 'locations',
		tabName: 'Localidades',
		requiredPermission: 'USERS_LOCATIONS',
		element: <UserLocationsPage />,
	}),
];

export const usersRoutes: RouteObject[] = [
	{
		path: 'users',
		element: <UsersPage />,
	},
	{
		path: 'users/new',
		element: <NewUserPage />,
	},
	{
		path: 'users/:id',
		element: <TabbedLayout tabRoutes={usersSubRoutes} />,
		children: usersSubRoutes,
	},
];
