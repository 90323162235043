import { apiPrivate } from '../config/apiPrivate';

interface IUpdateStockParams {
	productId: string;
	supplier: string;
	quantity: number;
	distinctive: string;
	color: string;
	startCode: string;
	endCode: string;
	price: string;
}

interface IUpdateStockResp {}

export const updateStock = async ({ productId, ...restParams }: IUpdateStockParams): Promise<IUpdateStockResp> => {
	const { data } = await apiPrivate.patch<IUpdateStockResp>(`/api/products/${productId}/update-stock`, restParams);
	return data;
};
