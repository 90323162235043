import { IcPistolScan, Button } from '@atoms';
import { FormInput } from '@molecules';
import { useForm } from 'react-hook-form';
import { verifySecurityItem } from '@api';
import { IFormNewKitValues } from '@interfaces';
import { useEffect } from 'react';

interface IFormNewKitProps {
	currentKit: number;
	isSaving?: boolean;
	onSubmit?: (formValues: IFormNewKitValues) => void;
	onCancel?: () => void;
	className?: string;
	kitStructure: {
		packaging: {
			productType: {
				id: string;
				name: string;
			};
		};
		securityItems: {
			productType: {
				id: string;
				name: string;
			};
			items: { value: string }[];
		}[];
	};
}

// TODO: Consumir endpoint de validación cuando onBlur
export const FormNewKit = ({
	className = '',
	isSaving = false,
	currentKit,
	onSubmit = () => {},
	onCancel = () => {},
	kitStructure,
}: IFormNewKitProps) => {
	const {
		register,
		formState: { errors, isValid },
		getValues,
		handleSubmit: handleSubmitWrapper,
		setValue,
		setFocus,
		setError,
		clearErrors,
		reset,
	} = useForm<IFormNewKitValues>({
		defaultValues: {
			packaging: kitStructure.packaging,
			securityItems: kitStructure.securityItems,
		},
	});

	useEffect(() => {
		reset();
		setFocus('packaging');
	}, [currentKit]);

	const handleSubmit = (formValues: IFormNewKitValues) => {
		onSubmit(formValues);
	};

	// This should be part of a different <Input> component. Maybe a <InputScan /> component.
	const handleEnter = (productTypeId: string, itemInputValue: string) => {
		return verifySecurityItem({
			typeId: productTypeId,
			itemSerial: itemInputValue,
		});
	};

	const allValid = getValues('securityItems')
		.flatMap(productType => productType.items)
		.every(item => (item.backendValue ? item.backendValue.length : 0 > 0));

	return (
		<form className={className} autoComplete='off' onSubmit={handleSubmitWrapper(handleSubmit)}>
			{/* Packaging inputs */}
			<div className='flex flex-col gap-4'>
				<p>
					Crea el kit completando los campos de texto con los productos físicos según su tipo. Te recomendamos
					presionar Enter para avanzar y escanear los productos para agilizar el proceso.
				</p>
				<div className='grid grid-cols-3 gap-4'>
					<FormInput
						onError={!!errors.packaging?.message}
						errorMessage={errors.packaging?.message}
						onKeyDown={async e => {
							const pressedEnter = e.key === 'Enter';
							if (!pressedEnter) return;
							e.preventDefault();

							const productTypeId = getValues('packaging.productType.id');
							const itemInputValue = getValues('packaging.value');

							try {
								const { id: backendSecurityItemId } = await handleEnter(productTypeId, itemInputValue);

								setValue('packaging.backendValue', backendSecurityItemId);
								setFocus(`securityItems.${0}.items.${0}.value`);
								clearErrors('packaging');
							} catch (error: unknown) {
								setError('packaging', {
									message: 'Invalid Item',
								});
							}
						}}
						onBlur={async () => {
							const productTypeId = getValues('packaging.productType.id');
							const itemInputValue = getValues('packaging.value');

							if (!itemInputValue) return;

							try {
								const { id: backendSecurityItemId } = await handleEnter(productTypeId, itemInputValue);
								setValue('packaging.backendValue', backendSecurityItemId);
								setFocus(`securityItems.${0}.items.${0}.value`);
								clearErrors('packaging');
							} catch (error: unknown) {
								setError('packaging', {
									message: 'Invalid Item',
								});
							}
						}}
						register={register('packaging.value', { required: true })}
						label='Packaging'
						placeholder='000000'
						iconPlacement='right'
						icon={<IcPistolScan className='stroke-2 w-[17px] stroke-bummock-disabled_grey_2' />}
					/>
				</div>
			</div>

			<hr />

			{getValues('securityItems').map((securityItem, productTypeIndex) => {
				return (
					<div key={securityItem.productType.id}>
						<h2 className='text-bummock-ocean_blue text-xl font-bold'>{securityItem.productType.name}</h2>
						<div className='grid grid-cols-3 gap-4'>
							{securityItem.items.map((_, itemIndex) => (
								<FormInput
									onError={
										!!(errors.securityItems
											? errors.securityItems[productTypeIndex]?.items![itemIndex]?.message
											: '')
									}
									errorMessage={
										errors.securityItems
											? errors.securityItems[productTypeIndex]?.items![itemIndex]?.message
											: ''
									}
									onKeyDown={async e => {
										const pressedEnter = e.key === 'Enter';
										if (!pressedEnter) return;
										e.preventDefault();

										const productTypeId = getValues(
											`securityItems.${productTypeIndex}.productType.id`
										);
										const itemInputValue = getValues(
											`securityItems.${productTypeIndex}.items.${itemIndex}.value`
										);

										// Checks if duplicate
										const itemAlreadyAdded = !!getValues(`securityItems.${productTypeIndex}.items`)
											.filter((_, index) => index !== itemIndex)
											.find(item => item.value === itemInputValue);
										if (itemAlreadyAdded) {
											setError(`securityItems.${productTypeIndex}.items.${itemIndex}`, {
												message: 'Item already added',
											});
											return;
										}

										try {
											const { id: backendSecurityItemId } = await handleEnter(
												productTypeId,
												itemInputValue
											);
											setValue(
												`securityItems.${productTypeIndex}.items.${itemIndex}.backendValue`,
												backendSecurityItemId
											);

											// This is gonna be slightly more complex as we have two indexes
											clearErrors(`securityItems.${productTypeIndex}.items.${itemIndex}`);

											// Goes to the next input only if there was no error
											const isLastOfPanel =
												getValues(`securityItems.${productTypeIndex}.items`).length ===
												itemIndex + 1;
											if (isLastOfPanel) {
												setFocus(`securityItems.${productTypeIndex + 1}.items.${0}.value`);
											} else {
												setFocus(
													`securityItems.${productTypeIndex}.items.${itemIndex + 1}.value`
												);
											}
										} catch (error: unknown) {
											setError(`securityItems.${productTypeIndex}.items.${itemIndex}`, {
												message: 'Invalid Item',
											});
										}
									}}
									onBlur={async () => {
										const productTypeId = getValues(
											`securityItems.${productTypeIndex}.productType.id`
										);
										const itemInputValue = getValues(
											`securityItems.${productTypeIndex}.items.${itemIndex}.value`
										);

										if (!itemInputValue) return;

										// Checks if duplicate
										const itemAlreadyAdded = !!getValues(`securityItems.${productTypeIndex}.items`)
											.filter((_, index) => index !== itemIndex)
											.find(item => item.value === itemInputValue);
										if (itemAlreadyAdded) {
											setError(`securityItems.${productTypeIndex}.items.${itemIndex}`, {
												message: 'Item already added',
											});
											return;
										}

										try {
											const { id: backendSecurityItemId } = await handleEnter(
												productTypeId,
												itemInputValue
											);
											setValue(
												`securityItems.${productTypeIndex}.items.${itemIndex}.backendValue`,
												backendSecurityItemId
											);

											// This is gonna be slightly more complex as we have two indexes

											clearErrors(`securityItems.${productTypeIndex}.items.${itemIndex}`);
										} catch (error: unknown) {
											setError(`securityItems.${productTypeIndex}.items.${itemIndex}`, {
												message: 'Invalid Item',
											});
										}
									}}
									register={register(
										`securityItems.${productTypeIndex}.items.${itemIndex}.value` as const,
										{
											required: true,
										}
									)}
									key={itemIndex}
									placeholder='000000'
									iconPlacement='right'
									icon={<IcPistolScan className='stroke-2 w-[17px] stroke-bummock-disabled_grey_2' />}
								/>
							))}
						</div>
					</div>
				);
			})}

			<div className='flex gap-4'>
				<Button onClick={onCancel} variant='secondary' className='px-10' type='button'>
					Cancelar
				</Button>
				<Button isLoading={isSaving} type='submit' disabled={!isValid || !allValid} className='px-10'>
					Guardar Kit
				</Button>
			</div>
		</form>
	);
};
