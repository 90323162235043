import { apiPrivate } from '../config/apiPrivate';

interface IAddProductParams {
	name: string;
	description: string;
	productTypeId: string;
	categoryId: string;
}

interface IAddProductResp {}

export const addProduct = async (params: IAddProductParams): Promise<IAddProductResp> => {
	const { data } = await apiPrivate.post<IAddProductResp>('/api/products', params);
	return data;
};
