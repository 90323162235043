import { apiPrivate } from '../config/apiPrivate';

interface IGetOneUserParams {
	userId: string;
}

interface IGetOneUserResp {
	id: string;
	dni: string;
	firstName: string;
	lastName: string;
	fullName: string;
	phoneNumber: string;
	email: string;
	image?: string; // This shouldn't be optional
	has2fa: boolean;
	city: {
		id: string;
		name: string;
	};
	companies: {
		id: string;
		name: string;
	}[];
	role: {
		id: string;
		name: string;
	};
	permissions: string[];
}

export const getOneUser = async (params: IGetOneUserParams): Promise<IGetOneUserResp> => {
	const { data } = await apiPrivate.get<IGetOneUserResp>(`/api/users/${params.userId}`);
	return data;
};
