import { apiPrivate } from '../config/apiPrivate';

interface ICreateSupplierParams {
	name: string;
	identification: string;
}

interface ICreateSupplierResp {}

export const createSupplier = async (params: ICreateSupplierParams): Promise<ICreateSupplierResp> => {
	const { data } = await apiPrivate.post<ICreateSupplierResp>('/api/suppliers', params);
	return data;
};
