import { deleteSupplier, findAllSuppliers, QUERY_KEYS } from '@api';
import { Button, IcPlus, IcEdit, IcTrash } from '@atoms';
import { useAppSelector, useConfirmation, useTableQuery } from '@hooks';
import { ISupplier } from '@interfaces';
import {
	BaseTable,
	ModalConfirmation,
	ModalEditSupplier,
	ModalNewSupplier,
	TablePagination,
	TableSearchbar,
} from '@organisms';
import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { canAccess } from '@utils';
import { PermissionWrapper } from '@wrappers';
import { useState } from 'react';

const columnHelper = createColumnHelper<ISupplier>();
const suppliersColumns = [
	columnHelper.accessor('name', {
		header: 'Nombre de Proveedor',
	}),
	columnHelper.accessor('productCount', {
		header: 'Productos Asignados',
	}),
];

const INITIAL_MODAL_STATE = {
	isModalVisible: false,
	editingItemId: '',
};
// Define two higher order components for the edit & delete button
const SuppliersPage = () => {
	const [{ isModalVisible, editingItemId }, setModalState] = useState(INITIAL_MODAL_STATE);

	const queryClient = useQueryClient();
	const { confirm, confirmationModalProps } = useConfirmation({
		confirmationMessage: '¿Estás seguro de eliminar el proveedor?',
		mutationFn: deleteSupplier,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.suppliers] });
		},
	});

	const permissions = useAppSelector(state => state.userProfile.permissions);
	const shouldShowActions = canAccess({ module: 'INVENTORY_SUPPLIERS', minimumPermission: 'EDIT', permissions });

	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.suppliers],
		fetcherFn: findAllSuppliers,
		tableColumns: [
			...suppliersColumns,
			...(shouldShowActions
				? [
						columnHelper.display({
							id: 'actions',
							header: 'Acciones',
							cell: ({ row }) => {
								const { id } = row.original;
								return (
									<div className='flex justify-end gap-4'>
										<PermissionWrapper module='INVENTORY_SUPPLIERS' minimumPermission='EDIT'>
											<IcEdit
												onClick={() =>
													setModalState(prev => ({
														...prev,
														isModalVisible: true,
														editingItemId: id,
													}))
												}
												className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
											/>
										</PermissionWrapper>
										<PermissionWrapper module='INVENTORY_SUPPLIERS' minimumPermission='DELETE'>
											<IcTrash
												onClick={() =>
													confirm({
														id,
													})
												}
												className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
											/>
										</PermissionWrapper>
									</div>
								);
							},
							meta: { alignment: 'right' },
						}),
					]
				: []),
		],
	});

	/*
	useEffect(() => {
		const rowsFromCurrentPage = tableQueryResult.table.getCoreRowModel().rows;
		if (!rowsFromCurrentPage.length) return;

		const columnIds = tableQueryResult.table.getAllColumns().map(column => column.id);
		console.log(columnIds);

		const columnVisibility = columnIds.reduce(
			(prev, curr) => ({ ...prev, [curr]: false }),
			{} as Record<string, boolean>
		);
		console.log(columnVisibility);
		for (const row of rowsFromCurrentPage) {
			for (const columnId of columnIds) {
				// Si tiene valor para esta column
				if (row.getValue(columnId) !== undefined) {
					columnVisibility[columnId] = true;
				}
			}
		}
		console.log(columnVisibility);

		// console.log(rowsFromCurrentPage[0].getValue('name'));
		// console.log(rowsFromCurrentPage[1].getValue('name'));

		console.log(rowsFromCurrentPage);
		tableQueryResult.table.setState(prev => ({
			...prev,
			columnVisibility,
		}));
	}, [tableQueryResult.table.getCoreRowModel().rows.length]);
    */

	return (
		<>
			<ModalConfirmation {...confirmationModalProps} />

			<ModalNewSupplier
				isVisible={isModalVisible && !editingItemId}
				onCloseModal={() =>
					setModalState(prev => ({
						...prev,
						isModalVisible: false,
					}))
				}
			/>
			<ModalEditSupplier
				editingItemId={editingItemId}
				isVisible={isModalVisible && !!editingItemId}
				onCloseModal={() =>
					setModalState(prev => ({
						...prev,
						isModalVisible: false,
						editingItemId: '',
					}))
				}
			/>

			<div className='flex justify-between'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<PermissionWrapper module='INVENTORY_SUPPLIERS' minimumPermission='CREATE'>
					<Button
						onClick={() =>
							setModalState({
								...INITIAL_MODAL_STATE,
								isModalVisible: true,
							})
						}
					>
						<IcPlus className='stroke-2 stroke-bummock-off_white w-5' />
						Crear Proveedor
					</Button>
				</PermissionWrapper>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default SuppliersPage;
