import { apiPrivate } from '../config/apiPrivate';

interface IUpdateKitTypeParams {
	id: string;

	name: string;
	description: string;
	// isActive: boolean; // Esto no va a servir
	// requiresPackaging: boolean;//  Esto no va a servir
	kitUsage: string;
	itemsType: {
		id?: string; // Opcional porque al omitir el ID se agrega
		productType: string;
		quantity: number;
		package_container: boolean;
		isActive: boolean; // Se utiliza para eliminar items
	}[];
}

interface IUpdateKitTypeResp {}

export const updateKitType = async ({ id, ...restParams }: IUpdateKitTypeParams): Promise<IUpdateKitTypeResp> => {
	const { data } = await apiPrivate.patch<IUpdateKitTypeResp>(`/api/kits/types/${id}`, restParams);
	return data;
};
