import { v4 } from 'uuid';

import { FormLabel } from '@atoms';

import styles from './FormInputPhone.module.sass';
import { IFormInputsProps } from '@interfaces';

export const FormInputPhone = ({
	className = '',
	disabled,
	errorMessage,
	label,
	name,
	onChange,
	onError,
	pattern,
	placeholder,
	register,
	required,
	value,
}: IFormInputsProps) => {
	const id = v4();

	return (
		<fieldset className={`${styles['fieldset']} ${className}`}>
			<FormLabel {...{ disabled, id, label, onError, required }} />
			<div className='flex gap-2'>
				<input
					className={styles['fieldset__prefix']}
					type='text'
					aria-disabled={true}
					aria-required={required}
					data-has-error={onError}
					value='+593'
					readOnly={true}
					disabled={true}
				/>
				<input
					className={styles['fieldset__number']}
					type='tel'
					aria-required={required}
					data-has-error={onError}
					{...{ disabled, id, name, onChange, pattern, placeholder, value }}
					{...register}
				/>
			</div>
			{onError ? <span className={styles['fieldset--error__message']}>{errorMessage}</span> : null}
		</fieldset>
	);
};
