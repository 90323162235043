import { View, ViewProps } from '@react-pdf/renderer';
import { ReactNode } from 'react';

export const Container = ({ children, style = {}, ...restProps }: ViewProps & { children?: ReactNode }) => (
	<View
		style={{
			padding: '8px 16px',
			border: '1px solid #eee',
			borderRadius: '4px',
			overflow: 'hidden',
			...style,
		}}
		{...restProps}
	>
		{children}
	</View>
);
