import { useAppSelector } from '@hooks';
import { MODULES } from '@utils';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface IProtectedRouteProps {
	children: ReactNode;
	requiredPermission: keyof typeof MODULES;
}

export const ProtectedRoute = ({ children, requiredPermission }: IProtectedRouteProps) => {
	const userRoles = useAppSelector(state => state.userProfile.permissions);

	const hasAccess = userRoles.some(permission => {
		const [, module] = permission.split(':');
		return module === requiredPermission;
	});

	if (hasAccess) {
		return children;
	} else {
		return <Navigate to='/unauthorized' replace />;
	}
};
