import { ITabRoute } from '@interfaces';
import { MODULES } from '@utils';
import { ProtectedRoute } from '@wrappers';
import { ReactElement } from 'react';
import { RouteObject } from 'react-router-dom';

interface ICreateProtectedTabRouteParams {
	path: string;
	tabName: string;
	requiredPermission: keyof typeof MODULES;
	element?: ReactElement;
	children?: RouteObject[];
}

export const createProtectedTabRoute = ({
	path,
	tabName,
	requiredPermission,
	element,
	children,
}: ICreateProtectedTabRouteParams): ITabRoute => {
	if (children) {
		return {
			path,
			handle: { tabName, requiredPermission },
			children: children.map(child => ({
				...child,
				element: <ProtectedRoute requiredPermission={requiredPermission}>{child.element}</ProtectedRoute>,
			})),
		};
	}

	return {
		path,
		handle: { tabName, requiredPermission },
		element: <ProtectedRoute requiredPermission={requiredPermission}>{element}</ProtectedRoute>,
	};
};
