import { ChangeEventHandler } from 'react';
import styles from './Switch.module.sass';
import { RefCallBack } from 'react-hook-form';

type TVariant = 'primary' | 'secondary';

interface IFormSwitchProps {
	id: string;
	className?: string;
	variant?: TVariant;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	mRef?: RefCallBack;
	name: string;
	defaultValue?: boolean;
	disabled?: boolean;
}

export const Switch = ({
	id,
	name,
	className = '',
	variant = 'primary',
	onChange,
	mRef,
	disabled,
}: IFormSwitchProps) => {
	return (
		<div
			className={`${styles['switch']} ${className} ${disabled ? '!bg-bummock-disabled_grey !shadow-bummock-disabled_grey_2' : ''} relative`}
			data-variant={variant}
		>
			<input
				id={id}
				name={name}
				ref={mRef}
				aria-hidden={true}
				className={`absolute left-0 w-full h-full opacity-0 z-10 ${disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'}`}
				type='checkbox'
				onChange={onChange}
			/>
			<div className={styles['switch__control']}></div>
		</div>
	);
};
