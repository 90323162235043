import { IIconsProps } from './Icons.interfaces';

export const IcSwitchVertical = ({ className }: IIconsProps) => (
	<svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 14' fill='none'>
		<path
			d='M4.25 1V13M4.25 13L1.25 10M4.25 13L7.25 10M11.75 13V1M11.75 1L8.75 4M11.75 1L14.75 4'
			stroke='#092D45'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
