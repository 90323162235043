import { apiPrivate } from '../config/apiPrivate';

interface IAssignLocationParams {
	userId: string;
	locationsIds: string[];
}

interface IAssignLocationResp {}

export const assignLocationToUser = async (params: IAssignLocationParams): Promise<IAssignLocationResp> => {
	const { data } = await apiPrivate.post<IAssignLocationResp>(`/api/users/assign-location`, {
		locationIds: params.locationsIds,
		user: params.userId,
	});
	return data;
};
