import { Modal } from '../Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppSelector } from '@hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createKitType } from '@api';
import { IModalProps } from '@interfaces';
import { FormKitType } from '@organisms';

interface IFormNewKitTypeValues {
	name: string;
	usage: string;
	usageDescription: string;
	requiresPackaging: boolean;
	packagingTypeId: string;
	items: {
		typeId: string;
		quantity: string;
	}[];
}

const INITIAL_LENGTH = 1;

interface ICreateFormProps {
	onCancel: () => void;
}

const CreateForm = ({ onCancel }: ICreateFormProps) => {
	const { companies } = useAppSelector(state => state.userProfile);

	const form = useForm<IFormNewKitTypeValues>({
		defaultValues: {
			name: '',
			usage: '',
			usageDescription: '',
			requiresPackaging: true,
			items: Array(INITIAL_LENGTH).fill({
				typeId: '',
				quantity: '',
			}),
		},
	});
	const { handleSubmit: handleSubmitWrapper, reset } = form;

	const queryClient = useQueryClient();
	const { isPending, mutate } = useMutation({
		mutationFn: createKitType,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['kits-types'] });
			onCancel();
			setTimeout(() => reset(), 400);
		},
	});

	const handleSubmit = ({ name, usage, items, usageDescription, packagingTypeId }: IFormNewKitTypeValues) => {
		mutate({
			name,
			description: usageDescription,
			kitUsage: usage,
			company: companies[0].id,
			itemsType: [
				...items.map(({ typeId, quantity }) => ({
					productType: typeId,
					quantity: +quantity,
					package_container: false,
				})),
				...(packagingTypeId
					? [
							{
								productType: packagingTypeId,
								quantity: 1,
								package_container: true,
							},
						]
					: []),
			],
		});
	};

	const handleCancel = () => {
		onCancel();
		// TODO: This should be atteched to a onFaded from the modal
		setTimeout(() => reset(), 200);
	};

	return (
		<FormProvider {...form}>
			<FormKitType
				isLoading={isPending}
				onCancel={handleCancel}
				onSubmit={handleSubmitWrapper(handleSubmit)}
				canSave={true}
			/>
		</FormProvider>
	);
};

export const ModalNewKitType = ({ isVisible = true, onCloseModal = () => {} }: IModalProps) => {
	return (
		<Modal
			className='p-10 min-w-[50rem]'
			title='Creacion del contenido del kit'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<p className='text-2xs w-5/6 mb-4'>
				Crea tus kits personalizados con los productos previamente añadidos en el inventario.
			</p>
			<CreateForm onCancel={onCloseModal} />
		</Modal>
	);
};
