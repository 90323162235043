import { apiPrivate } from '../config/apiPrivate';

interface IDeleteLocationParams {
	companyId: string;
	locationId: string;
}

interface IDeleteLocationResp {}

export const deleteLocation = async (params: IDeleteLocationParams): Promise<IDeleteLocationResp> => {
	const { data } = await apiPrivate.patch<IDeleteLocationResp>(`/api/locations/delete/${params.companyId}`, {
		locationIds: [params.locationId],
	});
	return data;
};
