import { apiPrivate } from '../config/apiPrivate';

interface IDeleteProductTypeParams {
	id: string;
}

interface IDeleteProductTypeResp {}

export const deleteProductType = async (params: IDeleteProductTypeParams): Promise<IDeleteProductTypeResp> => {
	const { data } = await apiPrivate.delete<IDeleteProductTypeResp>(`/api/products/product-type/${params.id}`);
	return data;
};
