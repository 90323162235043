import { FormLabel, SearchableDropdown } from '@atoms';
import { IFormInputsProps } from '@interfaces';
import { v4 } from 'uuid';

type IOption = {
	id: string;
	value: string;
};

interface IFormSearchableDropdownProps extends Omit<IFormInputsProps, 'pattern' | 'placeholder' | 'onChange'> {
	options: IOption[];
	value: IOption['id'];
	includeEmptyOption?: boolean;
	onChange?: (selectedOptionId: string) => void;
	onBlur?: () => void;
}

export const FormSearchableDropdown = ({
	className,
	required,
	options,
	label,
	value,
	onError,
	errorMessage,
	onChange,
	onBlur,
	disabled,
	includeEmptyOption,
}: IFormSearchableDropdownProps) => {
	const id = v4();

	return (
		<fieldset className={`flex flex-col gap-3 ${className}`}>
			<FormLabel id={id} onError={onError} required={required} disabled={disabled} label={label} />
			<SearchableDropdown
				id={id}
				className={`${onError ? 'ring-[3px] ring-error-300' : ''}`}
				disabled={disabled}
				options={options}
				selectedOptionId={value}
				onChange={onChange}
				onClose={onBlur}
				includeEmptyOption={includeEmptyOption}
			/>
			{onError ? <span className='font-normal text-xs text-error-500'>{errorMessage}</span> : null}
		</fieldset>
	);
};
