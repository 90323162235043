import { ITabRoute } from '@interfaces';
import { EditEntityPage, ClientsPage, DriversPage, NewEntityPage } from '@pages';
import { RouteObject } from 'react-router-dom';
import { createProtectedTabRoute } from '../../router/helpers';
import { TabbedLayout } from '@layout';

const entitiesSubRoutes: ITabRoute[] = [
	createProtectedTabRoute({
		path: 'clients',
		tabName: 'Clientes',
		requiredPermission: 'ENTITIES',
		element: <ClientsPage />,
	}),
	createProtectedTabRoute({
		path: 'drivers',
		tabName: 'Choferes',
		requiredPermission: 'ENTITIES',
		element: <DriversPage />,
	}),
];

export const entitiesRoutes: RouteObject[] = [
	{
		path: 'entities',
		element: <TabbedLayout tabRoutes={entitiesSubRoutes} />,
		children: entitiesSubRoutes,
	},
	{
		path: 'entities/new',
		element: <NewEntityPage />,
	},
	{
		path: 'entities/:id/edit',
		element: <EditEntityPage />,
	},
];
