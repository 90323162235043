import { apiPrivate } from '../config/apiPrivate';

interface IDeleteCategoryParams {
	id: string;
}

interface IDeleteCategoryResp {}

export const deleteCategory = async (params: IDeleteCategoryParams): Promise<IDeleteCategoryResp> => {
	const { data } = await apiPrivate.delete<IDeleteCategoryResp>(`/api/categories/${params.id}`);
	return data;
};
