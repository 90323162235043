import { ButtonGoBack } from '@atoms';
import { useEntity } from '@hooks';
import { FormEditEntity } from '@organisms';
import { useNavigate, useParams } from 'react-router-dom';

const EditEntityPage = () => {
	const navigator = useNavigate();
	const { id: entityId = '' } = useParams();
	const { isLoading, isError, data: entityData } = useEntity(entityId!);

	if (isLoading) return 'Skeleton Loader...';

	if (isError) return 'Some error ocurred while fetching company data';

	return (
		<>
			<ButtonGoBack onClick={() => navigator(-1)} />
			<h2 className='text-xl font-bold text-bummock-ocean_blue'>Detalles de Entidad</h2>
			<FormEditEntity
				entityId={entityId}
				editingEntity={{
					name: entityData!.clientCompany.name,
					contactPhoneNumber: entityData!.viewerUser.phoneNumber,
					identificationNumber: entityData!.clientCompany.identificationNumber,
					email: entityData!.clientCompany.email,
					adminIdentificationNumber: entityData!.clientCompany.identificationNumber,
					adminFirstName: entityData!.viewerUser.firstName,
					adminLastName: entityData!.viewerUser.lastName,
					adminEmail: entityData!.viewerUser.email,
					adminCity: entityData!.viewerUser.city.id,
				}}
			/>
		</>
	);
};

export default EditEntityPage;
