import { IKit } from '@interfaces';
import { IPaginatedResourceParams, getPaginatedResource } from '@api';
import moment from 'moment';

interface IFindAllKitsParams extends IPaginatedResourceParams {
	// This should be named boxId
	kitId: string;
}

interface IKitResponseEntity {
	id: string;
	createdAt: string;
	kit: string;
	status: 'ACTIVE' | 'INACTIVE' | 'RETURNED';
	user: {
		firstName: string;
		lastName: string;
		image: string;
	};
	kitType: {
		name: string;
	};
}

// Retrieves all the kits from a particular box
export const findAllKits = async ({ kitId, ...restParams }: IFindAllKitsParams) => {
	const { data } = await getPaginatedResource<IKitResponseEntity>(`/api/kits/box/${kitId}/kits`, restParams);
	const mappedData: IKit[] = data.data.map(({ id, createdAt, kit, kitType, status, user }) => ({
		id,
		code: kit,
		kitType: kitType.name,
		usage: '-',
		createdBy: `${user.firstName} ${user.lastName}`,
		creationDate: moment.utc(createdAt).format('DD/MM/YYYY'),
		status: status === 'ACTIVE' ? 'Activo' : status === 'RETURNED' ? 'Retornado' : 'Desactivado',
	}));
	return {
		...data,
		data: mappedData,
	};
};
