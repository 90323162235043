import { useState } from 'react';

import { useForm } from 'react-hook-form';

import type { SubmitHandler } from 'react-hook-form';

import { Button } from '@atoms';
import { FormInput } from '@molecules';
import { REGEX_EMAIL } from '@utils';
import { setResetRequestEmail, setResetRequestFailed, setResetRequestWasSuccessfully } from '@RTK/Slices';
import { useAppDispatch } from '@hooks';

import type { IFormResetPasswordParams } from './FormResetPassword.interfaces';
import { forgotPassword } from '@api';

export const FormResetPassword = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const {
		formState: { errors },
		handleSubmit,
		register,
	} = useForm<IFormResetPasswordParams>();

	const hdlSubmit: SubmitHandler<IFormResetPasswordParams> = async formData => {
		try {
			setIsLoading(true);
			const response = await forgotPassword({ email: formData.email });
			console.log(response);
			dispatch(setResetRequestEmail(formData.email));
			dispatch(setResetRequestWasSuccessfully(true));
		} catch (error) {
			dispatch(setResetRequestFailed(true));
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<form onSubmit={handleSubmit(hdlSubmit)} className='mt-3 flex flex-col gap-y-8 text-left'>
			<FormInput
				errorMessage={errors.email?.message}
				label='Correo Electrónico'
				onError={!!errors.email}
				placeholder='j.smith@bummock.io'
				register={{
					...register('email', {
						required: { value: true, message: 'This field is required' },
						minLength: { value: 8, message: 'Min length is 8' },
						maxLength: { value: 30, message: 'Max length is 30' },
						pattern: { value: REGEX_EMAIL, message: 'Invalid email' },
					}),
				}}
			/>
			<Button type='submit' className='w-full justify-center' isLoading={isLoading}>
				Siguiente
			</Button>
		</form>
	);
};
