import { FormNewCompany } from '@organisms';

const NewCompanyPage = () => {
	return (
		<>
			<h2 className='text-xl font-bold text-bummock-ocean_blue mt-8'>Detalles de Empresa</h2>
			<FormNewCompany />
		</>
	);
};

export default NewCompanyPage;
