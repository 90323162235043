import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProductType, Modal } from '@organisms';
import { QUERY_KEYS, updateProductType } from '@api';
import { AxiosError } from 'axios';
import { IModalProps } from '@interfaces';

interface IModalEditCategoryProps extends IModalProps {
	editingItemId: string;

	// This should ideally come from a 'detail endpoint' but since
	// we don't have one, let's work some magic
	name: string;
}

interface IFormEditCategoryValues {
	name: string;
}

export const ModalEditProductType = ({ isVisible, onCloseModal, editingItemId, name }: IModalEditCategoryProps) => {
	const form = useForm<IFormEditCategoryValues>({
		defaultValues: {
			name,
		},
	});
	const {
		handleSubmit: handleSubmitWrapper,
		reset,
		setError,
		formState: { isDirty },
	} = form;

	useEffect(() => {
		if (isVisible) {
			reset({
				name,
			});
		} else {
			setTimeout(() => reset(), 500);
		}
	}, [isVisible]);

	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationKey: ['categories'],
		mutationFn: updateProductType,
		onSuccess: () => {
			onCloseModal();
			queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.productsTypes] });
		},
		onError: (error: AxiosError) => {
			if (error.response?.status === 400) {
				setError('name', { message: 'Categoría ya existe' }, { shouldFocus: true });
			} else {
				throw error;
			}
		},
	});
	const handleSubmit = ({ name }: IFormEditCategoryValues) => {
		mutation.mutate({
			id: editingItemId,
			name,
		});
	};

	return (
		<Modal
			className='p-10 w-full max-w-[838px]'
			title='Edición de Tipo de Producto'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<p className='text-2xs w-3/4'>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
				dolore magna aliqua.
			</p>
			<FormProvider {...form}>
				<FormProductType
					canSave={isDirty}
					isLoading={mutation.isPending}
					onSubmit={handleSubmitWrapper(handleSubmit)}
					onCancel={onCloseModal}
				/>
			</FormProvider>
		</Modal>
	);
};
