import { getOneCompany, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

export const useCompany = (companyId: string) => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.companies, companyId],
		queryFn: () => getOneCompany({ companyId }),
	});

	return queryResult;
};
