import { apiPrivate } from '../config/apiPrivate';

interface IRole {
	id: string;
	name: string;
	description: string;
	isActive: boolean;
}

interface ICategory {
	id: string;
	name: string;
	description: string;
	isActive: boolean;
	role: IRole[];
}

export const findAllRoles = async (): Promise<ICategory[]> => {
	const { data } = await apiPrivate.get<ICategory[]>('/api/roles');
	return data;
};
