import { findAllCompanies, QUERY_KEYS } from '@api';
import {
	Badge,
	BadgeIcon,
	Button,
	IcMarker,
	IcPlus,
	IcTrash,
	IcUserDisabled,
	IcUserEdit,
	IcUsers,
	IcWorkflows,
} from '@atoms';
import { useTableQuery } from '@hooks';
import { ICompany } from '@interfaces';
import { BaseTable, TablePagination, TableSearchbar } from '@organisms';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

const columnHelper = createColumnHelper<ICompany>();
const companiesColumns: ColumnDef<ICompany, string>[] = [
	{
		header: 'Nombre',
		accessorKey: 'name',
	},
	{
		header: 'RUC',
		accessorKey: 'ruc',
		enableSorting: false,
	},
	{
		header: 'Correo Electrónico',
		accessorKey: 'adminEmail',
		enableSorting: false,
	},
	{
		header: 'Localidades Asignadas',
		accessorKey: 'assignedLocations',
		cell: columnDefBase => (
			<div className='flex justify-center'>
				<BadgeIcon
					icon={<IcMarker className='stroke-bummock-midnight_blue h-4 stroke-[1.5]' />}
					label={columnDefBase.getValue()}
				/>
			</div>
		),
		enableSorting: false,
		meta: { alignment: 'center' },
	},
	{
		header: 'Flujos Asignados',
		accessorKey: 'assignedWorkflows',
		cell: columnDefBase => (
			<div className='flex justify-center'>
				<BadgeIcon
					icon={<IcWorkflows className='stroke-[1.5] stroke-bummock-midnight_blue h-4' />}
					label={columnDefBase.getValue()}
				/>
			</div>
		),
		enableSorting: false,
		meta: { alignment: 'center' },
	},
	{
		header: 'Usuarios',
		accessorKey: 'users',
		cell: columnDefBase => (
			<div className='flex justify-center'>
				<BadgeIcon
					icon={<IcUsers className='stroke-bummock-midnight_blue h-4 stroke-2' />}
					label={columnDefBase.getValue()}
				/>
			</div>
		),
		enableSorting: false,
		meta: { alignment: 'center' },
	},
	columnHelper.accessor('status', {
		header: 'Estado',
		cell: columnDefBase => (
			<div className='flex justify-center'>
				<Badge
					label={columnDefBase.getValue()}
					variant={
						columnDefBase.getValue().toUpperCase() === 'ACTIVO'
							? 'success'
							: columnDefBase.getValue().toUpperCase() === 'DESACTIVADO'
								? 'danger'
								: 'warning'
					}
				/>
			</div>
		),
		meta: { alignment: 'center' },
	}),
];

const CompaniesPage = () => {
	const navigator = useNavigate();
	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.companies],
		fetcherFn: findAllCompanies,
		tableColumns: [
			...companiesColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { status, id } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcTrash
								onClick={() => console.log('Clicked item with id', id)}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
							<IcUserDisabled
								onClick={() => console.log('Disabling with ID', id)}
								className={`${status.toUpperCase() === 'DESACTIVADO' ? ' fill-red-500' : 'hover:fill-bummock-midnight_blue'} w-5 cursor-pointer transition-all ease-in-out duration-200 fill-bummock-disabled_grey_2`}
							/>
							<IcUserEdit
								onClick={() => navigator(`/companies/${id}/information`)}
								className='w-5 cursor-pointer transition-all ease-in-out duration-200 fill-bummock-disabled_grey_2 hover:fill-bummock-midnight_blue'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	return (
		<>
			<div className='flex justify-between mt-8'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<Button onClick={() => navigator('new')}>
					<IcPlus className='stroke-2 stroke-bummock-off_white w-5' />
					Nueva Empresa
				</Button>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default CompaniesPage;
