import { ITabRoute } from '@interfaces';
import { TabbedLayout } from '@layout';
import { InspectionDetailsPage, InspectionDocumentPage, InspectionsPage } from '@pages';
import { RouteObject } from 'react-router-dom';
import { createProtectedTabRoute } from '../../router/helpers';

const inspectionsSubRoutes: ITabRoute[] = [
	createProtectedTabRoute({
		path: 'all',
		tabName: 'Inspecciones',
		element: <InspectionsPage />,
		requiredPermission: 'INSPECTIONS_LIST',
	}),
];

export const inspectionsRoutes: RouteObject[] = [
	{
		path: 'inspections',
		element: <TabbedLayout tabRoutes={inspectionsSubRoutes} />,
		children: inspectionsSubRoutes,
	},
	{
		path: 'inspections/:id/pdf',
		element: <InspectionDocumentPage />,
	},
	{
		path: 'inspections/:id/details',
		element: <InspectionDetailsPage />,
	},
];
