import { ILocation } from '@interfaces';
import { IPaginatedResourceParams, getPaginatedResource } from '@api';

interface IFindAllLocations extends IPaginatedResourceParams {
	userId: string;
}

export const findAllLocationsByUser = async ({ userId, ...restParams }: IFindAllLocations) => {
	const { data } = await getPaginatedResource<ILocation>(`/api/locations/user/${userId}`, restParams);
	return data;
};
