import { apiPrivate } from '../config/apiPrivate';

interface IUpdateProfilePictureParams {
	userId: string;
	file: File;
}

interface IUpdateProfilePictureResp {}

export const updateProfilePicture = async ({
	userId,
	...restParams
}: IUpdateProfilePictureParams): Promise<IUpdateProfilePictureResp> => {
	const { data } = await apiPrivate.patch<IUpdateProfilePictureResp>(`/api/users/${userId}`, restParams, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return data;
};
