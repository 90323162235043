import { Modal } from '../Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { FormKitType } from '@organisms';
import { useKitTypeDetails } from '@hooks';
import { IModalProps } from '@interfaces';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IKitTypeSecurityItem, QUERY_KEYS, updateKitType } from '@api';
import toast from 'react-hot-toast';

interface IModalEditKitTypeProps extends IModalProps {
	editingItemId: string;
}

interface IFormEditKitTypeValues {
	name: string;
	usage: string;
	usageDescription: string;
	requiresPackaging: boolean;
	packagingTypeId: string;
	items: {
		typeId: string;
		quantity: string;
	}[];
}

interface IEditFormProps {
	kitType: IKitTypeSecurityItem;
	onCancel: () => void;
}

const EditForm = ({ kitType, onCancel }: IEditFormProps) => {
	const form = useForm<IFormEditKitTypeValues>({
		defaultValues: {
			name: kitType.name,
			usage: kitType.kitUsage.id,
			usageDescription: kitType.description,
			items: kitType.kitTypeDetail
				.filter(detail => !detail.package_container)
				.map(({ quantity, productType }) => ({
					quantity: quantity + '',
					typeId: productType.id,
				})),
			requiresPackaging: kitType.kitTypeDetail.some(detail => detail.package_container),
			packagingTypeId: kitType.kitTypeDetail.find(detail => detail.package_container)?.productType.id,
		},
	});
	const {
		formState: { isDirty },
		handleSubmit: handleSubmitWrapper,
		reset,
	} = form;

	const queryClient = useQueryClient();
	const { mutate, isPending } = useMutation({
		mutationFn: updateKitType,
		onSuccess: () => {
			onCancel();
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.kitsTypes, kitType.id],
			});
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.kitsTypes],
			});
		},
		onError: () => toast.error('No se pudo editar el tipo de kit.'),
	});

	// This handles the following cases:
	// Changing heading infor
	// Changing detail info
	// - Add items: Appending an item without passing an id (only the type id) adds it to the detail
	// - Change items: Pass ID and new item type
	// - Update quantity: Pass ID and new quantity
	// - Delete item: Pass isActive set to false
	const handleSubmit = (formValues: IFormEditKitTypeValues) => {
		const { kitTypeDetail } = kitType;

		const nonContainingSecurityItems: {
			id?: string;
			productType: string;
			quantity: number;
			package_container: boolean;
			isActive: boolean;
		}[] = kitTypeDetail.map(item => {
			const found = formValues.items.find(formItem => formItem.typeId === item.productType.id);
			return {
				id: item.id,
				productType: item.productType.id,
				quantity: found ? +found.quantity : +item.quantity, // From the form
				package_container: false, // Todos los de aquí son no contenedores
				isActive: true, // For now
			};
		});

		// If the number of submitted items is less than the original, we should set the missing item isActive property to false
		const originalNonContainingItems = kitTypeDetail.filter(detail => !detail.package_container);
		if (originalNonContainingItems.length !== formValues.items.length) {
			console.log('Deleting an item');
			// Conseguir los items que hayan sido removidos
			const removedItems = originalNonContainingItems.filter(originalDetail => {
				return !formValues.items.find(
					submittedDetail => submittedDetail.typeId === originalDetail.productType.id
				);
			});
			console.log('Deleted items', removedItems);
			nonContainingSecurityItems.forEach(item => {
				const found = removedItems.find(removedItem => removedItem.productType.id === item.productType);
				if (found) {
					item.isActive = false;
				}
			});
		}

		// We add items
		formValues.items.forEach(formDetailItem => {
			const isAlreadyAdded = !!nonContainingSecurityItems.find(
				item => item.productType === formDetailItem.typeId
			);
			if (!isAlreadyAdded) {
				nonContainingSecurityItems.push({
					// No id means the item will be added
					productType: formDetailItem.typeId,
					quantity: +formDetailItem.quantity, // From the form
					package_container: false, // Todos los de aquí son no contenedores
					isActive: true, // For now
				});
			}
		});

		const containerFound = kitTypeDetail.find(
			originalItem => originalItem.productType.id === formValues.packagingTypeId
		);
		const containerId = containerFound ? { id: containerFound.id } : {};
		const containingSecurityItems = formValues.packagingTypeId
			? [
					{
						...containerId, // This should be set dinamically
						productType: formValues.packagingTypeId,
						quantity: 1,
						package_container: true,
						isActive: formValues.requiresPackaging,
					},
				]
			: [];

		const remoteKitType = {
			name: formValues.name,
			description: formValues.usageDescription,
			kitUsage: formValues.usage,
			itemsType: [...nonContainingSecurityItems, ...containingSecurityItems],
		};

		mutate({
			id: kitType.id,
			...remoteKitType,
		});
	};

	const handleCancel = () => {
		reset();
		onCancel();
	};

	return (
		<FormProvider {...form}>
			<FormKitType
				isLoading={isPending}
				onCancel={handleCancel}
				onSubmit={handleSubmitWrapper(handleSubmit)}
				canSave={isDirty}
			/>
		</FormProvider>
	);
};

export const ModalEditKitType = ({ editingItemId, isVisible, onCloseModal }: IModalEditKitTypeProps) => {
	const { isLoading, isError, data } = useKitTypeDetails(editingItemId);

	if (!data) return null;

	if (isLoading) return <p>Loading...</p>;

	if (isError) return <p className='text-red-500'>Error consiguiendo información.</p>;

	return (
		<Modal
			className='p-10'
			title='Edición del contenido del kit'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<p className='text-2xs w-5/6 mb-4'>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
				dolore magna aliqua.
			</p>
			<EditForm kitType={data} onCancel={onCloseModal} />
		</Modal>
	);
};
