export const IcExpand = ({ className = '' }: { className?: string }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			className={className}
			width='20'
			height='21'
			viewBox='0 0 20 21'
			stroke='black'
			fill='none'
		>
			<path
				d='M3.99811 10.668H15.9981M3.99811 10.668L5.99811 7.668M3.99811 10.668L5.99811 13.668M15.9981 10.668L13.9981 7.668M15.9981 10.668L13.9981 13.668M18.9981 19.668V1.668M0.998108 19.668V1.668'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
