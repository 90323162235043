import { NumberedContentPage } from './NumberedContentPage';
import { Container } from '../components/Container';
import { View, Text, Image, StyleSheet, Svg, Path } from '@react-pdf/renderer';
import { NumberedSectionTitle } from '../components/NumberedSectionTitle';
import { ISecurityProcedureCheckListPage } from '../interfaces';

const styles = StyleSheet.create({
	headingSpanishText: {
		fontSize: '14px',
		fontWeight: 'bold',
	},
	headingEnglishText: {
		color: '#A1A1A1',
	},
	checkListTableHeader: {
		color: 'white',
		backgroundColor: '#092D45',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
	},
	numberCellHeader: {
		flexGrow: 0,
		flexBasis: '26px',
		padding: '8px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	proceduresCellHeader: {
		flexGrow: 1,
		padding: '8px',
	},
	checkListHeaderSpanishText: {
		fontWeight: 600,
	},
	checkListHeaderEnglishText: {
		fontSize: '8px',
		fontWeight: 400,
	},
	complianceCellHeader: {
		flexBasis: '85px',
		flexGrow: 0,
		padding: '8px',
	},

	rowContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	numberCell: {
		flexGrow: 0,
		flexBasis: '25px',
		flexShrink: 0,
		borderLeft: '1px solid #eee',
		borderBottom: '1px solid #eee',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	proceduresCell: {
		flexBasis: '453px',
		flexGrow: 0,
		flexShrink: 0,
		padding: '8px',

		borderLeft: '1px solid #eee',
		borderRight: '1px solid #eee',
		borderBottom: '1px solid #eee',
	},
	complianceCell: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',

		flexBasis: '85px',
		flexGrow: 0,

		padding: '8px',
		textAlign: 'center',

		borderRight: '1px solid #eee',
		borderBottom: '1px solid #eee',
	},

	cellEnglishText: {
		fontSize: '8px',
		fontWeight: 400,
		color: '#A1A1A1',
	},

	bold: {
		fontWeight: 'bold',
	},
	marginCenter: {
		margin: '0 auto',
	},
});

const CheckIcon = () => (
	<Svg width='13' height='13' viewBox='0 0 13 13'>
		<Path
			d='M3.875 6.5L5.625 8.375L9.125 4.625M4.05 12.125H8.95C9.93009 12.125 10.4201 12.125 10.7945 11.9206C11.1238 11.7409 11.3915 11.454 11.5593 11.1012C11.75 10.7001 11.75 10.1751 11.75 9.125V3.875C11.75 2.8249 11.75 2.29985 11.5593 1.89877C11.3915 1.54596 11.1238 1.25913 10.7945 1.07936C10.4201 0.875 9.93009 0.875 8.95 0.875H4.05C3.06991 0.875 2.57986 0.875 2.20552 1.07936C1.87623 1.25913 1.60852 1.54596 1.44074 1.89877C1.25 2.29985 1.25 2.8249 1.25 3.875V9.125C1.25 10.1751 1.25 10.7001 1.44074 11.1012C1.60852 11.454 1.87623 11.7409 2.20552 11.9206C2.57986 12.125 3.06991 12.125 4.05 12.125Z'
			stroke='#092D45'
			stroke-width='1.33'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</Svg>
);

const DocumentInformation = ({
	client,
	createdBy,
	date,
	inspectionPlace,
	company,
	companyImageSrc,
}: {
	client: string;
	createdBy: string;
	date: string;
	inspectionPlace: string;
	company: string;
	companyImageSrc: string;
}) => (
	<Container
		style={{
			display: 'flex',
			flexDirection: 'row',
		}}
	>
		<View
			style={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				width: 'auto',
				gap: '4px',
			}}
		>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: '8px',
				}}
			>
				<Text
					style={{
						fontWeight: 'bold',
					}}
				>
					Cliente:
				</Text>
				<Text>{client}</Text>
			</View>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: '8px',
				}}
			>
				<Text
					style={{
						fontWeight: 'bold',
					}}
				>
					Realizado por:
				</Text>
				<Text>{createdBy}</Text>
			</View>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: '8px',
				}}
			>
				<Text
					style={{
						fontWeight: 'bold',
					}}
				>
					Fecha:
				</Text>
				<Text>{date}</Text>
			</View>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: '8px',
				}}
			>
				<Text
					style={{
						fontWeight: 'bold',
					}}
				>
					Lugar inspección:
				</Text>
				<Text>{inspectionPlace}</Text>
			</View>
		</View>
		<View
			style={{
				flexShrink: 1,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: '8px',
				}}
			>
				<Text
					style={{
						fontWeight: 'bold',
					}}
				>
					Empresa:
				</Text>
				<Text>{company}</Text>
			</View>
			<Image
				style={{
					margin: '0 auto',
					width: '90%',
				}}
				src={companyImageSrc}
			/>
		</View>
	</Container>
);

const APPLIED_PROCEDURES = [
	{
		name: 'Coordinación previa con el cliente, para establecer el plan de operación e inspección.',
		english: 'Prior coordination with the client, to establish the operation and inspection plan.',
	},
	{
		name: 'Actualización de información de seguridad con el cliente, previa a la inspección del contenedor.',
		english: 'Update security information with the customer prior to the inspection of the container.',
	},
	{
		name: 'Reporte al cliente de los trabajos a realizar en cada una de las áreas.',
		english: 'Client report of the works, to be done in each one of the areas',
	},
];

interface ISecurityProcedureCheckListPageValues {
	pageData: ISecurityProcedureCheckListPage;
}

export const SecurityProcedureCheckListPage = ({
	pageData: { client, date, inspectionPlace },
}: ISecurityProcedureCheckListPageValues) => (
	<NumberedContentPage>
		<Container id='SECURITY_PROCEDURES_PAGE'>
			<Text style={styles.headingSpanishText}>Reporte de inspección de contenedor</Text>
			<Text style={styles.headingEnglishText}>Inspection Report</Text>
		</Container>

		<DocumentInformation
			client={client}
			createdBy='Dep. Reporte'
			date={date}
			inspectionPlace={inspectionPlace}
			company='Risk Protection S.A.'
			companyImageSrc='/logo-risk-protection-horizontal.png'
		/>

		<NumberedSectionTitle
			number='1'
			spanishTitle='Lista de verifcación de los procedimientos de seguridad'
			englishTitle='Security procedure check list'
		/>

		{/* Table */}
		<View>
			{/* Header */}
			<View style={styles.checkListTableHeader}>
				<View style={styles.numberCellHeader}>
					<Text style={styles.bold}>#</Text>
				</View>
				<View style={styles.proceduresCellHeader}>
					<Text style={styles.checkListHeaderSpanishText}>Procedimientos aplicados.</Text>
					<Text style={styles.checkListHeaderEnglishText}>Applied procedures</Text>
				</View>
				<View style={styles.complianceCellHeader}>
					<View style={styles.marginCenter}>
						<Text style={styles.checkListHeaderSpanishText}>Cumplimiento</Text>
						<Text style={styles.checkListHeaderEnglishText}>Compliance</Text>
					</View>
				</View>
			</View>

			{/* Table rows */}
			{APPLIED_PROCEDURES.map((item, i) => (
				<View key={i} style={styles.rowContainer}>
					<View style={styles.numberCell}>
						<Text style={styles.bold}>{i + 1}.</Text>
					</View>

					<View style={styles.proceduresCell}>
						<Text>{item.name}</Text>
						<Text style={styles.cellEnglishText}>{item.english}</Text>
					</View>

					<View style={styles.complianceCell}>
						<CheckIcon />
					</View>
				</View>
			))}
		</View>

		{/*<ObservationsTable spanishObservation={spanishObservation} englishObservation={englishObservation} />*/}
	</NumberedContentPage>
);
