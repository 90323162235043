import { apiPrivate } from '../config/apiPrivate';

interface ICreateProductTypeParams {
	name: string;
}

interface ICreateProductTypeResp {}

export const createProductType = async (params: ICreateProductTypeParams): Promise<ICreateProductTypeResp> => {
	const { data } = await apiPrivate.post<ICreateProductTypeResp>('/api/products/product-type', params);
	return data;
};
