import { getDispatchDetails, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

interface IData {
	code: string;
	createdBy: string;
	createdAt: string;
}

export const useDispatchDetails = (dispatchId: string, dispatchType: 'bulk' | 'kits') => {
	const { data, ...rest } = useQuery({
		queryKey: [QUERY_KEYS.dispatchDetails, dispatchType, dispatchId],
		queryFn: () => getDispatchDetails({ dispatchId, dispatchType }),
	});

	const transformedData: IData = {
		code: data?.code.toString().padStart(6, '0') ?? '',
		createdBy: `${data?.user.firstName} ${data?.user.lastName}`,
		createdAt: moment.utc(data?.createdAt).format('DD/MM/YYYY'),
	};

	return { data: transformedData, ...rest };
};
