export const QUERY_KEYS = {
	users: 'users',
	companies: 'companies',
	boxDetails: 'box-details',
	categories: 'categories',
	courtyards: 'courtyards',
	farms: 'farms',
	kitDetails: 'kit-details',
	usages: 'usages',
	kitsTypes: 'kits-types',
	kitsTypesSelect: 'kits-types-select',
	places: 'places',
	productsTypes: 'productsTypes',
	roles: 'roles',
	suppliers: 'suppliers',
	dispatches: 'dispatches',
	dispatchDetails: 'dispatch-details',
	drivers: 'drivers',
	entities: 'entities',
	inventory: 'inventory',
	kits: 'kits',
	boxes: 'boxes',
	seals: 'seals',
	kitCount: 'kit-count',
	inspections: 'inspections',
	processes: 'processes',
	locations: 'locations',
	locationsTypes: 'locations-types',
	workflows: 'workflows',
} as const;
