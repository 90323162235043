import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@atoms';
import { FormInput, FormMultiCheckSelect, Stepper } from '@molecules';
import { FormNewUser, RolesCheckList } from '@organisms';
import { IFormNewUserValues } from '@interfaces';
import { assignLocationToUser, createUser } from '@api';
import { useAppSelector, useCourtyards, useFarms } from '@hooks';
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';

const NewUserPage = () => {
	const { companies } = useAppSelector(state => state.userProfile);
	const [currentStep, setCurrentStep] = useState<number>(1);
	const navigator = useNavigate();

	const firstStepForm = useForm<IFormNewUserValues>();
	const [selectedRoleId, setSelectedRoleId] = useState<string>('');
	const thridStepForm = useForm<{ courtyards: string[]; farms: string[] }>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { data: courtyards = [] } = useCourtyards({ by: 'company', companyId: companies[0].id });
	const { data: farms = [] } = useFarms({ by: 'company', companyId: companies[0].id });

	const handleChangeStep = (step: number) => {
		if (step === 0) {
			navigator(-1);
			return;
		}
		setCurrentStep(step);
	};

	const assignLocationsMutation = useMutation({
		mutationFn: ({ courtyards, userId, farms }: { userId: string; courtyards: string[]; farms: string[] }) =>
			Promise.allSettled([
				assignLocationToUser({ userId, locationsIds: courtyards }),
				assignLocationToUser({ userId, locationsIds: farms }),
			]),
	});

	const createUserMutation = useMutation({
		mutationFn: createUser,
	});

	// TODO: Refactor this into a useMutation call
	const handleSaveUser = async () => {
		setIsLoading(true);
		const firstStepFields = firstStepForm.getValues();
		const thirdStepFields = thridStepForm.getValues();

		try {
			const { id: userId } = await createUserMutation.mutateAsync({
				age: 0,
				// TODO: This will change when multi-company users are better defined
				company: companies[0].id,
				dni: firstStepFields.nationalIdNumber,
				email: firstStepFields.email,
				firstName: firstStepFields.name,
				lastName: firstStepFields.lastName,
				phoneNumber: firstStepFields.phoneNumber,
				role: selectedRoleId,
				twoFactorEnabled: true,
				city: firstStepFields.citiesSelectedOptionId,
				file: firstStepFields.image[0],
			});
			await assignLocationsMutation.mutateAsync({
				userId,
				farms: thirdStepFields.farms,
				courtyards: thirdStepFields.courtyards,
			});

			setIsLoading(false);
			navigator('/users');
		} catch (error) {
			setIsLoading(false);
			if (!(error instanceof AxiosError)) throw error;

			if (error.response?.status === 400) {
				setCurrentStep(1);
				// TODO: Show error
				firstStepForm.setError('root', { message: error.response.data.message });
			}

			console.error(error);
		}
	};

	return (
		<>
			<Stepper className='mt-8' stepsQuantity={3} currentStep={currentStep} onChangeStep={handleChangeStep} />

			<section className={currentStep !== 1 ? 'hidden' : ''}>
				<h2 className='text-xl font-bold text-bummock-ocean_blue mb-8'>Detalles del perfil</h2>
				<FormNewUser form={firstStepForm} onSubmit={() => handleChangeStep(currentStep + 1)} />
				<p className='text-red-500 mt-4'>{firstStepForm.formState.errors.root?.message}</p>
			</section>

			<section className={currentStep !== 2 ? 'hidden' : ''}>
				<div className='flex flex-col gap-2 mb-8'>
					<h2 className='text-xl font-bold text-bummock-ocean_blue'>Roles y Permisos</h2>
					<p className='text-sm'>Asigna un rol predefinido según la función del usuario.</p>
				</div>
				<RolesCheckList
					onChangeSelectedRole={(roleId: string) => setSelectedRoleId(roleId)}
					selectedRoleId={selectedRoleId}
				/>
				<Button
					onClick={() => handleChangeStep(currentStep + 1)}
					className='mt-4'
					disabled={selectedRoleId.length === 0}
				>
					Siguiente
				</Button>
			</section>

			<section className={currentStep !== 3 ? 'hidden' : ''}>
				<h2 className='text-xl font-bold text-bummock-ocean_blue mb-8'>Asignación de localidades</h2>
				<div>
					<form onSubmit={thridStepForm.handleSubmit(handleSaveUser)}>
						<div className='grid grid-cols-4 gap-8'>
							<FormInput label='Nombre' placeholder={firstStepForm.getValues().name} disabled={true} />
							<FormInput
								label='Apellido'
								placeholder={firstStepForm.getValues().lastName}
								disabled={true}
							/>
							<FormInput
								label='Numero de Cédula'
								placeholder={firstStepForm.getValues().nationalIdNumber}
								disabled={true}
							/>
							<FormInput
								label='Correo Electrónico'
								placeholder={firstStepForm.getValues().email}
								disabled={true}
							/>
							<Controller
								control={thridStepForm.control}
								name='courtyards'
								render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
									<FormMultiCheckSelect
										label='Patios'
										options={courtyards.map(({ id, name }) => ({ id, value: name }))}
										selectedOptions={value}
										onBlur={onBlur}
										onError={!!error?.message}
										errorMessage={error?.message}
										onChange={onChange}
									/>
								)}
							/>
							<Controller
								control={thridStepForm.control}
								name='farms'
								render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
									<FormMultiCheckSelect
										label='Fincas'
										options={farms.map(({ id, name }) => ({ id, value: name }))}
										selectedOptions={value}
										onBlur={onBlur}
										onError={!!error?.message}
										errorMessage={error?.message}
										onChange={onChange}
									/>
								)}
							/>
						</div>
						<Button isLoading={isLoading} className='mt-8'>
							Crear Usuario
						</Button>
					</form>
				</div>
			</section>
		</>
	);
};

export default NewUserPage;
