import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		gap: '8px',
	},
	numberText: {
		fontWeight: 'bold',
	},
	innerContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	titleSpanishText: {
		fontWeight: 'bold',
	},
	titleEnglishText: {
		fontWeight: 400,
		color: '#A1A1A1',
	},
});

export const NumberedSectionTitle = ({
	number,
	spanishTitle,
	englishTitle,
}: {
	number: string;
	spanishTitle: string;
	englishTitle: string;
}) => (
	<View style={styles.container}>
		<Text style={styles.numberText}>{number}.</Text>
		<View style={styles.innerContainer}>
			<Text style={styles.titleSpanishText}>{spanishTitle}</Text>
			<Text style={styles.titleEnglishText}>{englishTitle}</Text>
		</View>
	</View>
);
