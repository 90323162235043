import { Image, Page, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
	coverPage: {
		flexDirection: 'row',
		backgroundColor: 'white',
	},
	backgroundImage: {
		objectFit: 'cover',
	},
	innerContainer: {
		padding: '32px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		position: 'absolute',
		height: '100%',
		width: '100%',
		color: 'white',
		justifyContent: 'space-between',
	},
	bottomContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: '8px',
		alignItems: 'center',
	},
	companyNameText: {
		fontSize: '16px',
		fontWeight: 'extrabold',
		textAlign: 'right',
		marginBottom: '8px',
		marginLeft: 'auto',
	},
	documentTitleText: {
		fontSize: '16px',
		fontWeight: 'extrabold',
		marginBottom: '8px',
		marginLeft: 'auto',
	},
	additionalInfoText: {
		marginLeft: 'auto',
		fontSize: '12px',
	},
});

const BummockWhiteLogo = () => (
	<Svg
		style={{
			width: '110px',
			height: '17px',
		}}
	>
		<Path
			d='M8.9511 5.67413H4.79938L5.57326 6.96282L5.96186 7.42117C5.98511 7.4411 6.02164 7.47099 6.06482 7.50753C6.48664 7.85295 6.55307 7.91938 6.59624 8.00574L6.60289 8.01902L7.64248 10.3905L8.50936 11.7224L8.53925 11.6825L8.86142 9.73285C8.87471 9.67307 8.87471 9.60996 8.86142 9.55018L8.65218 8.49398C8.64553 8.45744 8.66214 8.42423 8.69535 8.4043L9.89105 7.7666C9.94419 7.74003 9.96412 7.68024 9.94087 7.6271L8.9511 5.67081V5.67413Z'
			fill='white'
		/>
		<Path
			d='M15.0492 0.393127H1.55773C0.697489 0.393127 0 1.09062 0 1.95085V15.4423C0 16.3025 0.697489 17 1.55773 17H15.0492C15.9094 17 16.6069 16.3025 16.6069 15.4423V1.95085C16.6069 1.09062 15.9094 0.393127 15.0492 0.393127ZM13.528 5.47151L11.3325 9.39406C11.2728 9.50035 11.1864 9.59334 11.0801 9.65977L10.5919 9.96534C10.4723 10.2875 10.2 11.0215 10.1103 11.2341C10.087 11.2906 10.0571 11.3437 10.0173 11.3935L8.72858 13.0908C8.63558 13.2502 8.46287 13.3565 8.27023 13.3664C8.26027 13.3664 8.2503 13.3664 8.24034 13.3664C8.04438 13.3664 7.86502 13.2668 7.76206 13.104L6.61618 11.3404C6.59626 11.3072 6.57633 11.274 6.55972 11.2374L5.35738 8.49065C5.27103 8.40429 5.01196 8.19504 4.909 8.10869C4.85253 8.06219 4.80935 8.02565 4.77946 8.0024C4.74957 7.97583 4.723 7.94926 4.69643 7.91937L4.23808 7.38131C4.21151 7.35141 4.18826 7.3182 4.16833 7.28499L3.08556 5.48812C2.9826 5.32205 2.97928 5.11945 3.0756 4.94674C3.17524 4.7707 3.36456 4.6611 3.57048 4.6611H13.0397C13.2423 4.6611 13.4317 4.76738 13.5313 4.94341C13.6276 5.1128 13.6276 5.31209 13.5313 5.48148L13.528 5.47151Z'
			fill='white'
		/>
		<Path
			d='M34.1464 8.59193L33.8442 8.45244L34.1398 8.29965C34.525 8.10369 34.8273 7.81141 35.0432 7.43277C35.2591 7.05082 35.3687 6.59911 35.3687 6.08762C35.3687 5.21741 35.0731 4.54982 34.4653 4.04829C33.8574 3.54344 33.0038 3.29102 31.9344 3.29102H26.9722V14.1054H31.9344C33.1168 14.1054 34.0501 13.8397 34.7144 13.3116C35.3753 12.7868 35.6975 12.0794 35.6975 11.1461C35.6975 10.5748 35.5547 10.0533 35.2757 9.59831C34.9967 9.1466 34.618 8.8045 34.1464 8.58529V8.59193ZM29.0348 5.11445H31.9809C32.396 5.11445 32.7282 5.22738 32.9706 5.45323C33.2164 5.67909 33.3426 5.99462 33.3426 6.39318C33.3426 6.79175 33.2197 7.10064 32.9806 7.32317C32.7448 7.54238 32.4093 7.65198 31.9842 7.65198H29.0381V5.11445H29.0348ZM33.2231 11.8568C32.9507 12.106 32.5721 12.2355 32.1004 12.2355H29.0348V9.39571H32.1004C32.5754 9.39571 32.9507 9.5186 33.2231 9.76438C33.4987 10.0135 33.6382 10.3656 33.6382 10.8173C33.6382 11.269 33.4987 11.6044 33.2231 11.8602V11.8568Z'
			fill='white'
		/>
		<Path
			d='M44.9043 9.46213C44.9043 10.3456 44.6884 11.0564 44.26 11.5712C43.8249 12.096 43.2237 12.3617 42.4764 12.3617C41.7291 12.3617 41.1014 12.096 40.6696 11.5712C40.2478 11.0564 40.0319 10.3456 40.0319 9.46213V3.29102H37.9294V9.46213C37.9294 11.0033 38.3313 12.2156 39.1218 13.0658C39.909 13.9128 41.0383 14.3446 42.4764 14.3446C43.9146 14.3446 45.0372 13.9161 45.821 13.0692C46.6082 12.2189 47.0068 11.0066 47.0068 9.46213V3.29102H44.901V9.46213H44.9043Z'
			fill='white'
		/>
		<Path
			d='M55.3567 8.14355L51.7364 3.29102H49.8033V14.1054H51.8925V6.80171L55.2139 11.1461H55.5294L58.8209 6.81832V14.1054H60.91V3.29102H58.977L55.3567 8.14355Z'
			fill='white'
		/>
		<Path
			d='M69.4095 8.14355L65.7892 3.29102H63.8561V14.1054H65.9453V6.80171L69.2667 11.1461H69.5789L72.8704 6.81832V14.1054H74.9628V3.29102H73.0265L69.4095 8.14355Z'
			fill='white'
		/>
		<Path
			d='M85.5912 3.72281C84.7742 3.25782 83.8143 3.01868 82.7315 3.01868C81.6488 3.01868 80.6889 3.25449 79.8718 3.72281C79.0581 4.1878 78.4237 4.8554 77.982 5.70235C77.5369 6.55594 77.311 7.56232 77.311 8.69823C77.311 9.83414 77.5369 10.8405 77.982 11.6941C78.4237 12.5411 79.0614 13.2087 79.8718 13.6737C80.6856 14.1386 81.6488 14.3745 82.7315 14.3745C83.8143 14.3745 84.7742 14.1386 85.5912 13.6737C86.405 13.2087 87.0394 12.5411 87.4811 11.6941C87.9262 10.8405 88.152 9.83414 88.152 8.69823C88.152 7.56232 87.9262 6.55594 87.4811 5.70235C87.0394 4.8554 86.4016 4.1878 85.5912 3.72281ZM85.6377 10.6147C85.3654 11.1693 84.9701 11.6078 84.4653 11.9133C83.9638 12.2189 83.3792 12.375 82.7282 12.375C82.0772 12.375 81.496 12.2189 81.0011 11.9133C80.5029 11.6078 80.111 11.1693 79.8386 10.6147C79.5663 10.0666 79.4301 9.41897 79.4301 8.69823C79.4301 7.97749 79.5663 7.32982 79.8386 6.7818C80.111 6.22713 80.5029 5.78871 81.0011 5.48314C81.4993 5.17757 82.0805 5.02147 82.7282 5.02147C83.3759 5.02147 83.9604 5.17757 84.4653 5.48314C84.9701 5.78871 85.3621 6.22713 85.6377 6.7818C85.9101 7.33315 86.0463 7.97749 86.0463 8.69823C86.0463 9.41897 85.9101 10.0666 85.6377 10.6147Z'
			fill='white'
		/>
		<Path
			d='M96.8341 12.0628C96.3625 12.2621 95.8609 12.3617 95.3495 12.3617C94.6985 12.3617 94.1172 12.2056 93.6157 11.9C93.1108 11.5945 92.7189 11.156 92.4432 10.598C92.1709 10.05 92.0347 9.409 92.0347 8.6949C92.0347 7.9808 92.1709 7.3431 92.4432 6.79175C92.7156 6.23708 93.1108 5.79866 93.6157 5.49309C94.1172 5.18753 94.7018 5.03142 95.3495 5.03142C96.2628 5.03142 97.0633 5.35027 97.7276 5.97801L99.003 4.60628C97.9634 3.56005 96.7145 3.03195 95.2897 3.03195C94.2468 3.03195 93.3068 3.26777 92.4964 3.73608C91.6893 4.20108 91.0483 4.86867 90.5966 5.72559C90.1415 6.58583 89.9124 7.58224 89.9124 8.69822C89.9124 9.8142 90.1382 10.8106 90.5833 11.6642C91.025 12.5145 91.6627 13.1821 92.4765 13.6504C93.2902 14.122 94.2534 14.3612 95.3328 14.3612C96.8109 14.3612 98.0962 13.8298 99.1557 12.7802L97.9434 11.3288C97.6213 11.641 97.2493 11.8901 96.8308 12.0628H96.8341Z'
			fill='white'
		/>
		<Path
			d='M105.37 8.58861L109.611 3.29102H107.037L103.39 7.96087V3.29102H101.301V14.1054H103.39V9.39239L107.27 14.1054H110L105.37 8.58861Z'
			fill='white'
		/>
	</Svg>
);

export const CoverPage = ({ client }: { client: string }) => (
	<Page size='A4' style={styles.coverPage}>
		<Image style={styles.backgroundImage} src='/trucks-3.jpg' />
		<View style={styles.innerContainer}>
			<View>
				<Text style={styles.companyNameText}>Risk Protection S.A.</Text>
				<Text style={styles.documentTitleText}>Informe de Inspección</Text>
				<Text style={styles.additionalInfoText}>{moment().format('DD-MM-YYYY HH:mm:ss')}</Text>
				<Text style={styles.additionalInfoText}>{client}</Text>
			</View>
			<View style={styles.bottomContainer}>
				<Text>Powered by</Text>
				<BummockWhiteLogo />
			</View>
		</View>
	</Page>
);
