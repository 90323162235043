import { CardDropDown } from '@molecules';
import { IcUserCheck } from '@atoms';

import { IRolesCheckListProps } from './RolesCheckList.interfaces';
import { useRoles } from '@hooks';

export const RolesCheckList = ({ onChangeSelectedRole = () => {}, selectedRoleId }: IRolesCheckListProps) => {
	const { data: categorizedRoles, isLoading, isError } = useRoles();

	if (isLoading) return <Skeleton />;

	if (isError) return 'Some error ocurred';

	const selectedRole = categorizedRoles!.flatMap(category => category.role).find(role => role.id === selectedRoleId);

	const handleSelectRole = (itemId: string) => {
		onChangeSelectedRole(itemId);
	};

	return (
		<>
			<div>
				{!!selectedRole && (
					<div className='flex gap-[2.25rem] mb-8 py-4 px-8 rounded-2xl border-[5px] border-success-300 bg-success-100'>
						<div>
							<header className='text-success-500 font-bold text-xl mb-2'>
								Rol Asignado: {selectedRole.name}
							</header>
							<p>{selectedRole.description}</p>
						</div>
						<IcUserCheck className='stroke-success-500 stroke-[5] w-[2.875rem] shrink-0' />
					</div>
				)}

				<ul className='flex flex-col gap-y-4'>
					{categorizedRoles!
						.filter(category => category.role.length > 0)
						.map(category => {
							const roles = category.role.map(({ id, description, name }) => ({
								id,
								title: name,
								description: description,
								isSelected: selectedRoleId === id,
							}));
							return (
								<CardDropDown
									key={category.id}
									onCheckItem={handleSelectRole}
									title={category.name}
									description={category.description}
									options={roles}
								/>
							);
						})}
				</ul>
			</div>
		</>
	);
};

function Skeleton() {
	return Array.from({ length: 6 }).map((_, index) => (
		<div
			key={index}
			className='space-y-4 animate-pulse border-[5px] border-bummock-arctic_blue px-8 py-4 rounded-2xl'
		>
			<div className='w-full h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			<div className='w-1/3  h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			<div className='w-2/3  h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			<div className='w-full h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			<div className='w-2/3  h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
		</div>
	));
}
