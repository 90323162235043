import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import styles from './Devtools.module.sass';

const Devtools = () => {
	return (
		<div className={styles.devtools}>
			<ReactQueryDevtools />
		</div>
	);
};

export default Devtools;
