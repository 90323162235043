import { findAllInventory, QUERY_KEYS } from '@api';
import { Button, IcPlus, IcRestart } from '@atoms';
import { useTableQuery } from '@hooks';
import { IInventory } from '@interfaces';
import { BaseTable, ModalNewProduct, ModalUpdateStock, TablePagination, TableSearchbar } from '@organisms';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';

const columnHelper = createColumnHelper<IInventory>();
const inventoryColumns = [
	columnHelper.accessor('productType.name', {
		header: 'Tipo',
	}),
	columnHelper.accessor('description', {
		header: 'Descripción',
	}),
	columnHelper.accessor('category.name', {
		header: 'Categoría',
	}),
	columnHelper.accessor('createdAt', {
		header: 'Fecha Último Uso',
		cell: () => '-', // TODO: Un-hardcode this
	}),
	columnHelper.display({
		header: 'Cantidad',
		cell: ({ row }) => {
			const { stock, minStock } = row.original;
			return <span className={stock < minStock * 0.7 ? 'text-red-500' : ''}>{stock}</span>;
		},
	}),
];

const INITIAL_MODAL_STATE = {
	isModalVisible: false,
	productId: '',
};
const InventoryPage = () => {
	const [{ isModalVisible, productId }, setModalState] = useState(INITIAL_MODAL_STATE);
	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.inventory],
		fetcherFn: findAllInventory,
		tableColumns: [
			...inventoryColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { id } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcRestart
								onClick={() =>
									setModalState(prev => ({
										...prev,
										isModalVisible: true,
										productId: id,
									}))
								}
								className='hover:stroke-bummock-midnight_blue w-[1.125rem] cursor-pointer transition-all ease-in-out duration-200 stroke-bummock-disabled_grey_2 stroke-2'
							/>
							{/*
                            // Editing each of these items should redirect to another page
                            // Where we could see each of the registered variants
							<IcEdit
								onClick={() => console.log('Editing item with id', id)}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
                            */}
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	return (
		<>
			<ModalNewProduct
				isVisible={isModalVisible && !productId}
				onCloseModal={() =>
					setModalState(prev => ({
						...prev,
						isModalVisible: false,
					}))
				}
			/>
			<ModalUpdateStock
				productId={productId}
				isVisible={isModalVisible && !!productId}
				onCloseModal={() =>
					setModalState(prev => ({
						...prev,
						isModalVisible: false,
					}))
				}
			/>
			<div className='flex justify-between'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<Button
					onClick={() =>
						setModalState({
							...INITIAL_MODAL_STATE,
							isModalVisible: true,
						})
					}
				>
					<IcPlus className='stroke-2 stroke-white w-4' /> Agregar Producto
				</Button>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default InventoryPage;
