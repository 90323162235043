import { IFormEditCompanyInformationValues } from '@interfaces';
import { apiPrivate } from '../config/apiPrivate';

interface IUpdateCompanyParams
	extends Partial<Pick<IFormEditCompanyInformationValues, 'name' | 'identificationNumber' | 'email' | 'file'>> {
	id: string;
}

interface IUpdateCompanyResp {}

export const updateCompany = async ({ id, ...restParams }: IUpdateCompanyParams): Promise<IUpdateCompanyResp> => {
	let file: File | null = null;
	if (restParams.file instanceof FileList && restParams.file !== undefined) {
		file = restParams.file[0];
	}
	const { data } = await apiPrivate.patch<IUpdateCompanyResp>(
		`/api/companies/${id}`,
		{
			...restParams,
			...(file ? { file } : {}),
		},
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
	return data;
};
