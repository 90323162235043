import { findAllKitsTypes, QUERY_KEYS } from '@api';
import { Badge, Button, IcEdit, IcPlus, IcTrash } from '@atoms';
import { useAppSelector, useTableQuery } from '@hooks';
import { IKitType } from '@interfaces';
import { BaseTable, ModalNewKitType, ModalEditKitType, TablePagination, TableSearchbar } from '@organisms';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { canAccess } from '@utils';
import { PermissionWrapper } from '@wrappers';
import { useState } from 'react';

const columnHelper = createColumnHelper<IKitType>();
const kitsTypesColumns: ColumnDef<IKitType, string>[] = [
	{
		header: 'Tipo de Kit',
		accessorKey: 'name',
	},
	{
		header: 'Uso del Kit',
		accessorKey: 'usage',
	},
	{
		header: 'Fecha de creación',
		accessorKey: 'creationDate',
	},
	{
		header: 'Creado por',
		accessorKey: 'createdBy',
	},
	columnHelper.accessor('status', {
		header: 'Estado',
		cell: columnDefBase => <Badge label={columnDefBase.getValue()} variant='success' />,
		meta: { alignment: 'center' },
		enableSorting: false,
	}),
];

const INITIAL_MODAL_STATE = {
	isModalVisible: false,
	editingItemId: '',
};

const KitsTypesPage = () => {
	const permissions = useAppSelector(state => state.userProfile.permissions);
	const shouldShowActions = canAccess({ module: 'KITS_TYPES', minimumPermission: 'EDIT', permissions });

	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.kitsTypes],
		fetcherFn: findAllKitsTypes,
		tableColumns: [
			...kitsTypesColumns,
			...(shouldShowActions
				? [
						columnHelper.display({
							header: 'Acciones',
							cell: ({ row }) => {
								const { id } = row.original;
								return (
									<div className='flex justify-end gap-4'>
										<PermissionWrapper module='KITS_TYPES' minimumPermission='EDIT'>
											<IcEdit
												onClick={() =>
													setModalState(prev => ({
														...prev,
														isModalVisible: true,
														editingItemId: id,
													}))
												}
												className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
											/>
										</PermissionWrapper>
										<PermissionWrapper module='KITS_TYPES' minimumPermission='DELETE'>
											<IcTrash
												onClick={() => console.log('Clicked item with id', id)}
												className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
											/>
										</PermissionWrapper>
									</div>
								);
							},
							meta: { alignment: 'right' },
						}),
					]
				: []),
		],
	});
	const [{ isModalVisible, editingItemId }, setModalState] = useState(INITIAL_MODAL_STATE);

	return (
		<>
			<ModalNewKitType
				isVisible={isModalVisible && !editingItemId}
				onCloseModal={() =>
					setModalState(prev => ({
						...prev,
						isModalVisible: false,
					}))
				}
			/>
			<ModalEditKitType
				editingItemId={editingItemId}
				isVisible={isModalVisible && !!editingItemId}
				onCloseModal={() =>
					setModalState(prev => ({
						...prev,
						isModalVisible: false,
					}))
				}
			/>

			<div className='flex justify-between'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<PermissionWrapper module='KITS_TYPES' minimumPermission='CREATE'>
					<Button
						onClick={() =>
							setModalState({
								...INITIAL_MODAL_STATE,
								isModalVisible: true,
							})
						}
					>
						<IcPlus className='stroke-2 stroke-bummock-off_white w-5' />
						Crear Tipo
					</Button>
				</PermissionWrapper>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default KitsTypesPage;
