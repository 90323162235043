import { Page, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';

const styles = StyleSheet.create({
	page: {
		padding: '16px',
		paddingBottom: '48.61px',

		display: 'flex',
		flexDirection: 'column',
		gap: '8px',
	},
	pageNumberText: {
		position: 'absolute',
		bottom: '16px',
		left: '0',
		right: '0',
		textAlign: 'center',

		lineHeight: '0',
		zIndex: 100,
	},
	poweredByText: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '8px',

		position: 'absolute',
		right: '16px',
		bottom: '16px',
	},
});

const BummockIsotype = () => (
	<Svg style={{ height: '16.61px', width: '16.61px' }}>
		<Path
			d='M9.34424 5.67413H5.19252L5.9664 6.96282L6.355 7.42117C6.37825 7.4411 6.41479 7.47099 6.45797 7.50753C6.87978 7.85295 6.94621 7.91938 6.98939 8.00574L6.99603 8.01902L8.03562 10.3905L8.9025 11.7224L8.93239 11.6825L9.25457 9.73285C9.26785 9.67307 9.26785 9.60996 9.25457 9.55018L9.04532 8.49398C9.03868 8.45744 9.05528 8.42423 9.0885 8.4043L10.2842 7.7666C10.3373 7.74003 10.3573 7.68024 10.334 7.6271L9.34424 5.67081V5.67413Z'
			fill='#282828'
		/>
		<Path
			d='M15.4423 0.393127H1.95085C1.09062 0.393127 0.393127 1.09062 0.393127 1.95085V15.4423C0.393127 16.3025 1.09062 17 1.95085 17H15.4423C16.3025 17 17 16.3025 17 15.4423V1.95085C17 1.09062 16.3025 0.393127 15.4423 0.393127ZM13.9211 5.47151L11.7257 9.39406C11.6659 9.50035 11.5795 9.59334 11.4732 9.65977L10.985 9.96534C10.8654 10.2875 10.5931 11.0215 10.5034 11.2341C10.4802 11.2906 10.4503 11.3437 10.4104 11.3935L9.12171 13.0908C9.02871 13.2502 8.856 13.3565 8.66336 13.3664C8.65339 13.3664 8.64343 13.3664 8.63346 13.3664C8.4375 13.3664 8.25815 13.2668 8.15519 13.104L7.00931 11.3404C6.98938 11.3072 6.96946 11.274 6.95285 11.2374L5.75051 8.49065C5.66415 8.40429 5.40509 8.19504 5.30212 8.10869C5.24566 8.06219 5.20248 8.02565 5.17259 8.0024C5.1427 7.97583 5.11613 7.94926 5.08956 7.91937L4.63121 7.38131C4.60463 7.35141 4.58138 7.3182 4.56146 7.28499L3.47869 5.48812C3.37572 5.32205 3.3724 5.11945 3.46872 4.94674C3.56836 4.7707 3.75768 4.6611 3.96361 4.6611H13.4329C13.6355 4.6611 13.8248 4.76738 13.9244 4.94341C14.0207 5.1128 14.0207 5.31209 13.9244 5.48148L13.9211 5.47151Z'
			fill='#282828'
		/>
	</Svg>
);

export const NumberedContentPage = ({ children }: { children: ReactNode }) => (
	<Page size='A4' style={styles.page}>
		{children}
		<Text fixed style={styles.pageNumberText} render={({ pageNumber }) => `Pag ${pageNumber}`} />
		<View fixed style={styles.poweredByText}>
			<Text>Powered by</Text>
			<BummockIsotype />
		</View>
	</Page>
);
