import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { Button, IcDollarSign } from '@atoms';
import { FormInput, FormSearchableDropdown } from '@molecules';
import { Modal } from '@organisms';
import { generateLastCode } from '@utils';
import { useSuppliers } from '@hooks';
import { updateStock } from '@api';
import { IModalProps } from '@interfaces';

interface IModalUpdateStockProps extends IModalProps {
	productId: string;
}

interface IFormUpdateStockValues {
	supplier: string;
	serialBegin: string;
	quantity: string;
	color: string;
	distinctive: string;
	costPerUnit: string;
}

export const ModalUpdateStock = ({ productId, onCloseModal, isVisible }: IModalUpdateStockProps) => {
	const { data: suppliersResponse } = useSuppliers();
	const {
		formState: { errors, isValid },
		register,
		handleSubmit: handleSubmitWrapper,
		reset,
		control,
		watch,
	} = useForm<IFormUpdateStockValues>({
		defaultValues: {
			quantity: '',
			serialBegin: '',
			supplier: '',
			color: '',
			distinctive: '',
			costPerUnit: '',
		},
	});

	useEffect(() => {
		isVisible && reset();
	}, [isVisible]);

	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationKey: ['categories'],
		mutationFn: updateStock,
		onSuccess: () => {
			onCloseModal();
			queryClient.invalidateQueries({ queryKey: ['inventory'] });
		},
	});
	const handleSubmit = ({
		quantity,
		serialBegin,
		supplier,
		color,
		distinctive,
		costPerUnit,
	}: IFormUpdateStockValues) => {
		mutation.mutate({
			productId,
			color,
			distinctive,
			supplier,
			price: costPerUnit,
			quantity: +quantity,
			startCode: serialBegin,
			endCode: serialEndFieldValue,
		});
	};

	const serialBeginFieldValue = watch('serialBegin');
	const quantityFieldValue = +watch('quantity');
	const serialEndFieldValue = generateLastCode(serialBeginFieldValue, quantityFieldValue);

	return (
		<Modal
			className='p-10 w-full max-w-[838px]'
			title='Actualización de Stock'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<form className='mt-8 flex flex-col gap-8' onSubmit={handleSubmitWrapper(handleSubmit)} autoComplete='off'>
				<Controller
					control={control}
					name='supplier'
					rules={{ required: { value: true, message: 'This field is required' } }}
					render={({ field: { ref, ...rest }, fieldState: { error } }) => (
						<FormSearchableDropdown
							includeEmptyOption={true}
							className='basis-0 grow'
							label='Proveedor'
							required={true}
							options={
								suppliersResponse?.data.map(supplier => ({
									id: supplier.id,
									value: supplier.name,
								})) ?? []
							}
							onError={!!error?.message}
							errorMessage={error?.message}
							{...rest}
						/>
					)}
				/>

				<div className='grid grid-cols-3 gap-8'>
					<FormInput
						className='basis-0 grow'
						register={register('color', {
							required: { value: true, message: 'This field is required' },
						})}
						required={true}
						errorMessage={errors.color?.message}
						onError={!!errors.color}
						label='Color'
						placeholder='Blue'
					/>
					<FormInput
						className='basis-0 grow'
						register={register('distinctive', {
							required: { value: true, message: 'This field is required' },
						})}
						required={true}
						errorMessage={errors.distinctive?.message}
						onError={!!errors.distinctive}
						label='Distintivo'
						placeholder='ABC'
					/>
					<FormInput
						icon={<IcDollarSign className='stroke-2 stroke-bummock-disabled_grey_2 w-3' />}
						register={register('costPerUnit', {
							required: { value: true, message: 'This field is required' },
						})}
						required={true}
						errorMessage={errors.costPerUnit?.message}
						onError={!!errors.costPerUnit}
						label='Costo por Unidad'
						placeholder='0'
					/>
				</div>

				<div className='grid grid-cols-3 gap-8'>
					{/* Validate this field using a regexp so that it always ends with a number */}
					<FormInput
						className='basis-0 grow'
						register={register('serialBegin', {
							required: { value: true, message: 'This field is required' },
						})}
						required={true}
						errorMessage={errors.serialBegin?.message}
						onError={!!errors.serialBegin}
						label='Inicio de Serie'
						placeholder='Serial'
					/>
					<FormInput
						className='basis-0 grow'
						register={register('quantity', {
							required: { value: true, message: 'This field is required' },
						})}
						required={true}
						errorMessage={errors.quantity?.message}
						onError={!!errors.quantity}
						label='Cantidad'
						placeholder='0'
					/>
					<FormInput
						placeholder='-'
						disabled={true}
						className='basis-0 grow'
						label='Fin de Serie'
						value={serialEndFieldValue}
					/>
				</div>

				<div className='flex gap-4'>
					<Button onClick={onCloseModal} type='button' variant='secondary' className='w-[9.375rem]'>
						Cancelar
					</Button>
					<Button isLoading={mutation.isPending} disabled={!isValid} type='submit' className='w-[9.375rem]'>
						Guardar
					</Button>
				</div>
			</form>
		</Modal>
	);
};
