import { useParams } from 'react-router-dom';
import { ModalConfirmation, RolesCheckList } from '@organisms';
import { useAppSelector, useConfirmation, useUser } from '@hooks';
import { assignRole, QUERY_KEYS, unassignRole } from '@api';
import { useQueryClient } from '@tanstack/react-query';

const ONE_WEEK_IN_MS = 6.048e8;

const changeRole = async ({
	companyId,
	currentRole,
	newRole,
	userId,
}: {
	userId: string;
	companyId: string;
	currentRole: string;
	newRole: string;
}) => {
	await unassignRole({
		id: userId,
		company: companyId,
		role: currentRole,
	});

	await assignRole({
		id: userId,
		company: companyId,
		role: newRole,
		dateExpire: Date.now() + ONE_WEEK_IN_MS + '',
	});
};

const RolesPage = () => {
	// Maybe abstract this into a userUserId custom hook
	const { id: selectedUser } = useParams();
	const { id: profileUserId, companies } = useAppSelector(state => state.userProfile);
	const userId = selectedUser ?? profileUserId;

	const { isLoading, isError, data: userData } = useUser(userId);
	const queryClient = useQueryClient();
	const { confirm, confirmationModalProps } = useConfirmation({
		mutationFn: changeRole,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.users, userId],
			});
		},
	});

	if (isLoading) return <Skeleton />;

	if (isError) return 'Some error ocurred while fetching user data';

	const userRole = userData!.role.id;
	const handleChangeRole = (roleId: string) => {
		confirm({
			userId,
			companyId: companies[0].id,
			newRole: roleId,
			currentRole: userRole,
		});
	};

	return (
		<>
			<ModalConfirmation {...confirmationModalProps} />

			<header>
				<h2 className='text-xl font-bold text-bummock-ocean_blue mb-1'>Roles y Permisos</h2>
				<p className='text-bummock-midnight_blue'>Asigna un rol predefinido según las funciones del usuario.</p>
			</header>

			<RolesCheckList onChangeSelectedRole={handleChangeRole} selectedRoleId={userRole} />
		</>
	);
};

function Skeleton() {
	return (
		<>
			<div className='flex flex-col gap-2'>
				<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
				<div className='animate-pulse w-1/3 h-4 shrink-0 bg-bummock-arctic_blue rounded'></div>
			</div>
			{Array.from({ length: 6 }).map((_, index) => (
				<div
					key={index}
					className='space-y-4 animate-pulse border-[5px] border-bummock-arctic_blue px-8 py-4 rounded-2xl'
				>
					<div className='w-full h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='w-1/3  h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='w-2/3  h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='w-full h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='w-2/3  h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				</div>
			))}
		</>
	);
}

export default RolesPage;
