import { IUsage } from '@interfaces';
import { getPaginatedResource, IPaginatedResourceParams, IPaginatedResourceResp } from '@api';
import moment from 'moment';

interface IUsageResponseEntity {
	id: string;
	createdAt: string;
	name: string;
	description: string;
	isActive: boolean;
	user: {
		firstName: string;
		lastName: string;
		image: string;
	};
}

export const findAllKitsUsages = async (params: IPaginatedResourceParams): Promise<IPaginatedResourceResp<IUsage>> => {
	const { data } = await getPaginatedResource<IUsageResponseEntity>('/api/kit-usage', params);

	const mappedData: IUsage[] = data.data.map(({ id, name, description, createdAt, isActive, user }) => ({
		id,
		name,
		description,
		createdBy: `${user.firstName} ${user.lastName}`,
		creationDate: moment.utc(createdAt).format('DD/MM/YYYY'),
		status: isActive ? 'Activo' : 'Desactivado',
	}));

	return {
		...data,
		data: mappedData,
	};
};
