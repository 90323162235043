import { findAllCourtyards, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

type TUseCourtyardsParams =
	| {
			by: 'user';
			userId: string;
	  }
	| {
			by: 'company';
			companyId: string;
	  };

export const useCourtyards = (params: TUseCourtyardsParams) => {
	const queryResult = useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: [QUERY_KEYS.courtyards, params.by === 'user' ? params.userId : params.companyId],
		queryFn: () =>
			params.by === 'user'
				? findAllCourtyards({ by: params.by, userId: params.userId })
				: findAllCourtyards({ by: params.by, companyId: params.companyId }),
	});

	return queryResult;
};
