import { findAllLocationsTypes, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

export const useLocationsTypes = () => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.locationsTypes],
		queryFn: findAllLocationsTypes,
		initialData: [],
	});

	return queryResult;
};
