import { getPaginatedResource, IPaginatedResourceParams } from '@api';
import { IBulkDispatchDetails } from '@interfaces';

export const findBulkDispatchContent = async ({
	dispatchId,
	...params
}: IPaginatedResourceParams & { dispatchId: string }) => {
	const { data } = await getPaginatedResource<IBulkDispatchDetails>(`/api/dispatches/bulk/${dispatchId}`, params);

	return data;
};
