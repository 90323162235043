import { apiPrivate } from '../config/apiPrivate';

interface ICreateKitsUsageParams {
	name: string;
	description: string;
	company: string;
	user: string;
}

interface ICreateKitsUsageResp {}

export const createKitsUsage = async (params: ICreateKitsUsageParams): Promise<ICreateKitsUsageResp> => {
	const { data } = await apiPrivate.post<ICreateKitsUsageResp>('/api/kit-usage', params);
	return data;
};
