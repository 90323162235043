import { useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, ButtonGoBack, Card, DashedContainer, IcRestart } from '@atoms';
import { FormSearchableDropdown } from '@molecules';
import { ModalReturnItem } from '@organisms';
import { useAppSelector, useKitDetails } from '@hooks';
import moment from 'moment';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS, returnKitContent } from '@api';

const returnOptions = [
	{ id: 'DAMAGED', value: 'Dañado' },
	{
		id: 'WRONG_ITEM',
		value: 'Item incorrecto',
	},
];

interface InputPanelProps {
	isKitAlreadyReturned: boolean;
	panelName: string;
	control: Control;
	isReturnMode: boolean;
	securityItems: {
		id: string;
		value: string;
	}[];
	onReturnItem: (item: string) => void;
}

const InputPanel = ({
	isKitAlreadyReturned,
	control,
	panelName,
	isReturnMode,
	onReturnItem,
	securityItems,
}: InputPanelProps) => {
	return (
		<div>
			<h2 className='text-bummock-ocean_blue text-xl font-bold'>{panelName}</h2>
			<div className='grid grid-cols-3 gap-4'>
				{securityItems.map((securityItem, index) => (
					<div className='flex flex-col gap-4' key={index}>
						<DashedContainer className='flex items-center justify-between mt-4 px-3'>
							{/* Hardcoded for now */}
							{securityItem.value}
							{!isKitAlreadyReturned && (
								<span
									className='group hover:cursor-pointer w-7 h-7 flex justify-center items-center'
									onClick={() => onReturnItem(securityItem.value)}
								>
									<IcRestart className='group-hover:rotate-90 transition-transform stroke-2 w-[17px] stroke-bummock-midnight_blue' />
								</span>
							)}
						</DashedContainer>
						{isReturnMode && (
							<Controller
								control={control}
								name={`${securityItem.id}.${index}.value`}
								defaultValue=''
								render={({ field: { ref, ...rest } }) => (
									<FormSearchableDropdown
										includeEmptyOption={true}
										label='Razón'
										options={returnOptions}
										{...rest}
									/>
								)}
							/>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

// TODO: Move this to another directory
const Skeleton = () => {
	return (
		<>
			<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
			<div className='animate-pulse w-1/4 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>

			<div className='space-y-8 animate-pulse'>
				<div className='grid grid-cols-5 gap-4'>
					<div className='h-[7rem] bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-[7rem] bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-[7rem] bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-[7rem] bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-[7rem] bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				</div>
			</div>

			<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
			<div className='animate-pulse w-1/3 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>

			<div className='space-y-8 animate-pulse'>
				<div className='flex flex-col gap-4'>
					<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
					<div className='grid grid-cols-3 gap-4'>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					</div>
				</div>
				<div className='flex flex-col gap-4'>
					<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
					<div className='grid grid-cols-3 gap-4'>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					</div>
				</div>
				<div className='flex flex-col gap-4'>
					<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
					<div className='grid grid-cols-3 gap-4'>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					</div>
				</div>
				<div className='flex flex-col gap-4'>
					<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
					<div className='grid grid-cols-3 gap-4'>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					</div>
				</div>
				<div className='flex flex-col gap-4'>
					<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
					<div className='grid grid-cols-3 gap-4'>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
						<div className='h-9 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					</div>
				</div>
			</div>
		</>
	);
};

const KitContentPage = () => {
	const { id: userId } = useAppSelector(state => state.userProfile);
	const [{ isVisible: isModalVisible, selectedItem, selectedItemType }, setModalState] = useState({
		isVisible: false,
		selectedItem: '',
		selectedItemType: '',
	});

	const [isReturnMode, setIsReturnMode] = useState<boolean>(false);
	const navigator = useNavigate();
	const { id: kitId = '' } = useParams();

	const { isLoading: isLoadingKitDetails, isError: isErrorKitDetails, data: kitDetails } = useKitDetails(kitId);

	const { control, watch, reset: resetForm, handleSubmit: handleSubmitWrapper } = useForm();

	// TODO: This could be improved.
	const reasonInputsValues = Object.values(watch()).flat();
	const allFilled =
		reasonInputsValues.length > 0
			? reasonInputsValues.every((input: { value: string }) => input.value.length > 0)
			: false;

	const queryClient = useQueryClient();
	const returnKitMutation = useMutation({
		mutationFn: returnKitContent,
		onSuccess: () => {
			resetForm();
			setIsReturnMode(false);
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.kitDetails, kitId],
			});
		},
	});

	const handleClickCancel = () => {
		resetForm();
		setIsReturnMode(false);
	};

	const handleSubmit = (values: Record<string, ({ value: string } | undefined)[]>) => {
		console.log(values);
		const fixedValues = Object.entries(values).map(([key, values]) => ({
			id: key,
			reason: values.filter(value => value?.value)[0]?.value ?? 'Damaged',
		}));
		console.log(fixedValues);
		returnKitMutation.mutate({
			kitId,
			user: userId,
			items: fixedValues,
		});
	};

	if (isLoadingKitDetails) {
		return (
			<>
				<ButtonGoBack onClick={() => navigator('../')} />
				<Skeleton />
			</>
		);
	}

	if (isErrorKitDetails) return <p className='text-red-500'>An error ocurred while fetching the data.</p>;

	return (
		<>
			<ModalReturnItem
				selectedItem={selectedItem}
				itemType={selectedItemType}
				isVisible={isModalVisible}
				onCloseModal={() => setModalState(prev => ({ ...prev, isVisible: false }))}
			/>
			<ButtonGoBack onClick={() => navigator('../')} />

			<div>
				<h2 className='text-xl font-bold text-bummock-ocean_blue mb-4'>Kit Creado</h2>
				<p>Información relacionada con el Kit creado.</p>
			</div>

			{!isLoadingKitDetails && !isErrorKitDetails && !!kitDetails && (
				<div className='grid grid-cols-5 gap-8'>
					<Card title='Código' value={kitDetails.kit} />
					<Card title='Nombre de Kit' value={kitDetails.kitType.name} />
					<Card title='Fecha de creación' value={moment.utc(kitDetails.createdAt).format('DD/MM/YYYY')} />
					<Card title='Creado por' value={`${kitDetails.user.firstName} ${kitDetails.user.lastName}`} />
					<Card
						title='Estado'
						value={
							<Badge
								variant={kitDetails.status === 'RETURNED' ? 'warning' : 'success'}
								label={
									kitDetails.status === 'ACTIVE'
										? 'Asignado'
										: kitDetails.status === 'RETURNED'
											? 'Retornado'
											: kitDetails.status
								}
							/>
						}
					/>
				</div>
			)}

			<div className='flex justify-between items-center'>
				<div>
					<h2 className='text-xl font-bold text-bummock-ocean_blue mb-4'>Contenido del Kit</h2>
					<p>
						Detalle del contenido del Kit. Puedes devolver el Kit al inventario o reemplazar productos
						individuales.
					</p>
				</div>

				<Button
					disabled={kitDetails?.status === 'RETURNED' || isReturnMode}
					onClick={() => setIsReturnMode(prev => !prev)}
					className='px-6'
				>
					Return Kit
				</Button>
			</div>

			{isLoadingKitDetails && 'Loading...'}
			{!isLoadingKitDetails && !isErrorKitDetails && !!kitDetails && (
				<form onSubmit={handleSubmitWrapper(handleSubmit)} className='flex flex-col gap-8'>
					{kitDetails.kitDetail.map(securityItemType => (
						<InputPanel
							isKitAlreadyReturned={kitDetails.status === 'RETURNED'}
							key={securityItemType.securityItemType}
							control={control}
							panelName={securityItemType.securityItemType}
							securityItems={securityItemType.items}
							isReturnMode={isReturnMode}
							onReturnItem={selectedItem =>
								setModalState(prev => ({
									...prev,
									isVisible: true,
									selectedItemType: securityItemType.securityItemType,
									selectedItem,
								}))
							}
						/>
					))}

					<div className={isReturnMode ? 'flex gap-4' : 'hidden'}>
						<Button type='button' onClick={handleClickCancel} className='px-10' variant='secondary'>
							Cancelar
						</Button>
						<Button
							isLoading={returnKitMutation.isPending}
							type='submit'
							disabled={!allFilled}
							className='px-10'
						>
							Return
						</Button>
					</div>
				</form>
			)}
		</>
	);
};

export default KitContentPage;
