import { CardDropDown } from '@molecules';
import { ROLES_AND_PERMISSIONS } from '../../../dummy_data/roles-and-permissions';
import { Button } from '@atoms';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

interface IFormValues {
	name: string;
}

const NewCustomRolePage = () => {
	const navigator = useNavigate();

	const [checkedRoles, setcheckedRoles] = useState<string[]>([]);
	const handleChangeCheckedItem = (itemId: string, isChecked: boolean) => {
		setcheckedRoles(prev => (isChecked ? [...prev, itemId] : prev.filter(item => item !== itemId)));
	};

	const {
		formState: { errors },
		register,
		handleSubmit: handleSubmitWrapper,
	} = useForm<IFormValues>();

	const handleSubmit = ({ name }: IFormValues) => {
		// TODO: Send this data to the backend
		console.log('Creating role with data', {
			name,
			roles: checkedRoles,
		});
		navigator('../');
	};

	return (
		<>
			<header>
				<h2 className='text-xl font-bold text-bummock-ocean_blue mb-1'>Name your custom role</h2>
				<p className='text-bummock-midnight_blue'>
					Invite your team to collaborate with you based on the role and permission you choose for them.
				</p>
				<form onSubmit={handleSubmitWrapper(handleSubmit)} className='flex gap-4 mt-8' role='form'>
					<input
						{...register('name', {
							required: { value: true, message: 'This field is required' },
							minLength: { value: 5, message: 'Min length is 5' },
							maxLength: { value: 50, message: 'Max length is 50' },
						})}
						data-has-error={!!errors.name}
						placeholder='Ej: Analista administrativo'
						className='w-[31.25rem]'
						type='text'
					/>
					<Button disabled={!!errors.name || !checkedRoles.length}>Guardar</Button>
				</form>
			</header>
			<div>
				<h2 className='text-xl font-bold text-bummock-ocean_blue mb-1'>Roles & Permisos</h2>
				<p className='text-bummock-midnight_blue'>
					Select the roles and permissions that you would like to be a part of your new custom role.
				</p>
			</div>
			<ul className='flex flex-col gap-y-4'>
				{ROLES_AND_PERMISSIONS.map((item, index) => (
					<CardDropDown onCheckItem={handleChangeCheckedItem} key={index} {...item} />
				))}
			</ul>
		</>
	);
};

export default NewCustomRolePage;
