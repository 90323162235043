import { apiPrivate } from '../config/apiPrivate';

interface IUpdateKitUsageParams {
	id: string;
	name?: string;
	description?: string;
}

interface IUpdateKitUsageResp {}

export const updateKitsUsage = async ({ id, ...restParams }: IUpdateKitUsageParams): Promise<IUpdateKitUsageResp> => {
	const { data } = await apiPrivate.patch<IUpdateKitUsageResp>(`/api/kit-usage/${id}`, restParams);
	return data;
};
