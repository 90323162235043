import { useState, useEffect, useCallback, type Dispatch, type SetStateAction } from 'react';

export function useDebouncedState<T>(
	initialState: T,
	onDebounce: (resultingString: T) => void,
	delayMs: number
): [T, Dispatch<SetStateAction<T>>] {
	const [value, setValue] = useState<T>(initialState);
	const memoizedOnDebounce = useCallback(onDebounce, []);

	useEffect(() => {
		const timerId = setTimeout(() => {
			memoizedOnDebounce(value);
		}, delayMs);

		return () => {
			if (timerId) clearTimeout(timerId);
		};
	}, [value, memoizedOnDebounce, delayMs]);

	return [value, setValue];
}
