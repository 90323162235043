import type { Ref } from 'react';

export const Isotype = ({ mRef, className = '' }: { className?: string; mRef?: Ref<SVGSVGElement> }) => {
	return (
		<svg
			ref={mRef}
			className={className}
			xmlns='http://www.w3.org/2000/svg'
			width='32'
			height='32'
			viewBox='0 0 32 32'
			fill='none'
		>
			<path
				d='M17.0591 10.7437H9.14673L10.6216 13.198L11.3622 14.0709C11.4065 14.1089 11.4761 14.1658 11.5584 14.2354C12.3623 14.8933 12.4889 15.0198 12.5712 15.1842L12.5839 15.2096L14.5651 19.726L16.2172 22.2626L16.2742 22.1867L16.8882 18.4736C16.9135 18.3597 16.9135 18.2395 16.8882 18.1256L16.4894 16.1141C16.4768 16.0445 16.5084 15.9813 16.5717 15.9433L18.8505 14.7288C18.9518 14.6782 18.9897 14.5643 18.9454 14.4631L17.0591 10.7374V10.7437Z'
				fill='#282828'
			/>
			<path
				d='M28.6808 0.686035H2.96873C1.32928 0.686035 0 2.01441 0 3.65273V29.3472C0 30.9856 1.32928 32.3139 2.96873 32.3139H28.6808C30.3203 32.3139 31.6496 30.9856 31.6496 29.3472V3.65273C31.6496 2.01441 30.3203 0.686035 28.6808 0.686035ZM25.7817 10.3578L21.5977 17.8284C21.4837 18.0308 21.3192 18.2079 21.1166 18.3344L20.1861 18.9164C19.9582 19.5299 19.4392 20.9279 19.2683 21.3327C19.224 21.4403 19.167 21.5415 19.091 21.6364L16.635 24.8687C16.4578 25.1724 16.1286 25.3748 15.7615 25.3938C15.7425 25.3938 15.7235 25.3938 15.7045 25.3938C15.3311 25.3938 14.9892 25.204 14.793 24.894L12.6092 21.5352C12.5712 21.4719 12.5332 21.4086 12.5016 21.3391L10.2102 16.1078C10.0456 15.9433 9.55184 15.5448 9.35561 15.3804C9.24801 15.2918 9.16572 15.2222 9.10875 15.1779C9.05178 15.1273 9.00114 15.0767 8.9505 15.0198L8.07697 13.9951C8.02633 13.9381 7.98202 13.8749 7.94404 13.8116L5.88049 10.3895C5.68426 10.0732 5.67793 9.68734 5.8615 9.35841C6.0514 9.02315 6.4122 8.81441 6.80466 8.81441H24.8512C25.2374 8.81441 25.5982 9.01683 25.7881 9.35208C25.9716 9.67469 25.9716 10.0542 25.7881 10.3768L25.7817 10.3578Z'
				fill='#282828'
			/>
		</svg>
	);
};
