import type { FC } from 'react';

import type { ICardProps } from './Card.interfaces';

export const Card: FC<ICardProps> = ({ title, value }) => (
	<div className='flex flex-col items-center justify-center gap-4 shadow-bummock_out rounded-2xl p-4'>
		<h3 className='text-center text-xl text-bummock-midnight_blue font-bold'>{title}</h3>
		<p className='text-bummock-midnight_blue'>{value}</p>
	</div>
);
