import { findAvailableInventory, QUERY_KEYS } from '@api';
import { IAvailableInventory, IBulkDispatchItem } from '@interfaces';
import { createColumnHelper, RowData } from '@tanstack/react-table';
import type { Dispatch } from 'react';
import { BaseTable, TablePagination, TableSearchbar } from '../BaseTable/BaseTable';
import { useTableQuery } from '@hooks';
import { Counter } from '@atoms';

const columnHelper = createColumnHelper<IAvailableInventory>();
const tableColumns = [
	columnHelper.accessor('code', {
		header: 'Código',
	}),
	columnHelper.accessor('name', {
		header: 'Nombre',
	}),
	columnHelper.accessor('serialBegin', {
		header: 'Inicio de Serie',
	}),
	columnHelper.accessor('serialEnd', {
		header: 'Fin de Serie',
	}),
	columnHelper.accessor('available', {
		header: 'Disponible',
		cell: ({ row }) => {
			const { available } = row.original;
			return <span className={available < 500 ? 'text-red-500' : ''}>{available}</span>;
		},
		meta: { alignment: 'center' },
	}),
	columnHelper.display({
		header: 'Cantidad',
		cell: ({ row, table }) => {
			const { setSelectedItems, selectedItems } = table.options.meta!;

			const { id, name, available, serialBegin, serialEnd } = row.original;
			return (
				<Counter
					disabled={available === 0}
					id={id}
					onCount={value =>
						setSelectedItems(prev => {
							const stateClone = [...prev];

							const foundIndex = prev.findIndex(counter => counter.id === id);
							const wasFound = foundIndex !== -1;

							if (wasFound) {
								prev[foundIndex].quantity = value;
							} else {
								stateClone.push({
									id,
									name,
									quantity: value,
									code: id.slice(-6),
									serialBegin,
									serialEnd,
								});
							}

							return stateClone;
						})
					}
					count={selectedItems.find(counter => counter.id === id)?.quantity ?? '0'}
					availableStock={available}
				/>
			);
		},
		meta: { alignment: 'center' },
	}),
];

interface ITableInventoryDispatchProps {
	selectedItems: IBulkDispatchItem[];
	setSelectedItems: Dispatch<(items: IBulkDispatchItem[]) => IBulkDispatchItem[]>;
}

declare module '@tanstack/react-table' {
	interface TableMeta<TData extends RowData> {
		selectedItems: ITableInventoryDispatchProps['selectedItems'];
		setSelectedItems: ITableInventoryDispatchProps['setSelectedItems'];
	}
}

export const TableInventoryDispatch = ({ selectedItems, setSelectedItems }: ITableInventoryDispatchProps) => {
	const tableQueryResult = useTableQuery({
		queryKey: [`${QUERY_KEYS.inventory}-available`],
		fetcherFn: findAvailableInventory,
		tableColumns,
		meta: {
			selectedItems,
			setSelectedItems,
		},
	});

	return (
		<>
			<div className='grid grid-cols-4 gap-y-4 gap-x-8 items-end'>
				<TableSearchbar className='w-auto' tableQueryResult={tableQueryResult} />
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};
