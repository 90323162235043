import { apiPrivate } from '../config/apiPrivate';

interface IGetOneEntityParams {
	entityId: string;
}

interface IGetOneEntityResp {
	id: string;
	createdAt: string;
	updatedAt: string;
	deleteAt: string;
	status: string;
	clientCompany: {
		id: string;
		createdAt: string;
		updatedAt: string;
		deleteAt: string;
		name: string;
		identificationNumber: string;
		email: string;
		path: string;
		yardCount: number;
		farmCount: number;
		shippingLineCount: number;
		companyCount: number;
		numberOfCreatedUsers: number;
		isEntity: boolean;
		isActive: boolean;
	};
	viewerUser: {
		id: string;
		firstName: string;
		lastName: string;
		phoneNumber: string;
		email: string;
		city: {
			id: string;
			name: string;
		};
		image: string;
	};
}

export const getOneEntity = async (params: IGetOneEntityParams): Promise<IGetOneEntityResp> => {
	const { data } = await apiPrivate.get<IGetOneEntityResp>(`/api/entities/${params.entityId}`);
	return data;
};
