import { IFormEditEntityValues } from '@interfaces';
import { apiPrivate } from '../config/apiPrivate';

interface IUpdateEntityParams extends Partial<Pick<IFormEditEntityValues, 'name' | 'identificationNumber'>> {
	id: string;
}

interface IUpdateEntityResp {}

export const updateEntity = async ({ id, ...restParams }: IUpdateEntityParams): Promise<IUpdateEntityResp> => {
	const { data } = await apiPrivate.patch<IUpdateEntityResp>(`/api/entities/${id}`, {
		...restParams,
	});
	return data;
};
