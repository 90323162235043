import { setUserImage } from '@RTK/Slices';
import { getUserProfile, updateProfilePicture } from '@api';
import { useAppDispatch, useAppSelector, useUser } from '@hooks';
import { FormDeleteUser, FormEditUserInformation, ImagePicker } from '@organisms';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

const EditInformationPage = () => {
	const dispatcher = useAppDispatch();
	const { id: userId } = useParams();
	const isEditingUserFromTable = !!userId;
	const { id: profileUserId } = useAppSelector(state => state.userProfile);
	const editingUserId = isEditingUserFromTable ? userId : profileUserId;

	const queryClient = useQueryClient();
	const { data: userData, isLoading, isError } = useUser(userId ?? profileUserId);
	const mutation = useMutation({
		mutationFn: updateProfilePicture,
		onSuccess: async () => {
			if (isEditingUserFromTable && profileUserId !== userId) return;
			// Retrieve image from current user and update global state
			const { image } = await getUserProfile();
			console.log(image);
			dispatcher(setUserImage(image));
			queryClient.invalidateQueries({
				queryKey: ['user', profileUserId],
			});
		},
	});

	const handleChangeProfilePicture = (picture: File) => {
		mutation.mutate({
			userId: editingUserId,
			file: picture,
		});
	};

	if (isLoading) return <Skeleton />;

	if (isError) return <p className='text-red-500'>Some error ocurred while fetching user data</p>;

	return (
		<>
			<h2 className='text-xl font-bold text-bummock-ocean_blue'>Detalles del perfil</h2>
			<ImagePicker
				onChange={e => handleChangeProfilePicture(e!.target.files![0])}
				placeHolderImage={userData!.image}
			/>
			<hr />
			<FormEditUserInformation
				editingUser={{
					id: editingUserId,
					firstName: userData!.firstName,
					lastName: userData!.lastName,
					email: userData!.email,
					// company: userData!.companies?.[0]?.name,
					phoneNumber: userData!.phoneNumber,
					dni: userData!.dni,
					city: userData!.city.id,
					age: '0',
				}}
			/>
			{isEditingUserFromTable ? (
				<>
					<hr />
					<h2 className='text-xl font-bold text-bummock-ocean_blue'>Borrar Usuario</h2>
					<FormDeleteUser />
				</>
			) : null}
		</>
	);
};

function Skeleton() {
	return (
		<>
			<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
			<div className='rounded-md max-w-md w-full'>
				<div className='animate-pulse flex space-x-4 items-end'>
					<div className='rounded-lg bg-bummock-arctic_blue opacity-50 h-[100px] w-[100px]'></div>
					<div className='flex-1 space-y-6 py-1'>
						<div className='h-2 bg-bummock-arctic_blue opacity-50 rounded'></div>
						<div className='space-y-3'>
							<div className='grid grid-cols-3 gap-4'>
								<div className='h-2 bg-bummock-arctic_blue opacity-50 rounded col-span-2'></div>
								<div className='h-2 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
							</div>
							<div className='h-2 bg-bummock-arctic_blue opacity-50 rounded'></div>
						</div>
					</div>
				</div>
			</div>

			<hr />

			<div className='space-y-8 animate-pulse'>
				<div className='grid grid-cols-4 gap-4'>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				</div>
				<div className='grid grid-cols-4 gap-4'>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				</div>
				<div className='grid grid-cols-4 gap-4'>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
					<div className='h-7 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				</div>
			</div>
			<div className='space-y-4 animate-pulse'>
				<div className='w-1/5  h-6 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			</div>

			<hr />

			<div className='animate-pulse w-1/6 h-6 shrink-0 bg-bummock-arctic_blue rounded'></div>
			<div className='space-y-4 animate-pulse'>
				<div className='w-1/3 h-6 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				<div className='w-1/5  h-6 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
				<div className='w-1/3 h-6 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			</div>
			<div className='space-y-4 animate-pulse'>
				<div className='w-1/5  h-6 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			</div>
		</>
	);
}

export default EditInformationPage;
