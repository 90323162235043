import { Outlet } from 'react-router-dom';

const SealsLayout = () => {
	return (
		<>
			<nav className='mt-8 flex gap-8 overflow-auto flex-shrink-0'>
				<div className='bg-bummock-arctic_blue w-[20.75rem] p-4 rounded-lg bg-[url("/hand-1.png")] bg-right-top bg-[length:130px] bg-no-repeat h-[7.625rem] cursor-pointer shrink-0'>
					<div className='w-[13.75rem]'>
						<h3 className='text-xl font-bold text-bummock-midnight_blue mb-2'>Sellos Encriptados</h3>
						<p className='text-sm'>
							Encriptación de sellos NFC <strong>BUMMOCK</strong>®
						</p>
					</div>
				</div>
				<div className='shadow-bummock w-[20.75rem] p-4 rounded-lg bg-[url("/hand-2.png")] bg-[120%_45%] bg-[length:200px] bg-no-repeat h-[7.625rem] cursor-pointer hover:bg-bummock-disabled_grey transition-colors duration-200 shrink-0'>
					<div className='w-[13.75rem]'>
						<h3 className='text-xl font-bold text-bummock-midnight_blue mb-2'>Encriptación</h3>
						<p className='text-sm'>
							Encriptación de sellos NFC <strong>BUMMOCK</strong>®
						</p>
					</div>
				</div>
				<div className='shadow-bummock w-[20.75rem] p-4 rounded-lg bg-[url("/hand-2.png")] bg-[120%_45%] bg-[length:200px] bg-no-repeat h-[7.625rem] cursor-pointer hover:bg-bummock-disabled_grey transition-colors duration-200 shrink-0'>
					<div className='w-[13.75rem]'>
						<h3 className='text-xl font-bold text-bummock-midnight_blue mb-2'>Encriptación</h3>
						<p className='text-sm'>
							Encriptación de sellos NFC <strong>BUMMOCK</strong>®
						</p>
					</div>
				</div>
				<div className='shadow-bummock w-[20.75rem] p-4 rounded-lg bg-[url("/hand-2.png")] bg-[120%_45%] bg-[length:200px] bg-no-repeat h-[7.625rem] cursor-pointer hover:bg-bummock-disabled_grey transition-colors duration-200 shrink-0'>
					<div className='w-[13.75rem]'>
						<h3 className='text-xl font-bold text-bummock-midnight_blue mb-2'>Encriptación</h3>
						<p className='text-sm'>
							Encriptación de sellos NFC <strong>BUMMOCK</strong>®
						</p>
					</div>
				</div>
			</nav>
			<hr />

			<Outlet />
		</>
	);
};

export default SealsLayout;
