import { Document, StyleSheet } from '@react-pdf/renderer';
import { CoverPage } from './Pages/CoverPage';
import { TOCPage } from './Pages/TOCPage';
import { SecurityProcedureCheckListPage } from './Pages/SecurityProceduresCheckListPage';
import { InspectionedContainerRecordPage } from './Pages/InspectionedContainerRecordPage';
import { InspectionFormatPage } from './Pages/InspectionFormatPage';
import { PicturesPage } from './Pages/PicturesPage';
import {
	IDriverRelatedData,
	IInspectionedContainerRecordPage,
	IInspectionFormatPage,
	IKitRelatedData,
	ISecurityProcedureCheckListPage,
} from './interfaces';

const styles = StyleSheet.create({
	document: {
		fontFamily: 'Manrope',
		fontSize: 10,
		color: '#3F3F3F',
	},
});

interface IInspectionDocumentProps {
	firstPage: ISecurityProcedureCheckListPage;
	secondPage: IInspectionedContainerRecordPage;
	thirdPage: IInspectionFormatPage & IKitRelatedData & IDriverRelatedData;
	pictures: {
		inspeccionInterna: {
			title: string;
			pictures: {
				url: string;
				tookBy: string;
				date: string;
				coordinates: {
					lat: string;
					lng: string;
				};
			}[];
		}[];
		inspeccionExterna: {
			title: string;
			pictures: {
				url: string;
				tookBy: string;
				date: string;
				coordinates: {
					lat: string;
					lng: string;
				};
			}[];
		}[];
	};
}

export const InspectionDocument = ({ firstPage, secondPage, thirdPage, pictures }: IInspectionDocumentProps) => {
	return (
		<Document style={styles.document} title='Inspection #000'>
			<CoverPage client={firstPage.client ?? ''} />
			<TOCPage />

			{/* La información para esta página sale del formulario INGRESO INFORMACION que tiene el ID cb76fb94-a75e-48d8-8a0f-fd1dada76372 */}
			{/* When should this page not be present? */}
			<SecurityProcedureCheckListPage pageData={firstPage} />

			{/* We will have the content for this page later. */}
			{/* When should this page not be present? */}
			<InspectionedContainerRecordPage pageData={secondPage} />

			{/* When should this page not be present? */}
			<InspectionFormatPage pageData={thirdPage} />

			<PicturesPage
				id='WORKFLOWS_IMAGES'
				order='3'
				headerName='Inspección Interna'
				steps={pictures.inspeccionInterna.map(item => ({
					name: item.title,
					images: item.pictures,
				}))}
			/>

			{pictures.inspeccionExterna.length !== 0 && (
				<PicturesPage
					order='4'
					headerName='Inspección Externa'
					steps={pictures.inspeccionExterna.map(item => ({
						name: item.title,
						images: item.pictures,
					}))}
				/>
			)}
		</Document>
	);
};
