import { Button, DashedContainer, IcPlus, IcTrash } from '@atoms';
import { useKitsUsages, useProductsTypes } from '@hooks';
import { FormInput, FormInputSwitch, FormSearchableDropdown } from '@molecules';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

interface IFormKitTypeProps {
	onCancel: () => void;
	onSubmit: () => void;
	isLoading: boolean;
	canSave?: boolean;
}

interface IFormKitType {
	name: string;
	usage: string;
	usageDescription: string;
	requiresPackaging: boolean;
	packagingTypeId: string;
	items: {
		typeId: string;
		quantity: string;
	}[];
}

const REQUIRED_LENGTH = 3;

export const FormKitType = ({ isLoading, onCancel, onSubmit, canSave }: IFormKitTypeProps) => {
	const { register, formState, control, watch } = useFormContext<IFormKitType>();

	const { fields, append, remove } = useFieldArray({
		name: 'items',
		control,
	});

	const { data: productsTypesResponse } = useProductsTypes();
	const { data: kitsUsagesResponse } = useKitsUsages();

	const unselectedProductTypes = productsTypesResponse.filter(productType => {
		const selectedTypes = watch('items').filter(type => type.typeId);
		return !selectedTypes.find(selectedType => selectedType.typeId === productType.id);
	});

	return (
		<form onSubmit={onSubmit} autoComplete='off'>
			<div className='flex gap-8 mb-8'>
				<FormInput
					className='basis-0 grow'
					register={register('name', { required: { value: true, message: 'This field is required' } })}
					required={true}
					errorMessage={formState.errors.name?.message}
					onError={!!formState.errors.name}
					label='Nombre del Kit'
					placeholder='Name'
				/>
				<Controller
					control={control}
					name='usage'
					defaultValue=''
					rules={{ required: { value: true, message: 'This field is required' } }}
					render={({ field: { ref, ...rest }, fieldState: { error } }) => (
						<FormSearchableDropdown
							includeEmptyOption={true}
							className='basis-0 grow'
							label='Uso de Kit'
							required={true}
							options={
								kitsUsagesResponse?.data.map(productType => ({
									id: productType.id,
									value: productType.name,
								})) ?? []
							}
							onError={!!error?.message}
							errorMessage={error?.message}
							{...rest}
						/>
					)}
				/>
			</div>

			<FormInput
				className='mb-8'
				register={register('usageDescription', {
					required: { value: true, message: 'This field is required' },
				})}
				required={true}
				errorMessage={formState.errors.usageDescription?.message}
				onError={!!formState.errors.usageDescription}
				label='Descripción de Uso'
				placeholder='Description'
			/>

			{fields.map((fieldWithId, index) => {
				return (
					<div key={fieldWithId.id} className='flex gap-8 mt-4'>
						<Controller
							control={control}
							name={`items.${index}.typeId`}
							rules={{ required: { value: true, message: 'This field is required' } }}
							render={({ field: { ref, ...rest }, fieldState: { error } }) => {
								// Includes the unselected options + the selected options of the current
								// dropdown so that it shows up selected (otherwise thar option would be missing)
								const options = [
									...unselectedProductTypes.map(productType => ({
										id: productType.id,
										value: productType.name,
									})),
									{
										id: watch(`items.${index}.typeId`),
										value:
											productsTypesResponse.find(
												productType => productType.id === watch(`items.${index}.typeId`)
											)?.name ?? '',
									},
								];
								return (
									<FormSearchableDropdown
										includeEmptyOption={true}
										className='grow'
										label={`Tipo de Item ${index + 1}`}
										required={true}
										options={options}
										onError={!!error?.message}
										errorMessage={error?.message}
										{...rest}
									/>
								);
							}}
						/>
						<FormInput
							register={register(`items.${index}.quantity`, {
								required: { value: true, message: 'Required' },
							})}
							required={true}
							errorMessage={formState.errors?.items?.[index]?.quantity?.message}
							onError={!!formState.errors?.items?.[index]?.quantity?.message}
							className='basis-[5.9375rem]'
							label='Cantidad'
							placeholder='0'
						/>

						<button
							className={`self-center mt-[1.3rem] stroke-bummock-disabled_grey_2 ${fields.length > REQUIRED_LENGTH ? 'hover:stroke-bummock-midnight_blue' : 'cursor-not-allowed'}`}
							disabled={fields.length <= REQUIRED_LENGTH}
							tabIndex={-1}
							type='button'
							onClick={() => remove(index)}
						>
							<IcTrash
								className={`w-[1.25rem] m-1 stroke-2 stroke-inherit transition-colors duration-200`}
							/>
						</button>
					</div>
				);
			})}

			<DashedContainer
				onClick={() => {
					append({
						typeId: '',
						quantity: '',
					});
				}}
				className='cursor-pointer mt-4'
			>
				<span className='flex gap-2 text-sm'>
					<IcPlus className='stroke-2 stroke-bummock-midnight_blue w-4' />
					Agregar Item
				</span>
			</DashedContainer>

			<FormInputSwitch
				variant='secondary'
				label='Requires Packaging'
				register={register('requiresPackaging')}
				className='mt-8'
			/>

			{watch('requiresPackaging') && (
				<Controller
					control={control}
					name='packagingTypeId'
					rules={{ required: { value: true, message: 'This field is required' } }}
					defaultValue=''
					render={({ field: { ref, ...rest }, fieldState: { error } }) => (
						<FormSearchableDropdown
							includeEmptyOption={true}
							label='Packaging'
							className='mt-8'
							options={
								productsTypesResponse
									.map(productType => ({
										id: productType.id,
										value: productType.name,
									}))
									.filter(productType => {
										const alreadySelectedItems = Array(fields.length)
											.fill(0)
											.map((_, index) => watch(`items.${index}.typeId`))
											.filter(item => item.length > 0)
											// Creates a hash table to perform a fast lookup
											.reduce(
												(prev, curr) => {
													prev[curr] = true;
													return prev;
												},
												{} as Record<string, boolean>
											);
										return !alreadySelectedItems[productType.id];
									}) ?? []
							}
							onError={!!error?.message}
							errorMessage={error?.message}
							{...rest}
						/>
					)}
				/>
			)}

			<div className='flex gap-4 mt-8'>
				<Button onClick={onCancel} type='button' variant='secondary' className='w-[9.375rem]'>
					Cancelar
				</Button>
				<Button isLoading={isLoading} disabled={!canSave} className='w-[9.375rem]'>
					Guardar
				</Button>
			</div>
		</form>
	);
};
