import { IIconsProps } from './Icons.interfaces';

export const IcInventory = ({ className }: IIconsProps) => {
	return (
		<svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 21' fill='none'>
			<path
				d='M19.5 6.168V14.368C19.5 16.0482 19.5 16.8882 19.173 17.53C18.8854 18.0945 18.4265 18.5534 17.862 18.841C17.2202 19.168 16.3802 19.168 14.7 19.168H7.3C5.61984 19.168 4.77976 19.168 4.13803 18.841C3.57354 18.5534 3.1146 18.0945 2.82698 17.53C2.5 16.8882 2.5 16.0482 2.5 14.368V6.168M2.6 1.168H19.4C19.9601 1.168 20.2401 1.168 20.454 1.27699C20.6422 1.37287 20.7951 1.52585 20.891 1.71401C21 1.92792 21 2.20795 21 2.768V4.568C21 5.12805 21 5.40808 20.891 5.62199C20.7951 5.81015 20.6422 5.96313 20.454 6.05901C20.2401 6.168 19.9601 6.168 19.4 6.168H2.6C2.03995 6.168 1.75992 6.168 1.54601 6.05901C1.35785 5.96313 1.20487 5.81015 1.10899 5.62199C1 5.40808 1 5.12805 1 4.568V2.768C1 2.20795 1 1.92792 1.10899 1.71401C1.20487 1.52585 1.35785 1.37287 1.54601 1.27699C1.75992 1.168 2.03995 1.168 2.6 1.168ZM8.6 9.668H13.4C13.9601 9.668 14.2401 9.668 14.454 9.77699C14.6422 9.87287 14.7951 10.0258 14.891 10.214C15 10.4279 15 10.7079 15 11.268V12.068C15 12.6281 15 12.9081 14.891 13.122C14.7951 13.3102 14.6422 13.4631 14.454 13.559C14.2401 13.668 13.9601 13.668 13.4 13.668H8.6C8.03995 13.668 7.75992 13.668 7.54601 13.559C7.35785 13.4631 7.20487 13.3102 7.10899 13.122C7 12.9081 7 12.6281 7 12.068V11.268C7 10.7079 7 10.4279 7.10899 10.214C7.20487 10.0258 7.35785 9.87287 7.54601 9.77699C7.75992 9.668 8.03995 9.668 8.6 9.668Z'
				stroke='#092D45'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
