import { View, Text } from '@react-pdf/renderer';
import { NumberedContentPage } from './NumberedContentPage';
import { ObservationsTable } from '../components/ObservationsTable';
import { IInspectionedContainerRecordPage } from '../interfaces';

interface IInspectionedContainerRecordPageValues {
	pageData: IInspectionedContainerRecordPage;
}

// TODO: Refactor this
export const InspectionedContainerRecordPage = ({
	pageData: {
		containerNumber,
		date,
		inspectionFormatNumber,
		inspectionPlace,
		madeBy,
		spanishObservation,
		englishObservation,
		kitDetails,
	},
}: IInspectionedContainerRecordPageValues) => (
	<NumberedContentPage>
		<View
			id='RECORD_OF_INSPECTED_CONTAINER'
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '8px',
			}}
		>
			<Text style={{ fontWeight: 'bold' }}>2.</Text>
			<View
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Text style={{ fontWeight: 'bold' }}>Registro de contenedor inspeccionado</Text>
				<Text style={{ fontWeight: 400, color: '#A1A1A1' }}>Record of container inspected</Text>
			</View>
		</View>

		<View>
			{/* Header */}
			<View
				style={{
					padding: '8px',
					color: 'white',
					backgroundColor: '#092D45',
					display: 'flex',
					flexDirection: 'row',
					gap: '16px',
					borderTopLeftRadius: '4px',
					borderTopRightRadius: '4px',
				}}
			>
				<View
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '4px',
					}}
				>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '8px',
						}}
					>
						<Text
							style={{
								fontWeight: 'bold',
							}}
						>
							Formato de Inspección #:
						</Text>
						<Text style={{ color: '#D9D9D9' }}>{inspectionFormatNumber}</Text>
					</View>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '8px',
						}}
					>
						<Text
							style={{
								fontWeight: 'bold',
							}}
						>
							Realizado por:
						</Text>
						<Text style={{ color: '#D9D9D9' }}>{madeBy}</Text>
					</View>
				</View>

				<View
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '4px',
					}}
				>
					{/* TODO: Abstract this into a ValuePair component */}
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '8px',
						}}
					>
						<Text
							style={{
								fontWeight: 'bold',
							}}
						>
							Lugar inspección:
						</Text>
						<Text style={{ color: '#D9D9D9' }}>{inspectionPlace}</Text>
					</View>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '8px',
						}}
					>
						<Text
							style={{
								fontWeight: 'bold',
							}}
						>
							Fecha:
						</Text>
						<Text style={{ color: '#D9D9D9' }}>{date}</Text>
					</View>
				</View>
			</View>

			{/* ROW 1 */}
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',

					// Centralize this (abstract into a cell component)
					borderLeft: '1px solid #eee',
					borderRight: '1px solid #eee',
					borderBottom: '1px solid #eee',
				}}
			>
				<View
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',

						flexGrow: 1,
						padding: '8px',
					}}
				>
					<Text style={{ fontWeight: 'bold' }}>Contenedor</Text>
					<View style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginLeft: '8px' }}>
						<Text style={{ fontWeight: 'bold' }}>Número de Contenedor</Text>
						<Text style={{ color: '#A1A1A1' }}>{containerNumber}</Text>
					</View>
				</View>
			</View>

			{/* ROW 3 */}
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',

					// Centralize this (abstract into a cell component)
					borderLeft: '1px solid #eee',
					borderRight: '1px solid #eee',
					borderBottom: '1px solid #eee',
				}}
			>
				<View
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',

						flexGrow: 1,
						padding: '8px',
					}}
				>
					<Text style={{ fontWeight: 'bold' }}>Kit Asignado</Text>
					{kitDetails.map(kitDetail => (
						<View
							key={kitDetail.name}
							style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginLeft: '8px' }}
						>
							<Text style={{ fontWeight: 'bold' }}>{kitDetail.name}</Text>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									rowGap: '4px',
									columnGap: '16px',
									flexWrap: 'wrap',
								}}
							>
								{kitDetail.items.map(item => (
									<Text key={item} style={{ color: '#A1A1A1' }}>
										{item}
									</Text>
								))}
							</View>
						</View>
					))}
				</View>
			</View>
		</View>

		<ObservationsTable spanishObservation={spanishObservation} englishObservation={englishObservation} />
	</NumberedContentPage>
);
