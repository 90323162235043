import { useQuery } from '@tanstack/react-query';

import { findAllProductsTypes, QUERY_KEYS } from '@api';

export const useProductsTypes = () => {
	const {
		data = [],
		isLoading,
		isError,
	} = useQuery({
		queryKey: [QUERY_KEYS.productsTypes],
		queryFn: () =>
			findAllProductsTypes({ page: 0, limit: 100000, sorting: [], searchTerm: '' }).then(
				response => response.data
			),
	});

	return {
		data,
		isLoading,
		isError,
	};
};
