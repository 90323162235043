import { findAllSuppliers, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

export const useSuppliers = () => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.suppliers],
		queryFn: () => findAllSuppliers({ page: 0, limit: 100000, sorting: [], searchTerm: '' }),
	});

	return queryResult;
};
