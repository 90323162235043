import { apiPrivate } from '../config/apiPrivate';

interface IDeactivateUserParams {
	id: string;
}

interface IDeactivateUserResp {}

export const deactivateUser = async (params: IDeactivateUserParams): Promise<IDeactivateUserResp> => {
	const { data } = await apiPrivate.patch<IDeactivateUserResp>(`/api/users/${params.id}/deactivate`);
	return data;
};
