import { useForm } from 'react-hook-form';
import { Modal } from '../Modal';
import { FormInput } from '@molecules';
import { Button } from '@atoms';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateKitsUsage } from '@api';
import { useEffect } from 'react';
import { IModalProps } from '@interfaces';

// We don't have an endpoint that gets the details of a kit usage so we get them from the table
interface IModalEditKitUsageProps extends IModalProps, IEditKitUsageFormValues {
	editingItemId: string;
}

interface IEditKitUsageFormValues {
	name: string;
	description: string;
}

export const ModalEditKitUsage = ({
	name,
	description,
	editingItemId,
	isVisible,
	onCloseModal,
}: IModalEditKitUsageProps) => {
	const {
		register,
		handleSubmit: handleSubmitWrapper,
		reset,
		formState: { isDirty },
	} = useForm<IEditKitUsageFormValues>();

	useEffect(() => {
		isVisible && reset();
	}, [isVisible]);

	useEffect(() => {
		reset({
			name,
			description,
		});
	}, [name, description]);

	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: updateKitsUsage,
		onSuccess: () => {
			onCloseModal();
			queryClient.invalidateQueries({
				queryKey: ['usages'],
			});
			// Modal fade out transition time
			setTimeout(() => reset(), 500);
		},
	});

	const handleSubmit = ({ name, description }: IEditKitUsageFormValues) => {
		mutation.mutate({
			id: editingItemId,
			name,
			description,
		});
	};

	return (
		<Modal
			className='p-10 w-full max-w-[838px]'
			title='Edición de Uso de Kit'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<p className='text-2xs w-3/4'>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
				dolore magna aliqua.
			</p>
			<form className='mt-8 flex flex-col gap-8' onSubmit={handleSubmitWrapper(handleSubmit)} autoComplete='off'>
				<FormInput
					label='Nombre'
					placeholder='Kit ABC'
					required={true}
					register={register('name', { required: { value: true, message: 'This field is required' } })}
				/>
				<FormInput
					label='Descripción de uso'
					placeholder='For ABC type of container'
					required={true}
					register={register('description', { required: { value: true, message: 'This field is required' } })}
				/>
				<div className='flex gap-4'>
					<Button type='button' onClick={onCloseModal} className='px-9' variant='secondary'>
						Cancelar
					</Button>
					<Button disabled={!isDirty} isLoading={mutation.isPending} className='px-9'>
						Guardar
					</Button>
				</div>
			</form>
		</Modal>
	);
};
