import { findAllLocationsByCompany, findAllLocationsByUser, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

type TUseLocationsParams =
	| {
			by: 'user';
			userId: string;
	  }
	| {
			by: 'company';
			companyId: string;
	  };

export const useLocations = (params: TUseLocationsParams) => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.locations, 'no-paging', params.by],
		queryFn: () => {
			if (params.by === 'company') {
				return findAllLocationsByCompany({
					companyId: params.companyId,
					page: 0,
					limit: 100000,
					sorting: [],
					searchTerm: '',
				});
			} else {
				return findAllLocationsByUser({
					userId: params.userId,
					page: 0,
					limit: 100000,
					sorting: [],
					searchTerm: '',
				});
			}
		},
	});

	return {
		...queryResult,
		data: {
			courtyards: queryResult.data?.data.filter(location => location.locationType.name === 'Patio') ?? [],
			farms: queryResult.data?.data.filter(location => location.locationType.name === 'Finca') ?? [],
		},
	};
};
