import { Places, QUERY_KEYS, findAllPlaces } from '@api';
import { useQuery } from '@tanstack/react-query';

export const usePlaces = () => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.places],
		queryFn: () => findAllPlaces(),
		initialData: new Places([]),
	});

	return queryResult;
};
