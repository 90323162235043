import { ISupplier } from '@interfaces';
import { apiPrivate } from '../config/apiPrivate';

interface IGetOneSupplierParams {
	id: string;
}

interface ISupplierResponseEntity extends ISupplier {
	createdAt: string;
	updatedAt: string;
	deleteAt: string;
}

export const getOneSupplier = async (params: IGetOneSupplierParams): Promise<ISupplier> => {
	const { data } = await apiPrivate.get<ISupplierResponseEntity>(`/api/suppliers/${params.id}`);
	return data;
};
