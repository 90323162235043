import { ITEMS_PER_PAGE } from '@utils';

export const TableSkeleton = () => (
	<table className='w-full animate-pulse'>
		<thead>
			<tr>
				<td>
					<div className='h-4 bg-bummock-ocean_blue opacity-40 rounded w-full'></div>
				</td>
				<td>
					<div className='h-4 bg-bummock-ocean_blue opacity-40 rounded w-full'></div>
				</td>
				<td>
					<div className='h-4 bg-bummock-ocean_blue opacity-40 rounded w-full'></div>
				</td>
				<td>
					<div className='h-4 bg-bummock-ocean_blue opacity-40 rounded w-full'></div>
				</td>
			</tr>
		</thead>
		<tbody>
			{Array.from({ length: ITEMS_PER_PAGE }).map((_, i) => (
				<tr key={i}>
					<td>
						<div className='h-4 bg-bummock-arctic_blue opacity-50 rounded w-full'></div>
					</td>
					<td>
						<div className='h-4 bg-bummock-arctic_blue opacity-50 rounded w-full'></div>
					</td>
					<td>
						<div className='h-4 bg-bummock-arctic_blue opacity-50 rounded w-full'></div>
					</td>
					<td>
						<div className='h-4 bg-bummock-arctic_blue opacity-50 rounded w-full'></div>
					</td>
				</tr>
			))}
		</tbody>
	</table>
);
