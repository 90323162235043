import { apiPrivate } from '../config/apiPrivate';

interface IReturnKitContentParams {
	kitId: string;
	user: string;
	items: {
		id: string;
		reason: string;
	}[];
}

interface IReturnKitContentResp {}

export const returnKitContent = async ({
	kitId,
	...restParams
}: IReturnKitContentParams): Promise<IReturnKitContentResp> => {
	const { data } = await apiPrivate.patch<IReturnKitContentResp>(`/api/kits/${kitId}`, restParams);
	return data;
};
