import { Header, Sidebar } from '@organisms';
import { Navigate, Outlet } from 'react-router-dom';

import styles from './DashboardLayout.module.sass';
import { useGSAP } from '@gsap/react';
import { useRef } from 'react';
import gsap from 'gsap';
import { useAppSelector, usePlaces } from '@hooks';

const DashboardLayout = () => {
	const {
		loaderAnimation: { isDone },
	} = useAppSelector(state => state.auth);
	const dashboardRef = useRef<HTMLElement>(null);

	const { id } = useAppSelector(state => state.userProfile);

	usePlaces();

	useGSAP(() => isDone && gsap.fromTo(dashboardRef.current, { opacity: 0 }, { opacity: 1, duration: 0.5 }), [isDone]);

	if (!id) return <Navigate to='/login' />;

	return (
		<section ref={dashboardRef} className={styles['wrapper']}>
			<Sidebar className={styles['wrapper__left-column']} />
			<section className={styles['wrapper__content']}>
				<Header />
				<main className={styles['wrapper__content__main']}>
					<Outlet />
				</main>
			</section>
		</section>
	);
};

export default DashboardLayout;
