import { NavLink } from 'react-router-dom';
import type { ILinkSolidProps } from './LinkSolid.interfaces';

import styles from './LinkSolid.module.sass';

// TODO: Allow for custom icon placement
export const LinkSolid = ({ to, label, icon, className = '', onClick = () => {} }: ILinkSolidProps) => {
	return (
		<NavLink
			onClick={onClick}
			className={({ isActive }) => `${className} ${styles['link']} ${isActive ? styles['active'] : ''}`}
			to={to}
			data-type='button'
		>
			{icon}
			{label}
		</NavLink>
	);
};
