import { getOneEntity, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

export const useEntity = (entityId: string) => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.entities, entityId],
		queryFn: () => getOneEntity({ entityId }),
	});

	return queryResult;
};
