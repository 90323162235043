import { apiList } from '@utils';
import { apiPrivate } from '../config/apiPrivate';

import type { IFormLoginValues } from '@interfaces';

interface ILoginParams extends IFormLoginValues {}

interface ILoginResp {
	readonly type: string;
	readonly message: string;
	readonly twoFactorIsEnabled: boolean;
}

export const login = async (params: ILoginParams): Promise<ILoginResp> => {
	const { data } = await apiPrivate.post(apiList.auth.login, params);
	return data;
};
