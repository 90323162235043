import { InputCheckbox } from '@atoms';
import { IFormInputsProps } from '@interfaces';
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { v4 } from 'uuid';

interface IFormInputCheckboxProps<T extends FieldValues> extends Omit<IFormInputsProps, 'value' | 'register'> {
	form: UseFormReturn<T>;
	inputName: Path<T>;
}

export const FormInputCheckbox = <T extends FieldValues>({
	className = '',
	disabled,
	errorMessage,
	label,
	name,
	onError: hasError,
	pattern,
	placeholder,
	required,
	form,
	inputName,
}: IFormInputCheckboxProps<T>) => {
	const id = v4();
	return (
		<fieldset className={`flex gap-[6px] ${className}`}>
			<Controller
				control={form.control}
				name={inputName}
				render={({ field: { onChange, value } }) => (
					<InputCheckbox
						{...{ disabled, id, name, onChange, pattern, placeholder, required }}
						checked={value}
						onChange={onChange}
					/>
				)}
			/>
			<label {...{ disabled, id, required }} htmlFor={id}>
				{label}
			</label>
			{hasError ? <span>{errorMessage}</span> : null}
		</fieldset>
	);
};
