import { IPaginatedResourceParams, getPaginatedResource } from '@api';
import { IAvailableInventory } from '@interfaces';

interface IAvailableInventoryResponseEntity {
	id: string;
	createdAt: string;
	distinctive: string;
	color: string;
	startCode: string;
	endCode: string;
	inventoryDetail: number;
	product: {
		id: string;
		name: string;
		description: string;
		productType: {
			name: string;
		};
		category: {
			name: string;
		};
	};
}

export const findAvailableInventory = async (params: IPaginatedResourceParams) => {
	const { data } = await getPaginatedResource<IAvailableInventoryResponseEntity>(
		'/api/products/available-inventory',
		params
	);

	const availableInventory: IAvailableInventory[] =
		data.data.map(({ endCode, id, inventoryDetail, product, startCode }) => ({
			id,
			name: product.name,
			available: inventoryDetail,
			code: id.slice(-6),
			serialBegin: startCode,
			serialEnd: endCode,
		})) || [];

	return {
		...data,
		data: availableInventory,
	};
};
