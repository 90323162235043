import { findAllRoles, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

export const useRoles = () => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.roles],
		queryFn: () => findAllRoles(),
	});

	return queryResult;
};
