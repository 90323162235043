import { IPlace } from '@interfaces';
import { apiPrivate } from '../config/apiPrivate';

export class Places {
	data: IPlace[];

	constructor(data: IPlace[]) {
		this.data = data;
	}

	getStateByCityId(cityId: string) {
		const cityWithState = this.data
			.flatMap(country => country.states)
			.flatMap(({ id, cities }) => {
				return cities.map(city => ({
					...city,
					stateId: id,
				}));
			})
			.find(city => city.id === cityId);

		return cityWithState?.stateId ?? '';
	}

	getStatesByCountryId(countryId: string) {
		return this.data.find(country => country.id === countryId)?.states ?? [];
	}

	getCitiesByStateId(stateId: string) {
		return this.data.flatMap(country => country.states).find(state => state.id === stateId)?.cities ?? [];
	}

	getCountries() {
		return this.data;
	}
}

type IFindAllPlacesResp = Places;

export const findAllPlaces = async (): Promise<IFindAllPlacesResp> => {
	const { data } = await apiPrivate.get<IPlace[]>('/api/places');
	return new Places(data);
};
