import { apiPrivate } from '../config/apiPrivate';

interface IUnassignLocationFromUserParams {
	userId: string;
	locationsIds: string[];
}

interface IUnassignLocationFromUserResp {}

export const unassignLocationFromUser = async ({
	locationsIds,
	userId,
}: IUnassignLocationFromUserParams): Promise<IUnassignLocationFromUserResp> => {
	const { data } = await apiPrivate.patch<IUnassignLocationFromUserResp>(`/api/locations/deactivate/${userId}`, {
		locationIds: locationsIds,
	});
	return data;
};
