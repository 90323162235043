import { getPaginatedResource, IPaginatedResourceParams } from '@api';
import { IInspection } from '@interfaces';
import moment from 'moment';

interface IInspectionResponseEntity {
	id: string;
	createdAt: string;
	reference: string;
	isActive: boolean;
}

export const findAllInspections = async (params: IPaginatedResourceParams) => {
	const { data } = await getPaginatedResource<IInspectionResponseEntity>('/api/workflow/processes-performed', params);

	const inspections: IInspection[] =
		data?.data.map(({ id, isActive, createdAt, reference }) => ({
			id,
			status: isActive ? 'En proceso' : 'Inspeccionado',
			container: reference,
			inspectionDate: moment.utc(createdAt).format('DD/MM/YYYY'),
		})) || [];

	return {
		...data,
		data: inspections,
	};
};
