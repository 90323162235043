import { Alert, Button } from '@atoms';
import { FormInputCheckbox } from '@molecules';
import { useForm } from 'react-hook-form';

interface IFormConfirmedDeletionFields {
	hasConfirmed: boolean;
}

const DEFAULT_VALUES = {
	hasConfirmed: false,
};

interface IFormConfirmedDeletionProps {
	label: string;
	alertMessage: string;
	buttonLabel: string;
	onDelete: () => void;
}

export const FormConfirmedDeletion = ({ label, buttonLabel, alertMessage, onDelete }: IFormConfirmedDeletionProps) => {
	const form = useForm<IFormConfirmedDeletionFields>({
		defaultValues: DEFAULT_VALUES,
	});
	const { handleSubmit: handleSubmitWrapper, watch } = form;
	const hasConfirmed = watch('hasConfirmed');

	function handleDelete() {
		onDelete();
	}

	return (
		<form onSubmit={handleSubmitWrapper(handleDelete)}>
			<Alert className='mb-[28px]' message={alertMessage} />
			<FormInputCheckbox form={form} inputName='hasConfirmed' label={label} />
			<Button disabled={!hasConfirmed} className='mt-8' variant='secondary'>
				{buttonLabel}
			</Button>
		</form>
	);
};
