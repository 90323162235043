import { FormChangeUserPassword } from '@organisms';
import { FormInputSwitch } from '@molecules';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAppSelector, useUser } from '@hooks';
import { ChangeEvent } from 'react';
import { useMutation } from '@tanstack/react-query';
import { updateTwoFA } from '@api';

interface ITwoFAValues {
	isEnabled: boolean;
}

interface ITwoFACardProps {
	userId: string;
	isAdmin: boolean;
	isTwoFAEnabled: boolean;
}

const TwoFACard = ({ userId, isTwoFAEnabled, isAdmin }: ITwoFACardProps) => {
	const mutation = useMutation({
		mutationFn: updateTwoFA,
	});

	const { register, setValue } = useForm<ITwoFAValues>({
		defaultValues: { isEnabled: isTwoFAEnabled },
	});

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const wasChecked = e.target.checked;
		if (isAdmin && !wasChecked) {
			setValue('isEnabled', true);
			// alert('An admin must have 2FA enabled.');
			return;
		}

		mutation.mutate({
			userId,
			twoFactorIsEnabled: wasChecked,
		});
	};

	return (
		<div className='shadow-bummock rounded-2xl p-8 flex items-center gap-[8rem] justify-between'>
			<div>
				<header className='text-xl font-bold text-bummock-ocean_blue mb-4'>
					Autenticación de dos factores
				</header>
				<p className='text-bummock-ocean_blue'>
					Solicita a los usuarios verificar su identidad al ingresar su nombre de usuario y contraseña para
					añadir una capa adicional de seguridad a sus cuentas.
				</p>
			</div>
			<FormInputSwitch disabled={isAdmin} register={register('isEnabled', { onChange: handleChange })} />
		</div>
	);
};

const EditSecurityPage = () => {
	const { id: selectedUser } = useParams();
	const { id: profileUserId } = useAppSelector(state => state.userProfile);
	const userId = selectedUser || profileUserId;
	const { isLoading, isError, data: userData } = useUser(userId);

	return (
		<>
			<h2 className='text-xl font-bold text-bummock-ocean_blue'>Seguridad</h2>

			{isLoading && <Skeleton2FA />}

			{!isLoading && !isError && (
				<TwoFACard
					userId={userData!.id}
					isAdmin={userData!.role.name === 'Administrativo'}
					isTwoFAEnabled={userData!.has2fa}
				/>
			)}

			<hr />

			<section>
				<h3 className='text-xl font-bold text-bummock-ocean_blue'>Cambiar Contraseña</h3>
				<p className='text-sm'>Utiliza una contraseña segura que no uses en otros sitios web.</p>
				<FormChangeUserPassword />
			</section>
		</>
	);
};

function Skeleton2FA() {
	return (
		<div className='space-y-4 animate-pulse'>
			<div className='w-full h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			<div className='w-1/3  h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			<div className='w-2/3  h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
			<div className='w-full h-5 bg-bummock-arctic_blue opacity-50 rounded col-span-1'></div>
		</div>
	);
}

export default EditSecurityPage;
