import { apiPrivate } from '../config/apiPrivate';

interface ICreateKitParams {
	inventoryDetail: string;
	kitType: string;
	quantity: number;
	kitDetail: {
		inventoryDetail: string;
	}[];
}

interface ICreateKitResp {}

export const createKit = async (params: ICreateKitParams): Promise<ICreateKitResp> => {
	const { data } = await apiPrivate.post<ICreateKitResp>('/api/kits', params);
	return data;
};
