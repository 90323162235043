import { apiPrivate } from '../config/apiPrivate';

interface IGetDispatchDetailsParams {
	dispatchId: string;
	dispatchType: 'bulk' | 'kits';
}

interface IGetDispatchDetailsResp {
	id: string;
	code: number;
	user: {
		firstName: string;
		lastName: string;
		image: string;
	};
	location: {
		name: string;
	};
	createdAt: string;
}

const ENDPOINTS = {
	bulk: '/api/dispatches/bulk',
	kits: '/api/dispatches',
};

export const getDispatchDetails = async ({
	dispatchId,
	dispatchType,
}: IGetDispatchDetailsParams): Promise<IGetDispatchDetailsResp> => {
	// This should be changed once we have separate endpoints for the table and the details
	const { data } = await apiPrivate.get<{ header: IGetDispatchDetailsResp }>(
		`${ENDPOINTS[dispatchType]}/${dispatchId}`
	);
	return data.header;
};
