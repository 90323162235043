import { apiPrivate } from '../config/apiPrivate';

interface IUpdateProductTypeParams {
	id: string;
	name?: string;
}

interface IUpdateProductTypeResp {}

export const updateProductType = async ({
	id,
	...restParams
}: IUpdateProductTypeParams): Promise<IUpdateProductTypeResp> => {
	const { data } = await apiPrivate.patch<IUpdateProductTypeResp>(`/api/products/product-type/${id}`, restParams);
	return data;
};
