import type { IFormHeaderProps } from './FormHeader.interfaces';

export const FormHeader = ({ title, description, descriptionHighlight, icon }: IFormHeaderProps) => {
	return (
		<header className='w-full flex flex-col items-center gap-y-4'>
			{icon ? (
				<div className='w-[4.125rem] h-[4.125rem] mb-4 inline-grid place-items-center bg-bummock-arctic_blue/50 rounded-full'>
					<div className='w-[2.875rem] h-[2.875rem] inline-grid place-items-center bg-bummock-arctic_blue rounded-full'>
						{icon}
					</div>
				</div>
			) : null}
			<h1 className='font-semibold text-2xl text-bummock-midnight_blue leading-none text-center'>{title}</h1>
			<p className='text-center'>
				{description}
				<span className='font-bold text-bummock-midnight_blue'>{descriptionHighlight}</span>
			</p>
		</header>
	);
};
