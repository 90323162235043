import { changePassword } from '@api';
import { Button, InputValidationCriteria } from '@atoms';
import { useAppSelector } from '@hooks';
import { FormInputPassword } from '@molecules';
import { useMutation } from '@tanstack/react-query';
import { PATTERN_PASSWORD, REGEX_PASSWORD } from '@utils';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

interface IFormChangeUserPasswordFields {
	currentPassword: string;
	newPassword: string;
	newPasswordConfirmation: string;
}

const INITIAL_DATA: IFormChangeUserPasswordFields = {
	currentPassword: '',
	newPassword: '',
	newPasswordConfirmation: '',
};
export const FormChangeUserPassword = () => {
	const { id: userIdFromParams } = useParams();
	const isEditingUserFromTable = !!userIdFromParams;
	const userIdFromState = useAppSelector(state => state.userProfile).id;
	const userId = userIdFromParams || userIdFromState;

	const {
		register,
		formState: { isDirty, isValid, errors },
		watch,
		handleSubmit: handleSubmitWrapper,
		reset,
	} = useForm<IFormChangeUserPasswordFields>({
		defaultValues: INITIAL_DATA,
	});

	const mutation = useMutation({
		mutationFn: changePassword,
		onSuccess: () => {
			reset();
			toast.success('Contraseña actualizada.');
		},
	});

	const handleSubmit = ({ newPassword }: IFormChangeUserPasswordFields) => {
		mutation.mutate({
			id: userId!,
			newPassword,
		});
	};

	const handleCancelChangePassword = () => {
		reset();
	};

	return (
		<form onSubmit={handleSubmitWrapper(handleSubmit)} className='flex flex-col gap-8'>
			{!isEditingUserFromTable ? (
				<>
					<p className='mt-2'>Please enter your current password to change your password.</p>

					<FormInputPassword
						className='w-[21.3125rem]'
						label='Contraseña Actual'
						placeholder='Ej: Bummock123@'
						onError={!!errors.newPassword}
						errorMessage={errors.newPassword?.message}
						register={{
							...register('currentPassword', {
								required: { value: true, message: 'All fields are required' },
								pattern: { value: REGEX_PASSWORD, message: '' },
								minLength: { value: 8, message: '' },
							}),
						}}
					/>

					<hr className='w-full' />
				</>
			) : null}

			<div className='flex flex-col gap-4 items-start mt-4'>
				<FormInputPassword
					className='w-[21.3125rem]'
					label='Nueva Contraseña'
					placeholder='Ej: Bummock123@'
					onError={!!errors.newPassword}
					errorMessage={errors.newPassword?.message}
					register={{
						...register('newPassword', {
							required: { value: true, message: 'All fields are required' },
							pattern: { value: REGEX_PASSWORD, message: '' },
							minLength: { value: 8, message: '' },
						}),
					}}
				/>
				<FormInputPassword
					className='w-[21.3125rem]'
					label='Confirmar Nueva Contraseña'
					placeholder='Ej: Bummock123@'
					onError={!!errors.newPasswordConfirmation}
					errorMessage={errors.newPasswordConfirmation?.message}
					register={{
						...register('newPasswordConfirmation', {
							required: { value: true, message: 'All fields are required' },
							pattern: { value: REGEX_PASSWORD, message: '' },
							minLength: { value: 8, message: '' },
						}),
					}}
				/>
				<InputValidationCriteria
					criterias={[
						{
							callback: () => isDirty && watch('newPassword').length >= 8,
							message: 'Debe tener al menos 8 caracteres.',
						},
						{
							callback: () => isDirty && !!watch('newPassword').match(PATTERN_PASSWORD),
							message: 'Debe incluir mayúscula, minúscula, número y caracter especial.',
						},
					]}
				/>
			</div>
			<div className='flex gap-4'>
				<Button
					isLoading={mutation.isPending}
					type='submit'
					disabled={
						!isValid ||
						watch('newPasswordConfirmation').length === 0 ||
						watch('newPassword').length === 0 ||
						watch('newPassword') !== watch('newPasswordConfirmation') ||
						(!isEditingUserFromTable ? watch('currentPassword').length === 0 : false)
					}
				>
					Actualizar Contraseña
				</Button>
				<Button type='button' onClick={handleCancelChangePassword} className='w-[8.25rem]' variant='secondary'>
					Cancelar
				</Button>
			</div>
		</form>
	);
};
