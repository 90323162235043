import { apiPrivate } from '../config/apiPrivate';

interface IVerifySecurityItemParams {
	typeId: string;
	itemSerial: string;
}

interface IVerifySecurityItemResp {
	id: string;
}

export const verifySecurityItem = async ({
	itemSerial,
	typeId,
}: IVerifySecurityItemParams): Promise<IVerifySecurityItemResp> => {
	const { data } = await apiPrivate.get<IVerifySecurityItemResp>(`/api/kits/verify-item/${itemSerial}/${typeId}`);
	return data;
};
