import { apiPrivate } from '../config/apiPrivate';

interface IDeleteKitsUsageParams {
	id: string;
}

interface IDeleteKitsUsageResp {}

export const deleteKitsUsage = async (params: IDeleteKitsUsageParams): Promise<IDeleteKitsUsageResp> => {
	const { data } = await apiPrivate.delete<IDeleteKitsUsageResp>(`/api/kit-usage/${params.id}`);
	return data;
};
