import { BaseTable, TablePagination, TableSearchbar } from '@organisms';
import { findAllDrivers, QUERY_KEYS } from '@api';
import { useTableQuery } from '@hooks';
import { IDriver } from '@interfaces';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<IDriver>();
const driversColumns = [
	columnHelper.accessor('dni', {
		header: 'Cédula',
		enableSorting: false,
	}),
	columnHelper.display({
		header: 'Nombres',
		cell: ({ row }) => (
			<span className='capitalize'>
				{row.original.firstName?.toLowerCase()} {row.original.lastName?.toLowerCase()}
			</span>
		),
		meta: { alignment: 'left' },
		enableSorting: false,
	}),
	columnHelper.accessor('phoneNumber', {
		header: 'Celular',
		cell: ({ row }) => row.original.phoneNumber || '-',
		meta: { alignment: 'center' },
	}),
	columnHelper.display({
		header: 'Tiene Incidentes',
		cell: ({ row }) => (row.original.incidents ? 'Sí' : 'No'),
		meta: { alignment: 'center' },
		enableSorting: false,
	}),
	columnHelper.accessor('numberIncidents', {
		header: 'Número de Incidentes',
		enableSorting: false,
		meta: { alignment: 'center' },
	}),
	columnHelper.accessor('country.name', {
		header: 'País',
		enableSorting: false,
		meta: { alignment: 'right' },
	}),
];

const DriversPage = () => {
	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.drivers],
		fetcherFn: findAllDrivers,
		tableColumns: driversColumns,
	});

	return (
		<>
			<TableSearchbar tableQueryResult={tableQueryResult} />
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default DriversPage;
