import { IIconsProps } from './Icons.interfaces';

export const IcReport = ({ className, onClick }: IIconsProps) => {
	return (
		<svg className={className} onClick={onClick} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 18' fill='none'>
			<path
				d='M8.5 8.41797H4M5.5 11.418H4M10 5.41797H4M13 8.04297V5.26797C13 4.00785 13 3.37779 12.7548 2.89649C12.539 2.47313 12.1948 2.12892 11.7715 1.9132C11.2902 1.66797 10.6601 1.66797 9.4 1.66797H4.6C3.33988 1.66797 2.70982 1.66797 2.22852 1.9132C1.80516 2.12892 1.46095 2.47313 1.24524 2.89649C1 3.37779 1 4.00785 1 5.26797V13.068C1 14.3281 1 14.9581 1.24524 15.4394C1.46095 15.8628 1.80516 16.207 2.22852 16.4227C2.70982 16.668 3.33988 16.668 4.6 16.668H6.625M14.5 16.668L13.375 15.543M14.125 13.668C14.125 15.1177 12.9497 16.293 11.5 16.293C10.0503 16.293 8.875 15.1177 8.875 13.668C8.875 12.2182 10.0503 11.043 11.5 11.043C12.9497 11.043 14.125 12.2182 14.125 13.668Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
