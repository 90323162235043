import { Font } from '@react-pdf/renderer';

Font.register({
	family: 'Manrope',
	fonts: [
		{ src: '/fonts/Manrope-Light.ttf', fontWeight: 300 },
		{ src: '/fonts/Manrope-Regular.ttf', fontWeight: 400 },
		{ src: '/fonts/Manrope-SemiBold.ttf', fontWeight: 600 },
		{ src: '/fonts/Manrope-Bold.ttf', fontWeight: 700 },
		{ src: '/fonts/Manrope-ExtraBold.ttf', fontWeight: 800 },
	],
});

export { InspectionDocument } from './InspectionDocument/InspectionDocument';
