import { IIconsProps } from './Icons.interfaces';

export const IcCompanies = ({ className = '' }: IIconsProps) => (
	<svg xmlns='http://www.w3.org/2000/svg' className={className} viewBox='0 0 22 21' fill='none'>
		<path
			d='M12 9.168H16.8C17.9201 9.168 18.4802 9.168 18.908 9.38599C19.2843 9.57773 19.5903 9.88369 19.782 10.26C20 10.6878 20 11.2479 20 12.368V19.168M12 19.168V4.368C12 3.24789 12 2.68784 11.782 2.26002C11.5903 1.88369 11.2843 1.57773 10.908 1.38599C10.4802 1.168 9.9201 1.168 8.8 1.168H5.2C4.0799 1.168 3.51984 1.168 3.09202 1.38599C2.71569 1.57773 2.40973 1.88369 2.21799 2.26002C2 2.68784 2 3.24789 2 4.368V19.168M21 19.168H1M5.5 5.168H8.5M5.5 9.168H8.5M5.5 13.168H8.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
