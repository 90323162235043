import { SealsLayout } from '@layout';
import { SealsPage } from '@pages';
import { RouteObject } from 'react-router-dom';

export const sealsRoutes: RouteObject[] = [
	{
		path: 'seals',
		element: <SealsLayout />,
		children: [
			{
				path: 'encrypted',
				element: <SealsPage />,
			},
		],
	},
];
