import { findAllFarms, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

type TUseFarmsParams =
	| {
			by: 'user';
			userId: string;
	  }
	| {
			by: 'company';
			companyId: string;
	  };

export const useFarms = (params: TUseFarmsParams) => {
	const queryResult = useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: [QUERY_KEYS.farms, params.by === 'user' ? params.userId : params.companyId],
		queryFn: () =>
			params.by === 'user'
				? findAllFarms({ by: params.by, userId: params.userId })
				: findAllFarms({ by: params.by, companyId: params.companyId }),
	});

	return queryResult;
};
