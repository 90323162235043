import { apiPrivate } from '../config/apiPrivate';

interface IGetOneDriverParams {
	driverId: string;
}

interface IGetOneDriverResp {
	id: string;
	createdAt: string;
	updatedAt: string;
	deleteAt: string;
	dni: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	incidents: boolean;
	numberIncidents: number;
}

export const getOneDriver = async ({ driverId }: IGetOneDriverParams): Promise<IGetOneDriverResp> => {
	const { data } = await apiPrivate.get<IGetOneDriverResp>(`/api/drivers/${driverId}`);
	return data;
};
