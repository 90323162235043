import { apiPrivate } from '../config/apiPrivate';

interface IGetOneProcessParams {
	id: string;
}

const processExample = {
	id: 'efd83e5e-ff31-49a4-a620-15bb8cd2d6ea',
	reference: 'MMMM1234567',
	company: 'CIT CAL',
	date: '2024-09-13T21:06:22.141Z',
	kitId: 'Por qué no agregas la propiedad aquí?',
	workflows: [
		{
			title: 'INSPECCION REEFER',
			description: 'contenedores reefer',
			titleWorkflow: 'INSPECCION INTERNA REEFER',
			descriptionWorkflow: 'INSPECCIONAR LA PARTE INTERNA DEL CONTENEDOR',
			// Puedo usar esta fecha
			createAtWorkflow: '2024-08-30T16:49:18.379Z',
			location: 'Patio Ejemplo',
			steps: [
				{
					formId: 'cb76fb94-a75e-48d8-8a0f-fd1dada76372',
					title: 'INGRESO INFORMACIÒN',
					formType: 'FORMS',
					component: [
						{
							id: 'f25884f7-9842-40f7-b1e3-8d6bcbe79171',
							label: 'CODIGO DE BARRA DEL FORMATO',
							dataType: 'Texto',
							response: [
								{
									response_answer: 'VERD',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-02T00:00:00.000Z',
									fullName: 'Daniel Guzman',
								},
							],
						},
						{
							id: 'f25884f7-9842-40f7-b1e3-8d6bcbe79171',
							label: 'CLIENTE',
							dataType: 'Texto',
							response: [
								{
									response_answer: 'AP',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-02T00:00:00.000Z',
									fullName: 'Daniel Guzman',
								},
							],
						},
						{
							id: 'f25884f7-9842-40f7-b1e3-8d6bcbe79171',
							label: 'LUGAR',
							dataType: 'Texto',
							response: [
								{
									response_answer: 'P.RISKPRO',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-02T00:00:00.000Z',
									fullName: 'Daniel Guzman',
								},
							],
						},
						{
							id: 'f25884f7-9842-40f7-b1e3-8d6bcbe79171',
							label: 'CONDUCTOR',
							dataType: 'Texto',
							response: [
								{
									response_answer: 'Conductor 1',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-02T00:00:00.000Z',
									fullName: 'Daniel Guzman',
								},
							],
						},
						{
							id: 'f48b2079-7cff-4726-8b24-ab58dd619697',
							label: 'PLACA',
							dataType: 'Texto',
							response: [
								{
									response_answer: 'ABC-123',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-02T00:00:00.000Z',
									fullName: 'Daniel Guzman',
								},
							],
						},
						{
							id: 'f25884f7-9842-40f7-b1e3-8d6bcbe79171',
							label: 'TRANSPORTISTA',
							dataType: 'Texto',
							response: [
								{
									response_answer: 'EMPRESA TRANSPORTE 1',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-02T00:00:00.000Z',
									fullName: 'Daniel Guzman',
								},
							],
						},
						{
							id: 'f48b2079-7cff-4726-8b24-ab58dd619697',
							label: 'BOOKING',
							dataType: 'Texto',
							response: [
								{
									response_answer: 'BOOKING123',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-02T00:00:00.000Z',
									fullName: 'Daniel Guzman',
								},
							],
						},
						{
							id: 'f25884f7-9842-40f7-b1e3-8d6bcbe79171',
							label: 'NAVIERA',
							dataType: 'Texto',
							response: [
								{
									response_answer: 'NAVIERA123',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-02T00:00:00.000Z',
									fullName: 'Daniel Guzman',
								},
							],
						},
						{
							id: 'f48b2079-7cff-4726-8b24-ab58dd619697',
							label: 'SELLO NAVIERA',
							dataType: 'Texto',
							response: [
								{
									response_answer: 'SELLONAVIERA123',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-02T00:00:00.000Z',
									fullName: 'Daniel Guzman',
								},
							],
						},
						{
							id: 'f48b2079-7cff-4726-8b24-ab58dd619697',
							label: 'OBSERVACION',
							dataType: 'Texto',
							response: [
								{
									response_answer: 'Esta es una observación',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-02T00:00:00.000Z',
									fullName: 'Daniel Guzman',
								},
							],
						},
					],
				},
				{
					formId: '7089a802-2de9-457d-86c7-9884e9ea0584',
					title: 'FOTOGRAFIA DEL EIR',
					formType: 'CAMERA',
					component: [
						{
							id: '22f69eba-92b9-4184-b800-a2febeb9c071',
							label: 'Scanner Document',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: 'f0a31ee8-c4ad-4462-b9f3-fde2c998c021',
								workflowsDetailId: '3ef7f583-2e03-4c25-ac2f-c6ba42b758f2',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: '7089a802-2de9-457d-86c7-9884e9ea0584',
					title: 'DOCUMENTACION DEL CHOFER',
					formType: 'CAMERA',
					component: [
						{
							id: '22f69eba-92b9-4184-b800-a2febeb9c071',
							label: 'Scanner Document',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: 'f0a31ee8-c4ad-4462-b9f3-fde2c998c021',
								workflowsDetailId: '2e1cf327-6ca3-4301-844f-dd1fad4f9b3c',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA DEL CONTENEDOR CERRADO',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: 'be70cf83-8c02-4951-8c19-2e24c6c3bea7',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA DEL SELLO PROVISIONAL',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: '338214de-ec8c-4403-8fb5-a4deffeee616',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA DEL NUMERO DEL CONTENEDOR PARTE INTERNA',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: 'eb39cb86-3d00-4748-91e2-219a5ff5e773',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA DE LA PUERTA INTERNA DERECHA',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: '649fc713-395a-4e8c-874e-b46510ca597c',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA DE LA PUERTA INTERNA IZQUIERDA',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: '25d3febc-8644-4c36-8205-c0b6599ff1e9',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA DE KIT DE SELLOS DE SEGURIDAD (ABIERTO Y FUNDA CERRADA)',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: '60c72755-ef2f-489f-8992-67e7d1aa555e',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA DE DEFLECTOR, DUCTOS DE VENTILZACION Y PARED FRONTAL',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: '35dc3117-59ed-4f5d-9b28-7d3168c2b362',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA DE PARED DERECHA (PERFORACIONES Y SELLADO)',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: '88f77377-aa0f-4d44-92d4-b95c5eef2d9a',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA DE PARED IZQUIERDA (PERFORACIONES Y SELLADO)',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: 'f7846d07-6436-401c-9326-f3dcf8d48864',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA DE TECHO (PERFORACIONES Y SELLADO)',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: '3469e50d-f2ea-43c4-bc7e-b3b0389651c8',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DE PISO (PERFORACIONES Y SELLADO)',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: 'e099d813-89c6-4d52-a5f4-f1344136e9a4',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DE SELLOS PROVISIONALES Y PUERTAS CERRADAS',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: '0d5c0bde-bbdd-48b8-b74a-3bf4e25faa82',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DE CANDADO SATELITAL Y SELLO',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: 'd1e5bb9a-3ae6-4811-8f57-352159f7995a',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DE CINTA DE PUERTA',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: '5051b82a-f2b5-49fb-bd22-b8c6f27315f6',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA PANORAMICA DEL DESPACHO',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: 'af5645d9-1e17-4080-9bae-49b005e57933',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'DIBUJO PARTE INTERNA',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: '29e1a774-71df-4055-a92f-9dc336f36e60',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS EXTRAS',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
							ids: {
								formDetailsId: '016ef525-69ce-4868-96fe-a4c924ecbe7f',
								workflowsDetailId: 'e9262f7a-7c71-44f9-b8db-829c96281887',
								processGroupId: '1e9b2ac7-3742-4d7d-848f-48cfbf5094c9',
							},
						},
					],
				},
			],
		},
		{
			title: 'INSPECCION REEFER',
			description: 'contenedores reefer',
			titleWorkflow: 'INSPECCION EXTERNA REEFER',
			descriptionWorkflow: 'INSPECCION DE LA PARTE EXTERNA DEL CONTENDOR Y MAQUINA',
			createAtWorkflow: '2024-08-30T17:13:02.832Z',
			location: 'Patio Ejemplo',
			steps: [
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'INICIO FOTO PANORAMICA PANEL FRONTAL COMPLETO',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-30T17:15:24.892Z',
									fullName: 'Daniel Guzman',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-30T17:15:24.892Z',
									fullName: 'Daniel Guzman',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-08-30T17:15:24.892Z',
									fullName: 'Daniel Guzman',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DE PARED EXTERIOR IZQUIERDA',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DE PISO EXTERIOR IZQUIERDO',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DE PARED EXTERIOR DERECHA',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DE PISO EXTERIOR DERECHO',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DEL CABLE DE ALIMENTACION O MANGA',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DEL PTI',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DEL TECHO',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DE EVAPORADORES ABIERTOS',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DEL EVAPORADOR DERECHO',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DEL EVAPORADOR IZQUIERDO',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DE EVAPORADORES CERRADOS',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS DEL VENTILADOR',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIA PANORAMICA CON CINTAS DE SEGURIDAD',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
				{
					formId: 'c0a7969e-649b-4286-97d7-f165926226f8',
					title: 'DIBUJO PARTE EXTERNA',
					formType: 'CAMERA',
					component: [
						{
							id: '93e0fd80-2e3f-40b1-b1d5-e1dbb1c31f2e',
							label: 'imagen',
							dataType: 'Painter',
							response: [],
						},
					],
				},
				{
					formId: 'c0a7969e-649b-4286-97d7-f165926226f8',
					title: 'DIBUJO PARTE EXTERNA',
					formType: 'CAMERA',
					component: [
						{
							id: '93e0fd80-2e3f-40b1-b1d5-e1dbb1c31f2e',
							label: 'imagen',
							dataType: 'Painter',
							response: [],
						},
					],
				},
				{
					formId: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
					title: 'FOTOGRAFIAS EXTRAS (OPCIONALES)',
					formType: 'CAMERA',
					component: [
						{
							id: 'e637ce75-4cd1-4570-9210-01bf46ecf411',
							label: 'camara',
							dataType: 'Imagen',
							response: [
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:50:27.006Z',
									fullName: 'David Gavilanes',
								},
								{
									response_answer: 'https://dev--storage--s3.s3.amazonaws.com/assets/container.jpg',
									response_coordinates: {
										lat: '-2.343530',
										long: '-79.89733',
									},
									response_date: '2024-09-13T20:51:27.006Z',
									fullName: 'David Gavilanes',
								},
							],
						},
					],
				},
			],
		},
	],
};

type TProcess = typeof processExample;

export const getOneProcess = async ({ id }: IGetOneProcessParams): Promise<TProcess[]> => {
	try {
		const { data } = await apiPrivate.get(`/api/workflow/${id}/response-processes-performed`);
		return data.length ? data : [processExample];
	} catch (e) {
		return [processExample];
	}
};

export type { TProcess };
