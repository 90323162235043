import { Button } from '@atoms';
import { FormInput, FormInputPhone, FormSearchableDropdown } from '@molecules';
import { Controller, useForm } from 'react-hook-form';
import { REGEX_EMAIL, getDirtyFormData } from '@utils';
import { ImagePicker } from '@organisms';
import { useAppSelector, usePlaces } from '@hooks';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCompany } from '@api';
import { IFormEditCompanyInformationValues } from '@interfaces';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

interface IFormEditCompanyInformationProps {
	editingCompany: IFormEditCompanyInformationValues;
}

export const FormEditCompanyInformation = ({ editingCompany }: IFormEditCompanyInformationProps) => {
	const { id: companyIdFromparams } = useParams();
	const companyIdFromGlobalState = useAppSelector(state => state.userProfile).companies[0].id;
	const companyId = companyIdFromparams || companyIdFromGlobalState;

	const {
		formState: { isDirty, errors, defaultValues, dirtyFields },
		handleSubmit: handleSubmitWrapper,
		register,
		control,
		reset,
	} = useForm<IFormEditCompanyInformationValues>({
		defaultValues: editingCompany,
	});

	const { data: places } = usePlaces();
	const [selectedState, setSelectedState] = useState(places.getStateByCityId(editingCompany.adminCity));
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: updateCompany,
		onSuccess: (_, { id, ...savedFields }) => {
			reset(
				{
					...defaultValues,
					...savedFields,
				},
				{ keepDirty: false }
			);
			queryClient.invalidateQueries({ queryKey: ['company', companyId] });
			toast.success('Información actualizada.');
		},
	});

	function handleSubmit(formData: IFormEditCompanyInformationValues) {
		const dirties = getDirtyFormData(dirtyFields, formData);
		mutation.mutate({
			id: companyId!,
			...dirties,
		});
	}

	return (
		<form autoComplete='off' onSubmit={handleSubmitWrapper(handleSubmit)}>
			{/* Improve this to accept image as File */}
			<ImagePicker
				label='Cargar Logo'
				onError={!!errors.file}
				errorMessage={errors.file?.message}
				profilePictureSrc={defaultValues?.file as string}
				register={{
					...register('file', {
						required: { value: true, message: 'This field is required' },
					}),
				}}
			/>
			<div className='grid grid-cols-4 gap-x-8 gap-y-4 mt-8'>
				<FormInput
					type='text'
					label='Empresa'
					errorMessage={errors.name?.message}
					onError={!!errors.name}
					register={{
						...register('name', {
							required: { value: true, message: 'This field is required' },
						}),
					}}
				/>
				<FormInput
					type='text'
					label='RUC'
					errorMessage={errors.identificationNumber?.message}
					onError={!!errors.identificationNumber}
					register={{
						...register('identificationNumber', {
							required: { value: true, message: 'This field is required' },
							minLength: { value: 13, message: 'RUC must be 13 characters long' },
							maxLength: { value: 13, message: 'RUC must be 13 characters long' },
							validate: v => {
								const everyCharacterIsDigit = /^\d+$/.test(v);
								return everyCharacterIsDigit || 'Every character should be a digit';
							},
						}),
					}}
				/>
				<FormInputPhone
					disabled={true}
					label='Número de Contacto'
					errorMessage={errors.contactPhoneNumber?.message}
					onError={!!errors.contactPhoneNumber}
					register={{
						...register('contactPhoneNumber', {
							required: { value: true, message: 'This field is required' },
							minLength: { value: 9, message: 'Phone number must be at least 9 characters long' },
							maxLength: { value: 10, message: 'Phone number must be at most 10 characters long' },
							validate: v => {
								const everyCharacterIsDigit = /^\d+$/.test(v);
								return everyCharacterIsDigit || 'Every character should be a digit';
							},
						}),
					}}
				/>
				<FormInput
					disabled={true}
					type='email'
					label='Correo Electrónico del Administrador'
					errorMessage={errors.adminEmail?.message}
					onError={!!errors.adminEmail}
					register={{
						...register('adminEmail', {
							required: { value: true, message: 'This field is required' },
							pattern: { value: REGEX_EMAIL, message: 'Invalid email' },
							minLength: { value: 6, message: 'Min length is 6' },
							maxLength: { value: 30, message: 'Max length is 30' },
						}),
					}}
				/>
				<FormInput
					disabled={true}
					type='text'
					label='Nombre del Administrador'
					errorMessage={errors.adminFirstName?.message}
					onError={!!errors.adminFirstName}
					register={{
						...register('adminFirstName', {
							required: { value: true, message: 'This field is required' },
						}),
					}}
				/>
				<FormInput
					disabled={true}
					type='text'
					label='Apellido del Administrador'
					errorMessage={errors.adminLastName?.message}
					onError={!!errors.adminLastName}
					register={{
						...register('adminLastName', {
							required: { value: true, message: 'This field is required' },
						}),
					}}
				/>
				<FormSearchableDropdown
					disabled={true}
					label='Provincia'
					options={places
						.getStatesByCountryId(places.getCountries().find(place => place.name === 'Ecuador')?.id ?? '')
						.map(({ id, name }) => ({ id, value: name }))}
					value={places.getStateByCityId(editingCompany.adminCity)}
					onChange={value => setSelectedState(value)}
				/>
				<Controller
					control={control}
					name='adminCity'
					defaultValue=''
					render={({ field: { ref, ...rest } }) => (
						<FormSearchableDropdown
							disabled={true}
							label='Ciudad'
							options={places
								.getCitiesByStateId(selectedState)
								.map(({ id, name }) => ({ id, value: name }))}
							{...rest}
						/>
					)}
				/>
				<FormSearchableDropdown
					disabled={true}
					label='País'
					options={[{ id: 'ECUADOR', value: 'Ecuador' }]}
					value={'ECUADOR'}
				/>
			</div>
			<Button isLoading={mutation.isPending} disabled={!isDirty} className='mt-8'>
				Guardar Cambios
			</Button>
		</form>
	);
};
