import { apiPrivate } from '../config/apiPrivate';

interface ICloseBoxParams {
	kitType: string;
}

interface ICloseBoxResp {}

export const closeBox = async (params: ICloseBoxParams): Promise<ICloseBoxResp> => {
	const { data } = await apiPrivate.patch<ICloseBoxResp>('/api/kits/close-box', params);
	return data;
};
