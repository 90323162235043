import { apiPrivate } from '../config/apiPrivate';

type IFindAllCourtyardsParams =
	| {
			by: 'user';
			userId: string;
	  }
	| {
			by: 'company';
			companyId: string;
	  };

interface IFindAllCourtyardsResp {
	id: string;
	name: string;
	area: { latitude: string; longitude: string }[];
}

export const findAllCourtyards = async (params: IFindAllCourtyardsParams): Promise<IFindAllCourtyardsResp[]> => {
	const { data } = await apiPrivate.get<IFindAllCourtyardsResp[]>(
		`/api/courtyards/${params.by}/${params.by === 'company' ? params.companyId : params.userId}`
	);
	return data;
};
