import { ICategory } from '@interfaces';
import { apiPrivate } from '../config/apiPrivate';

interface IGetOneCategoryParams {
	id: string;
}

interface IGetOneCategoryResp extends ICategory {
	createdAt: string;
	updatedAt: string;
	deleteAt: string;
}

export const getOneCategory = async (params: IGetOneCategoryParams): Promise<IGetOneCategoryResp> => {
	const { data } = await apiPrivate.get<IGetOneCategoryResp>(`/api/categories/${params.id}`);
	return data;
};
