import { IUserProfile } from '@interfaces';
import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

const EMPTY_STATE: IUserProfile = {
	dni: '',
	email: '',
	id: '',
	firstName: '',
	lastName: '',
	fullName: '',
	phoneNumber: '',
	image: '',
	city: { id: '', name: '' },
	companies: [],
	has2fa: false,
	permissions: [],
	role: {
		id: '',
		name: '',
	},
};

interface IUserProfileState extends IUserProfile {}

const getUserProfile = (): IUserProfileState => {
	const userProfile = localStorage.getItem('userProfile');

	if (!userProfile) return EMPTY_STATE;

	return JSON.parse(userProfile) as IUserProfileState;
};

const initialState: IUserProfileState = getUserProfile();

export const userProfileSlice = createSlice({
	name: 'userProfile',
	initialState,
	reducers: {
		setUserProfile: (_state, { payload }: PayloadAction<IUserProfile>) => payload,
		setUserImage: (_state, { payload }: PayloadAction<string>) => {
			_state.image = payload;
		},
		clearUserProfile: () => EMPTY_STATE,
	},
});

export const { setUserProfile, clearUserProfile, setUserImage } = userProfileSlice.actions;
