import { isAction } from '@reduxjs/toolkit';

import type { Middleware } from '@reduxjs/toolkit';

import { clearUserProfile, setUserImage, setUserProfile } from '@RTK/Slices';

import type { RootState } from '..';

// eslint-disable-next-line @typescript-eslint/ban-types
export const userProfileMiddleware: Middleware<{}, RootState> = store => next => action => {
	const result = next(action);

	if (isAction(action) && (action.type === setUserProfile.type || action.type === setUserImage.type)) {
		const state = store.getState();
		localStorage.setItem('userProfile', JSON.stringify(state.userProfile));
	} else if (isAction(action) && action.type === clearUserProfile.type) {
		localStorage.removeItem('userProfile');
	}

	return result;
};
