import { apiPrivate } from '../config/apiPrivate';

type IFindAllFarmsParams =
	| {
			by: 'user';
			userId: string;
	  }
	| {
			by: 'company';
			companyId: string;
	  };

interface IFindAllFarmsResp {
	id: string;
	name: string;
	area: { latitude: string; longitude: string }[];
}

export const findAllFarms = async (params: IFindAllFarmsParams): Promise<IFindAllFarmsResp[]> => {
	const { data } = await apiPrivate.get<IFindAllFarmsResp[]>(
		`/api/farms/${params.by}/${params.by === 'company' ? params.companyId : params.userId}`
	);
	return data;
};
