import { apiPrivate } from '../config/apiPrivate';

interface IDeleteUserParams {
	id: string;
}

interface IDeleteUserResp {}

export const deleteUser = async (params: IDeleteUserParams): Promise<IDeleteUserResp> => {
	const { data } = await apiPrivate.delete<IDeleteUserResp>(`/api/users/${params.id}`);
	return data;
};
