import styles from './Searchbar.module.sass';
import type { ITableSearchbarProps } from './Searchbar.interfaces';

import { IcMagnifier } from '@atoms';
import { useDebouncedState } from '@hooks';
import type { ChangeEvent } from 'react';

export const Searchbar = ({ onSearch, searchDelayMs = 0, className = '' }: ITableSearchbarProps) => {
	const [searchString, setSearchString] = useDebouncedState<string>('', onSearch, searchDelayMs);

	function handleChange(e: ChangeEvent<HTMLInputElement>) {
		setSearchString(e.target.value);
	}

	return (
		<div className={`${styles['searchbar']} ${className}`}>
			<input
				onChange={handleChange}
				value={searchString}
				className={styles['searchbar__input']}
				placeholder='Buscar'
			/>
			<IcMagnifier className={styles['searchbar__search-icon']} />
		</div>
	);
};
