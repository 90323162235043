import { apiPrivate } from '../config/apiPrivate';

interface IUpdateTwoFAParams {
	userId: string;
	twoFactorIsEnabled: boolean;
}

interface IUpdateTwoFAResp {}

export const updateTwoFA = async ({ userId, ...restParams }: IUpdateTwoFAParams): Promise<IUpdateTwoFAResp> => {
	const { data } = await apiPrivate.patch<IUpdateTwoFAResp>(`/api/users/${userId}/toggle-2FA`, restParams);
	return data;
};
