import { ButtonGoBack } from '@atoms';
import { useProcessPdfData } from '@hooks';
import { InspectionDocument } from '@pdfs';
import { PDFViewer } from '@react-pdf/renderer';
import { useNavigate, useParams } from 'react-router-dom';

const InspectionDetailsPage = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { isLoading, isError, data } = useProcessPdfData({ id: id! });

	if (isLoading) return <p className='text-lg'>Cargando PDF...</p>;

	if (isError) return <p className='text-lg text-red-500'>Ocurrió un error al generar el PDF</p>;

	// TODO: Render the PDF using a web worker
	return (
		<>
			<ButtonGoBack onClick={() => navigate(-1)} />
			<PDFViewer className='h-full'>
				<InspectionDocument
					firstPage={data!.firstPage}
					secondPage={data!.secondPage}
					thirdPage={data!.thirdPage}
					pictures={data!.pictures}
				/>
			</PDFViewer>
		</>
	);
};

export default InspectionDetailsPage;
